import { Tab } from '@headlessui/react'
import React, { Fragment, useContext } from 'react'
import NoCombination from './NoCombination'
import NoResult from './NoResult'
// import PersonalResults from './PersonalResults'
import './MyPage.scss'
import { MyPageContext } from '../../api/context/myPageContext'
import { Setting } from './Setting'
import { MyPageSettingModal } from '../../components/Modals/MyPageSettingModal/MyPageSettingModal'
import { useHomePage } from '../../hooks/useHomePage'
import { SuccessModal } from '../../components/Modals/SuccessModal/SuccessModal'
import { ErrorModal } from '../../components/Modals/ErrorModal/ErrorModal'

function MyPageElements() {
    const { apiResponse, setMyPageData, showMyPageSettingModal, settingResponseValue, setSettingModalValue } = useContext(MyPageContext)
    const { showSuccessModal, setShowSuccessModal, showErrorModal, setShowErrorModal, successAndErrorResponse } = useHomePage()
    const sections = { '#outlineSection': 0, '#settingSection': 1, '#friends-section': 2 }
    const urlHash = window.location.hash.split('?')[0]
    const defaultTab = sections?.[urlHash] ? sections[urlHash] : 0
    return (
        <>
            {showMyPageSettingModal === true ? (
                <MyPageSettingModal settingResponseValue={settingResponseValue} setSettingModalValue={setSettingModalValue} />
            ) : showSuccessModal === true ? (
                <SuccessModal
                    showSuccessModal={showSuccessModal}
                    setShowSuccessModal={setShowSuccessModal}
                    successAndErrorResponse={successAndErrorResponse}
                />
            ) : showErrorModal === true ? (
                <ErrorModal
                    showErrorModal={showErrorModal}
                    setShowErrorModal={setShowErrorModal}
                    successAndErrorResponse={successAndErrorResponse}
                />
            ) : (
                ''
            )}
            <Tab.Group
                defaultIndex={defaultTab}
                onChange={(index) => {
                    index === 0 && setMyPageData()
                }}
            >
                <Tab.List className="mx-auto flex max-w-[1210px] sm:px-[20px]">
                    <Tab as={Fragment}>
                        {({ selected }) => (
                            /* Use the `selected` state to conditionally style the selected tab. */
                            <button
                                className={`h-[51px] w-[125px]  rounded-tl-[4px] rounded-tr-[4px] outline-none sm:w-[120px] sxl:h-[61px] ${
                                    selected ? 'text-white bg-[#A1C7FF]' : 'bg-white text-[#212529]'
                                } `}
                            >
                                개요
                            </button>
                        )}
                    </Tab>
                    {/* <Tab as={Fragment}>
            {({ selected }) => (
              // Use the `selected` state to conditionally style the selected tab. 
              <button
                className={`h-[51px] w-[125px]  rounded-tr-[4px] rounded-tl-[4px] outline-none sm:w-[120px] sxl:h-[61px] ${
                  selected ? 'text-white bg-[#A1C7FF]' : 'bg-white text-[#212529]'
                } `}
              >
                친구들
              </button>
            )}
          </Tab> */}
                    <Tab as={Fragment}>
                        {({ selected }) => (
                            /* Use the `selected` state to conditionally style the selected tab. */
                            <button
                                className={`h-[51px] w-[125px]  rounded-tl-[4px] rounded-tr-[4px] outline-none sm:w-[120px] sxl:h-[61px] ${
                                    selected ? 'text-white bg-[#A1C7FF]' : 'bg-white text-[#212529]'
                                } `}
                            >
                                설정
                            </button>
                        )}
                    </Tab>
                </Tab.List>
                <Tab.Panels>
                    <Tab.Panel>{apiResponse?.status ? <NoCombination /> : <NoResult message={apiResponse?.message} />}</Tab.Panel>
                    {/* <Tab.Panel>
            <FriendProvider>
              <NoFriends defaultTab={defaultTab} />
            </FriendProvider>
          </Tab.Panel> */}
                    <Tab.Panel>
                        <Setting />
                    </Tab.Panel>
                </Tab.Panels>
            </Tab.Group>
        </>
    )
}
export default MyPageElements
