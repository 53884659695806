import React, { Suspense } from 'react'
import './App.css'
import { Route, Routes } from 'react-router-dom'
import { ReactNotifications } from 'react-notifications-component'
import { UserProvider } from './api/context/userContext'
import { useHomePage } from './hooks/useHomePage'
import { Navbar } from './components/Shared/Navbar/Navbar'
import { Footer } from './components/Shared/Footer/Footer'
import SearchPage from './views/SearchPage/SearchPage'
import DNAtype from './views/DNAtypePage/DNAtype'
import MyPage from './views/MyPage/MyPage'
import { Spinner } from './components/Spinner/Spinner'
import LearningGene from './views/LearningGenePage/LearningGene'
import QuestionPage from './views/QuestionPage/QuestionPage'

// Lazy load components
const HomePage = React.lazy(() => import('./views/HomePage/HomePage'))
const SignUpPage = React.lazy(() => import('./views/Signup/SignupPage'))
const WelcomePage = React.lazy(() => import('./views/WelcomePage/WelcomePage'))
const CreateAccount = React.lazy(() => import('./views/CreateAccount/CreateAccount'))
const ShareLinkElements = React.lazy(() => import('./views/ShareLink/ShareLinkProfile'))
const PrivateRoutes = React.lazy(() => import('./routes/PrivateRoute'))
const ProtectedRoute = React.lazy(() => import('./routes/ProtectedRoute'))
const PhoneVerifyResponse = React.lazy(() => import('./views/PhoneVerifyResponse'))
// const Review = React.lazy(() => import('./views/Review/Review'));
const KakaoLogin = React.lazy(() => import('./views/KakaoLogin/KakaoLogin'))
const KakaoConnect = React.lazy(() => import('./views/KakaoConnect/KakaoConnect'))
const VerifyTokenPage = React.lazy(() => import('./views/VerifyTokenPage/VerifyTokenPage'))

const ResultPage = React.lazy(() => import('./views/ResultPage/ResultPage'))
const ReportPage = React.lazy(() => import('./views/ReportTypes/ReportPage'))
const TypeDetail = React.lazy(() => import('./views/TypeDetailPage/TypeDetail'))
const Contact = React.lazy(() => import('./views/Contact/Contact'))
const TermAndCondition = React.lazy(() => import('./views/TermAndCondition/TermAndCondition'))
const PrivacyPolicy = React.lazy(() => import('./views/PrivacyPolicy/PrivacyPolicy'))
const NotFound404 = React.lazy(() => import('./views/Errors/NotFound404'))
const ServiceAgreement = React.lazy(() => import('./views/ServiceAgreementPage/ServiceAgreement'))
const ResetPasswordPageHandler = React.lazy(() => import('./views/ResetPasswordPage/Handler'))
const ThankYouPage = React.lazy(() => import('./views/ThankyouPage/ThankyouPage'))
const MarketingAgrement = React.lazy(() => import('./views/MarketingAgrement/MarketingAgrement'))
const { ServerError500 } = React.lazy(() => import('./views/Errors/ServerError500'))

function App() {
    const { setIsLoggedIn } = useHomePage()
    const verifytoken = localStorage.getItem('resetPasswordToken')

    return (
        <UserProvider>
            <div className="App">
                <ReactNotifications />
                <Navbar />
                <Suspense
                    fallback={
                        <div className="h-screen">
                            <Spinner />{' '}
                        </div>
                    }
                >
                    <Routes>
                        <Route path="/" element={<HomePage />} />
                        <Route path="/signup" element={<SignUpPage setIsLoggedIn={setIsLoggedIn} />} />
                        <Route path="/typeReport" element={<ReportPage />} />
                        <Route path="/typeReport/:number" element={<TypeDetail />} />
                        <Route path="/54DNAtype" element={<DNAtype />} />
                        <Route path={`/${verifytoken}`} element={<VerifyTokenPage verifytoken={verifytoken} />} />
                        <Route path="/user/resetPassword/:token" element={<ResetPasswordPageHandler />} />
                        <Route path="*" element={<NotFound404 />} />
                        <Route path="/500" element={<ServerError500 />} />
                        <Route path="/signup" element={<SignUpPage setIsLoggedIn={setIsLoggedIn} />} />
                        <Route path="/create-account" element={<CreateAccount setIsLoggedIn={setIsLoggedIn} />} />
                        <Route path="/agreement" element={<ServiceAgreement />} />
                        <Route path="/learningGene" element={<LearningGene />} />
                        <Route path="/profiles/:nickname" element={<ShareLinkElements />} />
                        <Route path="/contact" element={<Contact />} />
                        <Route path="/termAndCondition" element={<TermAndCondition />} />
                        <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
                        <Route exact path="/search" element={<SearchPage />} />
                        <Route path="/questions" element={<QuestionPage />} />
                        <Route path="/myPage" element={<ProtectedRoute protected={MyPage} />} />
                        <Route path="/phoneVerifyResponse" element={<PhoneVerifyResponse />} />
                        {/* <Route path="/reviews" element={<Review />} /> */}
                        <Route path="/kakao/login" element={<KakaoLogin />} />
                        <Route path="/kakao/link" element={<KakaoConnect />} />
                        <Route path="/marketing" element={<MarketingAgrement />} />
                        <Route element={<PrivateRoutes />}>
                            <Route path="/welcome" element={<WelcomePage />} />
                            <Route path="/result" element={<ResultPage />} />
                            <Route path="/thank-you" element={<ThankYouPage />} />
                        </Route>
                    </Routes>
                </Suspense>
                <Footer />
            </div>
        </UserProvider>
    )
}

export default App
