import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import alertCircleOutline from '../../../assets/icons/dupliccate_icon.svg'

export function DuplicateQuestionModal({ showDuplicateQuestionModal, setShowDuplicateQuestionModal }) {
    const handleConfirm = () => {
        setShowDuplicateQuestionModal(false)
    }
    return (
        <Modal
            centered
            size="lg"
            className="errorModal"
            aria-labelledby="contained-modal-title-vcenter"
            show={showDuplicateQuestionModal}
            onHide={() => setShowDuplicateQuestionModal(false)}
        >
            <Modal.Header closeButton className="border-none" />
            <Modal.Body className="errorModal px-[21px] lg:px-[55px]">
                <div>
                    <img src={alertCircleOutline} alt="alertCircleOutline" className="ml-4" />
                </div>
                <div className="text-center text-[15px] font-bold leading-[24px]   max-md:mt-3  md:mt-[-10px] md:text-[20px] md:leading-[30px] lg:mt-[-30px]">
                    중복 질문이 있을 수 있습니다.
                </div>
                <div className="text-center text-[15px] font-normal leading-[24px] md:mt-[-20px]  md:text-[20px] md:leading-[30px] lg:mt-[-40px]">
                    주의 깊게 답변해 주시면 감사하겠습니다.
                </div>
                <div className="flex w-full items-center justify-center gap-2 pb-12 md:mt-[10px]">
                    <Button
                        variant="primary"
                        className="h-[50px] w-1/2 bg-[#005BE2] text-center text-[16px] font-semibold leading-[19px] md:h-[60px] md:w-[40%]"
                        onClick={handleConfirm}
                    >
                        확인
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    )
}
