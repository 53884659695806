import React, { useContext, useState } from 'react'
import ClipBoardIcon from '../../assets/icons/clipboard.svg'
import LinkIcon from '../../assets/icons/link.svg'
import BG from '../../assets/icons/bottomWaveBg.svg'
import copyCheck from '../../assets/icons/copy-check.png'
import BgMobile from '../../assets/icons/noCombinationMobileBg.svg'
import GeneSection from '../../components/MyPage/Gene'
import PageCardList from '../../components/MyPage/Cards/PageCardList'
import { MyPageContext } from '../../api/context/myPageContext'
import { Reviews } from './Reviews'

function NoCombination() {
    const {
        apiResponse: {
            data: {
                images: { desktop },
                profileLink,
            },
        },
    } = useContext(MyPageContext) // use the useContext hook to access apiResponse value
    const [checkImg, setCheckImg] = useState(false)

    const copyToClipboard = () => {
        navigator.clipboard.writeText(profileLink)
        setCheckImg(true)
        setTimeout(() => {
            setCheckImg(false)
        }, 3000)
    }
    return (
        <div>
            <img className="mx-auto -mt-[1px] block hidden" src={desktop} alt="Banner" />
            <div
                style={{
                    background: `url(${BgMobile})`,
                }}
                className="relative z-[10] block hidden min-h-[200px]  bg-[length:100%] bg-bottom
        bg-no-repeat pt-[40px] after:absolute after:left-0 after:right-0 after:top-0 after:z-0 after:h-[100px] after:bg-[#A2C7FF]
        "
            >
                <h2 className="text-white relative z-10 mb-[8px] text-[24px] leading-[29px]">학습유전자</h2>
                <p className="text-white relative z-10 text-[14px] leading-[17px]">
                    DNA 질문검사 결과를 검토하고 <br />
                    조합된 타입 특성에 대해 자세히 알아보십시오.
                </p>
            </div>
            <PageCardList />
            <GeneSection />
            <div
                style={{
                    background: `url(${BG})`,
                }}
                className="bg-[length:100%] bg-bottom bg-no-repeat pb-[100px] sxl:pb-[130px]"
            >
                <div className="mx-auto max-w-[1210px] px-[20px]">
                    <div className="mb-[30px]  block">
                        <h2 className="mb-[12px] text-left text-[36px] leading-normal text-[#272727]">프로필 공유</h2>
                        <p className="text-black mb-0 text-left leading-[19px] text-[#272727]">
                            암호화된 프로필 링크를 공유하여 다른 사람이 귀하의 조합된 타입 특성을 볼 수 있게 하십시오.
                        </p>
                    </div>

                    <div className="bg-white flex flex-wrap items-center justify-between rounded-full border-[1px] !border-[#EBEBEB] px-[10px]  py-[9px] text-[#999999]  lg:px-[30px] sxl:py-[11px] sxl:pl-[50px] sxl:pr-[48px]">
                        <p className="mb-0 flex w-[60%] items-center text-wrap text-[12px] leading-[19px] tracking-[-0.03em] lg:text-[16px]">
                            <img src={LinkIcon} alt="Link Icon" className="mr-[10px]" />
                            {profileLink}
                        </p>

                        <button
                            onClick={copyToClipboard}
                            className="border-white bg-white focus:border-black/5 flex h-[30px] w-[30px] items-center justify-center rounded-full border-[1px] drop-shadow-[0px_0px_10px_rgba(71,_71,_71,_0.15)] focus:drop-shadow-[0px_0px_10px_rgba(71,_71,_71,_0)] lg:h-[48px] lg:w-[48px] sxl:h-[56px] sxl:w-[56px]"
                        >
                            {checkImg && <img src={copyCheck} alt="clipboard" className="absolute mb-7 ml-8 w-[20px] lg:mb-11 lg:ml-11" />}
                            <img src={ClipBoardIcon} alt="clipboard" />
                        </button>
                    </div>
                    <Reviews />
                    {/* <div className="mb-[30px] mt-[50px] mb-[40px] mt-[100px] sxl:mb-[60px] sxl:mt-[130px]">
            <h2 className="mb-[12px] text-left text-[24px] leading-normal text-[#272727] text-[30px] sxl:text-[36px]">조합 이력</h2>
            <p className="text-black mb-0 text-left leading-[19px] tracking-normal text-[#272727]">
              나의 DNA 질문검사 결과와 친구로 맺은 부모 또는 지인 결과를 조합받아 더 높은 정확도로 확인합니다. 조합에 따라 결과 변화가
              예상되며 95% 수준의 정확도로 상승 됩니다.
            </p>
          </div> */}
                    {/* <ParentUnionResults /> */}
                </div>
            </div>
        </div>
    )
}

export default NoCombination
/* Ellipse 9 */
