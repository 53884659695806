import React from 'react'
import { useNavigate } from 'react-router-dom'

function Gene45Card({ data: { name, icon, description } }) {
    const navigate = useNavigate()

    return (
        <div className="flex flex-col lg:!rounded-bl-[8px] rounded-[8px] lg:rounded-none h-full border-[1.5px] border-[#EEEEEE] bg-white relative after:block after:h-[7px] after:w-full after:absolute after:left-0 after:top-0 after:bg-[#7D3C9A] after:bg-opacity-60 overflow-hidden pt-[7px]">
            <h2 className="text-[#272727] font-bold mt-[18px] sm:mt-[28px] mb-[12px] sm:mb-[18px] text-[18px] md:text-[20px] sxl:text-[24px] max-w-[300px] mx-auto text-center">
                {name}
            </h2>
            <img src={icon} alt="dna-structure" className="sm:max-w-auto mx-auto  sxl:h-auto h-[85px] md:h-[100px] max-w-full" />
            <p className="mt-[16px] sm:mt-[20px] md:mt-[40px] px-[16px]  sm:px-[20px] xl:px-[25px] sxl:text-[16px] text-[12px] sm:text-[14px] min-h-[119px] sm:min-h-[133px] text-left leading-[14.4px] sm:!leading-tight sxl:leading-[19px] tracking-normal text-[#272727]">
                {description}
            </p>
            <div className="text-right pb-[20px] lg:pb-[30px] pr-[25px] mt-auto pt-[8px] lg:pt-[40px]">
                <button
                    className="underline text-[#005BE2] hover:text-opacity-90 transition duration-200  text-[14px]  mt-auto ml-auto inline-block font-semi-bold tracking-[-0.03em]  leading-none"
                    onClick={() => {
                        navigate('/54dnatype', { state: { section: '창의행동 유전자' } })
                    }}
                >
                    더 알아보기 →
                </button>
            </div>
        </div>
    )
}

export default Gene45Card
