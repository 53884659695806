import { Helmet } from 'react-helmet'
import MyPageElements from './MyPageElements'
import { MyPageProvider } from '../../api/context/myPageContext'

function MyPage() {
    return (
        <MyPageProvider>
            <Helmet>
                <title>마이페이지</title>
            </Helmet>
            <MyPageElements />
        </MyPageProvider>
    )
}

export default MyPage
