import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { useHomePage } from '../../hooks/useHomePage'
import { LoginModal } from '../../components/Modals/LoginModal/LoginModal'
import { LoginFailedModal } from '../../components/Modals/LoginFailedModal/LoginFailedModal'
import { ForgetPasswordModal } from '../../components/Modals/ForgetPasswordModal/ForgetPasswordModal'
import { FindEmailModal } from '../../components/Modals/FindEmailModal/FindEmailModal'
import { SuccessModal } from '../../components/Modals/SuccessModal/SuccessModal'
import { ResetPasswordWrongEmailModal } from '../../components/Modals/ResetPasswordWrongEmailModal/ResetPasswordWrongEmailModal'

function DemoQuestions({ data }) {
    const {
        showLoginModal,
        setShowLoginModal,
        showLoginFailedModal,
        setShowLoginFailedModal,
        showForgetPasswordModal,
        setShowForgetPasswordModal,
        showFindEmailModal,
        setShowFindEmailModal,
        showSuccessModal,
        setShowSuccessModal,
        resetPasswordWrongEmailModal,
        setResetPasswordWrongEmailModal,
        passwordError,
        setPasswordError,
        isLoggedIn,
        setSuccessAndErrorResponse,
        successAndErrorResponse,
    } = useHomePage()

    const handleLogin = () => {
        setShowLoginModal(true)
    }
    return (
        <>
            <Helmet>
                <title>DNA질문검사</title>
            </Helmet>
            {/* ====================  Modals =================== */}

            {showLoginModal === true ? (
                <LoginModal
                    setSuccessAndErrorResponse={setSuccessAndErrorResponse}
                    setShowSuccessModal={setShowSuccessModal}
                    showLoginModal={showLoginModal}
                    setShowLoginModal={setShowLoginModal}
                    setShowLoginFailedModal={setShowLoginFailedModal}
                    setShowForgetPasswordModal={setShowForgetPasswordModal}
                    setShowFindEmailModal={setShowFindEmailModal}
                    isLoggedIn={isLoggedIn}
                />
            ) : showLoginFailedModal === true ? (
                <LoginFailedModal
                    showLoginFailedModal={showLoginFailedModal}
                    setShowLoginFailedModal={setShowLoginFailedModal}
                    setShowLoginModal={setShowLoginModal}
                    setShowForgetPasswordModal={setShowForgetPasswordModal}
                    setShowFindEmailModal={setShowFindEmailModal}
                />
            ) : showForgetPasswordModal === true ? (
                <ForgetPasswordModal
                    setSuccessAndErrorResponse={setSuccessAndErrorResponse}
                    setShowLoginModal={setShowLoginModal}
                    showForgetPasswordModal={showForgetPasswordModal}
                    setShowForgetPasswordModal={setShowForgetPasswordModal}
                    setShowSuccessModal={setShowSuccessModal}
                    setResetPasswordWrongEmailModal={setResetPasswordWrongEmailModal}
                    setShowFindEmailModal={setShowFindEmailModal}
                    setPasswordError={setPasswordError}
                />
            ) : showFindEmailModal === true ? (
                <FindEmailModal
                    showFindEmailModal={showFindEmailModal}
                    setShowFindEmailModal={setShowFindEmailModal}
                    setShowForgetPasswordModal={setShowForgetPasswordModal}
                    setShowLoginModal={setShowLoginModal}
                />
            ) : showSuccessModal === true ? (
                <SuccessModal
                    successAndErrorResponse={successAndErrorResponse}
                    showSuccessModal={showSuccessModal}
                    setShowSuccessModal={setShowSuccessModal}
                />
            ) : resetPasswordWrongEmailModal === true ? (
                <ResetPasswordWrongEmailModal
                    resetPasswordWrongEmailModal={resetPasswordWrongEmailModal}
                    setResetPasswordWrongEmailModal={setResetPasswordWrongEmailModal}
                    setShowForgetPasswordModal={setShowForgetPasswordModal}
                    setShowFindEmailModal={setShowFindEmailModal}
                    passwordError={passwordError}
                />
            ) : (
                ''
            )}
            <>
                {data.map((question, index) => (
                    <>
                        <Row className="question hidden justify-end md:flex" key={index}>
                            <Col md={9} sm={12}>
                                <span key={question._id}>{`${question.question}`}</span>
                            </Col>
                            <Col md={3} sm={12}>
                                <Row className="gx-2 justify-end">
                                    <Col xs={6} lg={6}>
                                        <button
                                            name="yes"
                                            variant="outline-primary"
                                            onClick={handleLogin}
                                            className="btn btn-outline-secondary col-12 py-md-3 mt-lg-0 mt-2"
                                        >
                                            그렇다
                                        </button>
                                    </Col>
                                    <Col xs={6} lg={6}>
                                        <button
                                            name="no"
                                            variant="outline-primary"
                                            onClick={handleLogin}
                                            className="btn btn-outline-secondary col-12 py-md-3 mt-lg-0 mt-2"
                                        >
                                            아니다
                                        </button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <div className="textFont mt-4 flex w-full flex-col px-3 md:hidden">
                            <div className="flex flex-row">
                                <p className="self-start">{`${question.question.split('.', 2)[0]}.`}</p>
                                <p className="ml-1 self-start text-start">{question.question.split('.', 2)[1]}</p>
                            </div>
                            <div className="mx-auto flex w-[100%] flex-row items-center justify-evenly">
                                <button
                                    className="border-{#B4B4B4} h-10 w-[49%] max-w-[100px] rounded-md border-[1.5px] text-[#B4B4B4]"
                                    name="yes"
                                    variant="outline-primary"
                                    onClick={handleLogin}
                                >
                                    그렇다
                                </button>
                                <button
                                    className={`border-{#B4B4B4} } h-10 w-[49%] max-w-[100px] rounded-md border-[1.5px]
                  text-[#B4B4B4]`}
                                    name="no"
                                    variant="outline-primary"
                                    onClick={handleLogin}
                                >
                                    아니다
                                </button>
                            </div>
                        </div>
                    </>
                ))}
                <div className="flex w-full items-center justify-center px-3 pb-28 pt-16 md:pt-24">
                    <div className="flex items-center justify-center">
                        <button
                            type="button"
                            className="textFont prevNextColor h-12 w-40 rounded-md text-center font-semibold text-[#7fb0f9] md:w-28"
                            onClick={handleLogin}
                        >
                            다음
                        </button>
                    </div>
                </div>
            </>
        </>
    )
}

export default DemoQuestions
