import { Modal, Row, Col } from 'react-bootstrap'
import './LoginModal.scss'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useEffect, useState } from 'react'
// import axios from 'axios';
import axios from '../../../api/axios'
import { Spinner } from '../../Spinner/Spinner'
import { UseUser } from '../../../api/context/userContext'
import { REACT_APP_REDIRECT_URL, REACT_APP_KAKAO_URL, REACT_APP_JS_KEY } from '../../../common/constants'
import { signInWithGooglePopup } from '../../../common/firebase'
import GoogleLogo from '../../../assets/icons/google-logo.png'
import KakaoImg from '../../../assets/icons/kakao.png'
import loadingImg from '../../../assets/images/loading.svg'

export function LoginModal({
    showLoginModal,
    setShowLoginModal,
    setShowLoginFailedModal,
    setShowForgetPasswordModal,
    setShowFindEmailModal,
    setSuccessAndErrorResponse,
    setShowErrorModal,
    isLoggedIn,
}) {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false) // to restrict Api calls to once
    const [disableButton, setDisableButton] = useState(false)
    const [foundEmail, setFoundEmail] = useState(null)
    const [isGoogleLoading, setIsGoogleLoading] = useState(false)

    const { getUser } = UseUser()
    const location = useLocation()

    let refferData = localStorage.getItem('refferData')
    refferData = refferData ? JSON.parse(refferData) : {}

    const handleMembership = () => {
        if (isLoggedIn) {
            setShowLoginModal(false)
            navigate('/')
        } else {
            setShowLoginModal(false)
            navigate('/create-account', { state: location?.state })
        }
    }

    const handleForgetPassword = () => {
        setShowLoginModal(false)
        setShowForgetPasswordModal(true)
    }

    const handleFindEmail = () => {
        setShowLoginModal(false)
        setShowFindEmailModal(true)
    }

    const handleKakaoLogin = () => {
        const CLIENT_ID = REACT_APP_JS_KEY
        const REDIRECT_URI = REACT_APP_REDIRECT_URL
        const kakaoURL = `${REACT_APP_KAKAO_URL}/oauth/authorize?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=code`
        window.location.href = kakaoURL
    }

    // ========================================================================================
    // ---------------------------Form Validation starts from Here-----------------------------
    // ========================================================================================
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm()

    const getInviteParam = () => {
        const queryParam = refferData?.queryParam
        if (queryParam) {
            const searchParams = new URLSearchParams(queryParam)
            return searchParams.get('q')
        }
        return null
    }
    // ========================================================================================
    // -----------------------------------APIs starts from Here----------------------------------
    // ==========================================================================================

    // -------------------request Login----------------------

    const redirectToDestination = () => {
        const pathname = refferData?.refererPathname
        localStorage.removeItem('refferData')
        pathname && navigate(pathname)
    }

    useEffect(() => {
        const foundEmailAddress = localStorage.getItem('foundEmail')
        if (foundEmailAddress) {
            setFoundEmail(foundEmailAddress)
            setValue('login', foundEmailAddress)
        }
    }, [])

    const handleNavigate = () => {
        if (getInviteParam()) {
            const fullPathName = refferData?.refererPathname
            getUser()
            navigate(fullPathName)
        } else {
            // navigate('/welcome');
        }
    }

    const onSubmit = (data) => {
        setLoading(true)
        const postData = {
            ...data, // spread the properties from the original object
        }
        // if (q) {
        //   postData['q'] = q;
        // }

        if (!isSubmitting) {
            setIsSubmitting(true)
            setDisableButton(true)
            axios
                .post('user/login', postData)
                .then((response) => {
                    if (response) {
                        // setSuccessAndErrorResponse(response.data.message);
                        // setShowSuccessModal(true);
                        localStorage.setItem('email', response.data.user.email)
                        localStorage.setItem('token', response.data.token.token)
                        localStorage.setItem('refreshToken', response.data.token.refreshToken)
                        localStorage.setItem('nickname', response.data.user.nickname)
                        localStorage.setItem('profileImage', response.data.user.profileIcon)
                        localStorage.removeItem('refferData')
                        localStorage.removeItem('foundEmail')
                        handleNavigate()
                        setShowLoginModal(false)
                        setIsSubmitting(false)
                        redirectToDestination()
                    }
                    getUser()
                    setLoading(false)
                })
                .catch((error) => {
                    if (error?.response?.status === 400) {
                        setSuccessAndErrorResponse(error?.response?.data?.message)
                        setShowErrorModal(true)
                    } else {
                        setShowLoginFailedModal(true)
                    }
                    setShowLoginModal(false)
                    setIsSubmitting(false)
                })
                .finally(() => {
                    setLoading(false)
                    setDisableButton(false)
                })
        }
    }

    const logGoogleUser = async () => {
        try {
            setIsGoogleLoading(true)
            setDisableButton(true)
            const response = await signInWithGooglePopup()
            const { idToken } = response._tokenResponse
            const postData = {
                id_token: idToken,
            }
            if (idToken) {
                await axios
                    .post('/user/googleLogin', postData, { timeout: 25000 })
                    .then((response) => {
                        if (response) {
                            if (!response.data.user) {
                                localStorage.setItem('id_token', idToken)
                                localStorage.setItem('loginType', 'google')
                                navigate('/marketing')
                            } else {
                                localStorage.setItem('email', response.data.user.email)
                                localStorage.setItem('token', response.data.token.token)
                                localStorage.setItem('refreshToken', response.data.token.refreshToken)
                                localStorage.setItem('nickname', response.data.user.nickname)
                                localStorage.setItem('profileImage', response.data.user.profileIcon)
                                localStorage.removeItem('refferData')
                                localStorage.removeItem('foundEmail')
                                handleNavigate()
                                redirectToDestination()
                                getUser()
                            }
                            setShowLoginModal(false)
                            setIsSubmitting(false)
                        }
                        setLoading(false)
                    })
                    .catch((error) => {
                        if (error?.response?.status === 400) {
                            setSuccessAndErrorResponse(error?.response?.data?.message)
                            setShowErrorModal(true)
                        } else {
                            setShowLoginFailedModal(true)
                        }
                        setShowLoginModal(false)
                        setIsSubmitting(false)
                    })
                    .finally(() => {
                        setLoading(false)
                        setDisableButton(false)
                        setIsGoogleLoading(false)
                    })
            }
        } catch (error) {
            if (error.code === 'auth/popup-closed-by-user') {
                alert('Google 로그인을 종료합니다')
            }
            setLoading(false)
            setDisableButton(false)
            setIsGoogleLoading(false)
        }
    }

    return (
        <Modal
            className="login-modal-wrapper"
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={showLoginModal}
            onHide={() => {
                setShowLoginModal(false)
                localStorage.removeItem('foundEmail')
            }}
        >
            {loading && <Spinner />}
            <Modal.Header>
                <button
                    type="button"
                    className="btn-close modal-close-btn"
                    data-bs-dismiss="modal"
                    onClick={() => {
                        setShowLoginModal(false)
                        localStorage.removeItem('foundEmail')
                    }}
                    aria-label="Close"
                />
            </Modal.Header>
            <Modal.Body className="-my-8 px-[21px] md:px-[55px]">
                <Row>
                    <Col className="col-12 loginmodal-text d-flex justify-content-center">로그인 해주세요.</Col>
                    <Col
                        className="col-12 mt-[10px] flex justify-center text-center text-[16px] font-medium leading-[19px] text-[#1C319F] md:text-[20px] md:leading-[24px]"
                        style={{ fontFamily: 'Pretendard' }}
                    >
                        계정이 없으신가요?
                        <br />
                        회원가입 후 DNA 질문검사를 시작해보세요!
                    </Col>

                    {/* ---------------Form Starts Here--------------- */}
                    <Row className="mb-6 mt-4 flex items-center justify-between px-4" />
                    <form className="form" onSubmit={handleSubmit(onSubmit)}>
                        <Col className="col-12 d-flex justify-content-center  ">
                            <button
                                onClick={handleKakaoLogin}
                                disabled={disableButton}
                                type="button"
                                style={{ fontFamily: 'Pretendard' }}
                                className="text-black btn  flex h-[50px] w-full items-center justify-center bg-[#FFEB00] text-[12px] font-bold leading-[19px]  md:h-[62px] md:max-w-[421px] md:text-[20px] md:leading-[24px]"
                            >
                                <img src={KakaoImg} alt="google login" className="mr-1 w-[40px]" />
                                카카오계정으로 로그인
                            </button>
                        </Col>

                        <Col className="col-12 d-flex justify-content-center  mt-[1rem]">
                            <button
                                disabled={disableButton}
                                type="button"
                                className="text-black btn  flex h-[50px] w-full items-center justify-center border-[#34A853] text-center  font-Pretendard text-[12px] font-bold leading-[19px]  md:h-[62px] md:max-w-[421px] md:text-[20px] md:leading-[24px]"
                                onClick={logGoogleUser}
                            >
                                <img src={GoogleLogo} alt="google login" className="mr-2 w-[30px]" />
                                <div>Google로 로그인 </div>
                                {isGoogleLoading && (
                                    <div className="d-flex align-items-center justify-content-center  ml-3">
                                        <img src={loadingImg} className="mx-auto h-6 w-6 animate-spin md:h-6 md:w-6" alt="loading" />
                                    </div>
                                )}
                            </button>
                        </Col>
                        <Col className="col-12 my-6 flex items-center justify-center ">
                            <hr className="h-[0.5px] w-[30%] bg-[#817f7f]" />
                            <div className="mx-2 font-Pretendard">OR</div>
                            <hr className="h-[0.5px] w-[30%] bg-[#817f7f]" />
                        </Col>
                        <Col className="col-12">
                            <label className="label">사용자 ID / 이메일 주소</label>
                            <div className="input-icons mt-1 flex items-center">
                                <svg
                                    className="ml-4 h-[25px] w-[25px] md:ml-6 md:h-[34px] md:w-[34px]"
                                    width="34"
                                    height="34"
                                    viewBox="0 0 36 36"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M18 2C12.486 2 8 6.486 8 12C8 17.514 12.486 22 18 22C23.514 22 28 17.514 28 12C28 6.486 23.514 2 18 2ZM18 24C25.016 24 32 25.687 32 29V31H4V29C4 25.687 10.984 24 18 24Z"
                                        fill="#272727"
                                    />
                                </svg>
                                <input
                                    className={`form-control input-field h-[50px] pl-[50px] md:h-[70px] md:pl-[80px] ${
                                        errors.login && 'border-[1.5px] border-[#FF0000] focus:outline-none'
                                    } ${foundEmail && 'cursor-not-allowed'}`}
                                    placeholder={foundEmail || '사용자 ID / 이메일 주소'}
                                    disabled={disableButton || foundEmail}
                                    type="text"
                                    readOnly={!!foundEmail}
                                    {...register('login', {
                                        required: true,
                                        pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+|^[a-zA-Z0-9]{5,20}$/,
                                    })}
                                />
                            </div>
                            {errors.login && <p className="error">형식에 맞는 이메일 주소를 정확하게 입력해주세요.</p>}

                            {/* ----------------Password--------------- */}

                            <label className="label mt-[1rem] md:mt-[2rem]">패스워드</label>
                            <div className="input-icons mt-1 flex items-center">
                                <svg
                                    className="ml-4 h-[25px] w-[25px] md:ml-6 md:h-[34px] md:w-[34px]"
                                    width="36"
                                    height="36"
                                    viewBox="0 0 36 36"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M18 3C13.8645 3 10.5 6.3645 10.5 10.5V15H9C8.20435 15 7.44129 15.3161 6.87868 15.8787C6.31607 16.4413 6 17.2044 6 18V30C6 30.7956 6.31607 31.5587 6.87868 32.1213C7.44129 32.6839 8.20435 33 9 33H27C27.7956 33 28.5587 32.6839 29.1213 32.1213C29.6839 31.5587 30 30.7956 30 30V18C30 17.2044 29.6839 16.4413 29.1213 15.8787C28.5587 15.3161 27.7956 15 27 15H25.5V10.5C25.5 6.3645 22.1355 3 18 3ZM13.5 10.5C13.5 8.019 15.519 6 18 6C20.481 6 22.5 8.019 22.5 10.5V15H13.5V10.5ZM19.5 26.5845V30H16.5V26.5845C15.9756 26.2842 15.5545 25.832 15.2922 25.2875C15.03 24.7431 14.9389 24.1319 15.031 23.5347C15.1231 22.9374 15.3941 22.3821 15.8081 21.9419C16.2222 21.5017 16.76 21.1974 17.3505 21.069C17.7891 20.972 18.2439 20.9747 18.6814 21.0769C19.1188 21.1791 19.5277 21.3782 19.878 21.6595C20.2282 21.9408 20.5109 22.2971 20.7051 22.7022C20.8993 23.1073 21 23.5508 21 24C20.9991 24.5246 20.86 25.0397 20.5967 25.4935C20.3334 25.9472 19.9551 26.3235 19.5 26.5845Z"
                                        fill="#272727"
                                    />
                                </svg>
                                <input
                                    className={`form-control input-field h-[50px] pl-[50px] md:h-[70px] md:pl-[80px] ${
                                        errors.password && 'border-[1.5px] border-[#FF0000] focus:outline-none'
                                    }`}
                                    placeholder="비밀번호"
                                    disabled={disableButton}
                                    type="password"
                                    {...register('password', {
                                        required: true,
                                        // Google Password Criteria
                                        pattern:
                                            /^(?=.*[0-9])(?=.*[!@#$%^&*(),.?":{}|<>])(?=.*[a-zA-Z])[0-9a-zA-Z!@#$%^&*(),.?":{}|<>]{8,16}$/,
                                    })}
                                />
                            </div>
                            {errors.password?.type === 'required' && <p className="error">입력 필드가 비어 있습니다.</p>}
                            {errors.password?.type === 'pattern' && (
                                <p className="error">회원가입 시 설정했던 비밀번호를 정확하게 입력해주세요.</p>
                            )}
                        </Col>
                        <Row className="mt-[3rem] items-center justify-center">
                            <Col className="col-4 d-flex justify-content-center ">
                                <button
                                    disabled={disableButton}
                                    type="submit"
                                    className="text-white btn  h-[50px] w-full bg-[#005BE2] text-[16px] font-semibold leading-[19px]  md:h-[60px] md:max-w-[421px] md:text-[20px] md:leading-[24px]"
                                >
                                    로그인
                                </button>
                            </Col>
                            <Col className=" col-4 d-flex justify-content-center ">
                                <button
                                    disabled={disableButton}
                                    className="btn btn-outline-primary mx-auto h-[50px] w-full text-[16px] font-semibold leading-[19px] md:h-[60px] md:max-w-[421px] md:text-[20px] md:leading-[24px]"
                                    onClick={handleMembership}
                                >
                                    회원가입
                                </button>
                            </Col>
                        </Row>
                    </form>

                    {/* ---------------Form Ends--------------- */}

                    <Col className="col-12 d-flex justify-content-center mb-[2.5rem] mt-[0.75rem] md:mb-[3rem] md:mt-[2rem]">
                        <Link className={`link  ${disableButton ? 'opacity-40' : ''}`} onClick={disableButton ? null : handleFindEmail}>
                            계정 찾기
                        </Link>
                        <span className="text-[#CCCCCC]">&nbsp;&nbsp;| &nbsp;&nbsp;</span>
                        <Link
                            className={`link  ${disableButton ? 'opacity-40' : ''}`}
                            onClick={disableButton ? null : handleForgetPassword}
                        >
                            비밀번호 재설정
                        </Link>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    )
}
