import { Modal, Row, Col } from 'react-bootstrap'
import './SuccessModal.scss'
import SuccessTickIcon from '../../../assets/icons/successTickIcon.svg'

export function SuccessModal({ showSuccessModal, setShowSuccessModal, successAndErrorResponse }) {
    return (
        <Modal
            className="success-modal-wrapper"
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={showSuccessModal}
            onHide={() => setShowSuccessModal(false)}
        >
            <Modal.Header>
                <button
                    type="button"
                    className="btn-close modal-close-btn"
                    data-bs-dismiss="modal"
                    onClick={() => setShowSuccessModal(false)}
                    aria-label="Close"
                />
            </Modal.Header>
            <Modal.Body>
                <Row className="-translate-y-4 md:-translate-y-2 md:gap-2">
                    <Col className="col-12 success-text d-flex justify-content-center py-3">
                        <img src={SuccessTickIcon} alt="SuccessTickIcon" className="h-[80px] md:h-[100px]" />
                    </Col>
                    <Col className="col-12 success-text d-flex justify-content-center py-3">{successAndErrorResponse}</Col>

                    <Col className="col-12 d-flex justify-content-center md:my-3">
                        <button
                            className="btn btn-primary h-[50px] w-[48%] max-md:mb-6 md:my-6 md:h-[60px] md:w-[35%]"
                            onClick={() => setShowSuccessModal(false)}
                        >
                            확인
                        </button>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    )
}
