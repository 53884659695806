/* eslint-disable camelcase */
import React from 'react'
import DNAtype_II from '../../../assets/images/DNAtype-II.svg'
import DNAtype_ID from '../../../assets/images/DNAtype-ID.svg'
import DNAtype_DD from '../../../assets/images/DNAtype-DD.svg'
import enduranceGene from '../../../assets/images/enduranceGene.svg'

export function EnduranceGene() {
    const EnduranceData = [
        {
            id: 1,
            heading: `지구력 유전자
      (끈기, 인내와 같은 지구력, 체력, 에너지 성향을 알아볼 수 있는 유전자)
      ACE (Angiotensin converting Enzyme) PCR`,
            description: `혈압상승물질(angiontensin)을 변환시켜주는 역할을 하는 유전자로, 혈압 조절에 관여하여 심폐지구력과 근력에 영향을 주는 유전자이며, 
      선천적인 지구력에 영향을 주고 이는 외향적인 성격, 감성이 풍부한 성격, 학업 수행능력 등의 성격형성에도 영향을 줍니다.`,
            card: [
                {
                    id: 1,
                    heading: `II 타입`,
                    description: `지구력 강함, 학습 및 운동에 유리, 침착, 온순
          이기적, 독선적 기질 강함
          II 타입이 주로 많은 “소음인”
          눈치가 빠르고 치밀, 꼼꼼한 성격
          한번 토라지면 잘 풀리지 않으며, 편식, 위가 약함 `,
                    image: DNAtype_II,
                },
                {
                    id: 2,
                    heading: `ID 타입`,
                    description: `영민한 머리, 의욕적, 적극적 사고, 명랑, 활발
          의리파, 기분파, 마무리가 매우 약함
          ID 타입이 주로 많은 “소양인”
          명랑하고 활달한 기질, 다소 산만, 음식을 가리지 않음
          키가 큰 편이며, 비뇨기가 약함`,
                    image: DNAtype_ID,
                },
                {
                    id: 3,
                    heading: `DD 타입`,
                    description: `따뜻한 마음, 높은 감성지수, 타인에 대한 배려가 많음
          효자 · 부자 유전자 보유
          소극적, 내성적
          DD 타입이 주로 많은 ”태음인”
          말이 없고 조용한 기질, 꾸준하고 단순한 면이 많음
          땀이 많고 피부가 약하며, 심장질환 가능성이 큼`,
                    image: DNAtype_DD,
                },
            ],
            image: enduranceGene,
            details: [
                {
                    id: 1,
                    heading: `지구력 = 체력 = 에너지 유전자(Angiotensin Converting Enzyme PCR)의 학술적 원리 기능`,
                    description: `간에서 만들어진 Agiotensinogen이 renin이라는 효소에 의해 Angiotensin I 으로 분해되고 Angiotensin I은 ACE 효소에 의해 Angiotensin II로 만들
          어지는데, 이 때 Angiotencin II가 혈압을 올리는 역할을 담당하게 된다.
          일반적으로 지구력 유전자의 DD 타입이 II 타입에 비해 ACE 효소량이 2배정도 많아서 운동이나 일을 지속하는데 지장을 많이 주게 된다.
          이러한 이유는 ACE 효소가 상대적으로 많기 때문에 혈압을 올리는 작용을 하기 때문이다.
          혈압이 높다는 것은 그만큼 심장에 무리가 따른다는 것이고 피로도가 증가하게 됨을 의미한다.`,
                },
                {
                    id: 2,
                    heading: `지구력 유전자 검사에 대한 소개`,
                    description: `지구력 유전자는 국내외 병원에서 오랫동안 심장질환 관련 연구를 통해 알려진 유전자로 II, ID, DD 모두 3가지 타입이 있습니다.
          II 타입이 지구력이 제일 강하며, ID 타입은 중간, DD 타입은 지구력이 제일 떨어집니다.
          지구력이란 힘이 세거나 체격이 좋은 것이 아닌 끈기와 체력이 강한 것을 의미합니다.`,
                },
            ],
            points: [
                {
                    id: 1,
                    heading: `왜 II 타입이 DD 타입에 비해 지구력이 좋은 것일까?`,
                    description: [
                        {
                            id: 1,
                            li: `II 타입에 비해 DD 타입은 혈관을 수축하는 힘이 2배정도 강함`,
                        },
                        {
                            id: 2,
                            li: `격렬한 운동이나 과로한 업무를 할 때 영양분과 산소를 많이 필요로 함`,
                        },
                        {
                            id: 3,
                            li: `DD 타입은 II 타입에 비해 심장 박동을 강하게 해야 함`,
                        },
                        {
                            id: 4,
                            li: `이것이 장기간 지속되면 심장 벽이 두꺼워지고 확장되는 현상이 나타나며 심장마비나 심근 경색이 일어나기 쉽고 돌연사, 과로사의 주된 원인이 됨`,
                        },
                        {
                            id: 5,
                            li: `인간의 몸은 본능적으로 자신을 보호하는 기능이 있어서 DD 타입의 대부분은 자신의 몸에 무리가 가지 않도록 활동성이 떨어짐`,
                        },
                        {
                            id: 6,
                            li: `이러한 원인으로 DD 타입은 어떤 일을 할 때 II 타입에 비해 지속성, 지구력이 현저히 떨어짐`,
                        },
                    ],
                },
                {
                    id: 2,
                    heading: `지구력 유전자는 학습과 어떤 관련이 있을까?`,
                    description: [
                        {
                            id: 1,
                            li: `공부를 잘하고 성적이 향상되려면 지구력(체력)이 좋아야함`,
                        },
                        {
                            id: 2,
                            li: `II 타입이나 ID 타입은 기본적으로 지구력을 갖춘 그룹임`,
                        },
                        {
                            id: 3,
                            li: `II 타입은 이기적이고 독선적인 면이 강하고 조직에서 리더가 되고자 하는 욕망이 강하기 때문에 학습에도 적극적이고 열심히 하려는 타입임`,
                        },
                        {
                            id: 4,
                            li: `ID 타입은 초등학교와 중학교 시절에 두각을 보이지만 고학년으로 갈수록 친구가 많아져 지구력이 떨어질 확률이 높고 기질적으로 마무리가 약한 면 때문에 학습에 불리함`,
                        },
                        {
                            id: 5,
                            li: `DD 타입은 상대적으로 지구력이 약하고 지속성이 떨어지므로, 학습에 불리함. 따라서 대개의 DD 타입은 학업에서 시간을 효율적으로 사용해야함`,
                        },
                        {
                            id: 6,
                            li: `DD 타입은 지구력은 떨어지더라도 감성지수가 상대적으로 높은 편이며, 대개 법 없이도 살 수 있는 따뜻한 심성을 가진 그룹이고 DD 타입은 아이디어가 많으며, 사업가의 기질이 매우 강함`,
                        },
                    ],
                },
            ],
        },
    ]
    return (
        <section className="relative mt-12 mb-24 md:my-32 px-[20px] lg:px-[9.3%]">
            {EnduranceData.map((k) => (
                <>
                    <div key={k.id} className="flex flex-col gap-[1rem] md:flex-row-reverse md:justify-between md:gap-[4rem]">
                        {/* background pattern */}
                        <div className="md:hidden top-[52%] left-[0rem] bg-cover bg-no-repeat bg-[url('./assets/images/endurenceGeneMobileBg.svg')] absolute h-[734px] w-[361px] -z-[1]" />
                        <div className="hidden md:block md:left-[0%] md:top-[59.5%] bg-cover bg-no-repeat bg-[url('./assets/images/endurenceGeneBg.svg')] absolute md:h-[1058px] md:w-[1168px] -z-[1]" />

                        <div className="mx-auto md:ml-[0px] md:mx-0 w-1/2 md:w-1/5" />
                        <div className="flex flex-col gap-[16px] md:gap-[40px]">
                            <div className="whitespace-pre-line text-left text-[20px] leading-[24px] font-semibold md:text-[30px] md:leading-[36px]">
                                {k.heading}
                            </div>
                            <div className="mb-[2rem] md:mb-[4rem] whitespace-pre-line text-left text-[16px] leading-[24px] font-normal ">
                                {k.description}
                            </div>
                        </div>
                    </div>
                    {/* card */}
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
                        {k.card.map((card) => (
                            <div key={card.id} className="bg-white rounded-[8px] shadow-[3px_4px_80px_rgba(194,203,214,0.2)] xl:h-[450px]">
                                <div className="rounded-t-[8px] bg-[#93CA49] h-[50px] md:h-[69px] text-white flex items-center justify-center text-[20px] leading-[24px] md:text-[24px] md:leading-[29px] font-semibold">
                                    {card.heading}
                                </div>
                                <div className="text-left py-3 whitespace-pre-line text-[16px] leading-[19px] md:text-[16px] md:leading-[24px] font-normal px-6">
                                    {card.description}
                                </div>
                                <img src={card.image} alt={card.image} className="mt-[20px] mb-[16px] md:mt-8 md:mb-4 mx-auto" />
                            </div>
                        ))}
                    </div>
                    <img src={k.image} alt={k.image} className="w-100 mt-12 mb-[0] md:-mb-[2rem] md:my-24 mx-auto rounded-[10px]" />
                    {/* details */}
                    <div className="">
                        {k.details.map((detail) => (
                            <div key={detail.id} className="flex flex-col gap-[16px] md:gap-[20px]">
                                <div className="mt-[2rem] md:mt-[6rem] text-left whitespace-pre-line text-[20px] leading-[24px] md:text-[24px] md:leading-[29px] font-semibold">
                                    {detail.heading}
                                </div>
                                <div className="text-left whitespace-pre-line text-[16px] leading-[24px] font-normal">
                                    {detail.description}
                                </div>
                            </div>
                        ))}
                    </div>
                    {/* points */}
                    <div className="">
                        {k.points.map((points) => (
                            <div key={points.id} className="flex flex-col gap-[16px] md:gap-[20px]">
                                <div className="mt-[2rem] md:mt-[6rem] text-left whitespace-pre-line text-[20px] leading-[24px] md:text-[24px] md:leading-[29px] font-semibold">
                                    {points.heading}
                                </div>
                                <ol className="list-disc text-left whitespace-pre-line text-[16px] leading-[24px] font-normal">
                                    {points.description.map((list) => (
                                        <li key={list.li}>{list.li}</li>
                                    ))}
                                </ol>
                            </div>
                        ))}
                    </div>
                </>
            ))}
        </section>
    )
}
