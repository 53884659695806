import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import alertCircleOutline from '../../../assets/icons/alertCircleOutline.svg'
import { signOutFromGoogle, kakaoSignOut } from '../../../common/firebase'
import axios from '../../../api/axios'
import loading from '../../../assets/images/loading.svg'
import { UseUser } from '../../../api/context/userContext'

function DisconnectModal({ showDisconnectModal, setShowDisconnectModal, email, socialLoginType }) {
    const { getUser } = UseUser()
    const [showLoading, setShowLoading] = useState(false)
    const handleGoogleDisconnect = async () => {
        if (!email || !socialLoginType) {
            return
        }
        setShowLoading(true)

        try {
            if (socialLoginType === 'kakao') {
                await kakaoSignOut()
            }
            if (socialLoginType === 'google') {
                await signOutFromGoogle()
            }

            const postData = {
                email,
                socialLoginType,
            }

            await axios.post('/user/disconnect', postData, { timeout: 25000 }).then(async (response) => {
                await getUser()
                setShowDisconnectModal(false)
                alert(response?.data?.message)
            })
        } catch (error) {
            setShowLoading(false)
            console.log({ error })
        }
    }
    return (
        <Modal
            size="lg"
            centered
            aria-labelledby="contained-modal-title-vcenter "
            show={showDisconnectModal}
            onHide={() => setShowDisconnectModal(false)}
            dialogClassName="w-[90%] md:w-[75%] max-w-[660px] mx-auto flex items-center"
        >
            <Modal.Header closeButton className="border-none pr-8 pt-7" />
            <Modal.Body className="errorModal h-[300px] font-Pretendard md:h-[390px]">
                <img src={alertCircleOutline} alt="alertCircleOutline" className=" ml-1 h-[50px] md:h-[70px]" />

                <div className="mx-auto mb-1 ml-3 block w-80 text-center font-normal leading-[24px] md:h-[90px]  md:leading-[30px] lg:text-[18px]">
                    {email} 계정을 연결 해제하시겠습니까?
                </div>
                {showLoading && (
                    <div className="d-flex align-items-center justify-content-center  w-full">
                        <img src={loading} className="mx-auto h-6 w-6 animate-spin md:h-6 md:w-6" alt="loading" />
                    </div>
                )}
                <div className="mb-14 ml-3  flex w-full justify-center space-x-4 ">
                    <button
                        disabled={showLoading}
                        onClick={() => setShowDisconnectModal(false)}
                        className="border-blue-500 h-[50px] w-[240px] rounded-lg border-2 bg-[#FFFFFF] text-center text-[16px] font-semibold leading-[19px] text-[#005BE2] md:h-[60px]"
                    >
                        취소
                    </button>
                    <button
                        onClick={handleGoogleDisconnect}
                        variant="primary"
                        disabled={showLoading}
                        className="h-[50px]  w-[240px] rounded-lg bg-[#005BE2]  text-center text-[16px] font-semibold leading-[19px] text-[#ffffff] md:h-[60px]"
                    >
                        계속하다
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default DisconnectModal
