import React, { useEffect, useState } from 'react'
import { useHomePage } from '../../hooks/useHomePage'
import RegisterReviewDialog from '../../components/Dialogs/RegisterReviewDialog'
import axios from '../../api/axios'

export function Reviews() {
    const { setReviewModal, reviewModal } = useHomePage()
    const [userReview, setUserReview] = useState(null)
    const [reviewValue, setReviewValue] = useState(null)

    const getUserReview = () => {
        axios
            .get('/reviews/myreview')
            .then((response) => {
                setUserReview(response.data.data)
            })
            .catch((error) => {
                console.log('error', error)
            })
    }

    useEffect(() => {
        getUserReview()
        if (userReview?.isReview === 1) {
            setReviewValue(userReview?.review?.contents)
        }
    }, [userReview?.isReview, userReview?.review?.contents])

    return (
        <div className="mt-[100px] lg:mt-[130px]">
            {reviewModal ? (
                <RegisterReviewDialog
                    isReviewAdded={userReview?.isReview === 1}
                    reviewValue={reviewValue}
                    setReviewValue={setReviewValue}
                    userReview={userReview}
                    isOpen={reviewModal}
                    setIsOpen={setReviewModal}
                    getUserReview={getUserReview}
                />
            ) : (
                ''
            )}
            <div>
                <h1 className="text-left text-[1.5rem] font-bold leading-[2.25rem] md:text-[2.25rem] md:leading-[3.375rem]">사용후기</h1>
                <p className="text-left text-[1rem] font-normal">회원님의 타입 리포트를 보고 느낀 소감을 사용후기로 등록해보세요.</p>
            </div>
            <div className="mt-[15px] sm:mt-[20px] sxl:mt-[28px]">
                <textarea
                    value={reviewValue}
                    disabled
                    className="my-4 block h-[200px] w-full whitespace-pre-wrap rounded-[8px] border-[2px] border-[#CCCCCC] bg-[#fafafa] bg-no-repeat p-[16px] text-left text-[0.8rem] font-normal leading-[1.2] placeholder:text-[#999999] focus:outline-none sm:p-[20px] sm:text-[16px] md:text-[1rem] sxl:p-[20px]"
                    name=""
                    id=""
                    cols="30"
                    rows="8"
                />
            </div>
            <button
                type="button"
                onClick={() => setReviewModal(true)}
                className=" text-white font-semi-bold ml-auto mt-[8px] inline-flex h-[50px] w-full max-w-full items-center justify-center rounded-[8px] bg-[#005BE2] px-[30px] py-[15.5px] text-[16px] leading-[19px] tracking-[-0.03em] no-underline  md:h-[60px] md:w-[240px]"
            >
                {`${userReview?.isReview === 1 ? '사용후기 수정' : '사용후기 등록'}`}
            </button>
        </div>
    )
}
