import clock from '../../assets/images/clock.svg'
import handHeart from '../../assets/images/hand-heart.svg'
import clipboard from '../../assets/images/clipboard.svg'

const CardsData = [
    {
        id: '1',
        image: clock,
        content: (
            <p className=" md:text-center ">
                각 유형별 검사의 질문 응답은 <br /> 8분 이내가 좋습니다.
            </p>
        ),
    },
    {
        id: '2',
        image: handHeart,
        content: (
            <p className="md:text-center  ">
                혹 질문이 마음에 들지 않더라도 <br /> 정직하게 답변하십시오.
            </p>
        ),
    },
    {
        id: '3',
        image: clipboard,
        content: (
            <p className=" md:text-center ">
                질문 유형은 A유형부터 D유형까지
                <br /> 단계별로 4가지 유형입니다.
            </p>
        ),
    },
]

export default CardsData
