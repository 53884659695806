import { useForm } from 'react-hook-form'
import { Col } from 'react-bootstrap'

export function EmailFoundComp({ foundUserIdMsg, foundEmailMsg, setShowFindEmailModal, setShowLoginModal, setShowForgetPasswordModal }) {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm()

    const handleFindPasssword = () => {
        setShowFindEmailModal(false)
        setShowForgetPasswordModal(true)
    }

    const onSubmit = (data) => {
        setShowFindEmailModal(false)
        setShowLoginModal(true)
        localStorage.setItem('foundEmail', data.selectedRadioBtn)
    }

    return (
        <form className="emailFoundForm" onSubmit={handleSubmit(onSubmit)}>
            <div className="borderLine" />
            <Col className="col-12 mt-[1.25rem] md:mt-[1.75rem] text-center">
                <div className="radioCont">
                    <input
                        type="radio"
                        name="radioBtnGroup"
                        className="w-[20px] h-[20px]"
                        value={foundEmailMsg}
                        {...register('selectedRadioBtn', {
                            required: true,
                        })}
                    />
                    <label htmlFor="emailRadioBtn">{foundEmailMsg}</label>
                </div>
            </Col>
            <Col className="col-12 mt-[1.25rem] md:mt-[1.75rem] text-center">
                <div className="radioCont">
                    <input
                        type="radio"
                        name="radioBtnGroup"
                        className="w-[20px] h-[20px]"
                        value={foundUserIdMsg}
                        {...register('selectedRadioBtn', {
                            required: true,
                        })}
                    />
                    <label htmlFor="userIdRadioBtn">{foundUserIdMsg}</label>
                </div>
            </Col>
            {errors.selectedRadioBtn && <p className="error">위에서 선택해주세요</p>}
            <Col className="col-12 d-flex flex-column align-items-center btns">
                <button type="submit" className="loginBtn d-flex justify-content-center align-items-center">
                    로그인
                </button>
                <span className="findPasswordBtn" onClick={handleFindPasssword}>
                    비밀번호 찾기
                </span>
            </Col>
        </form>
    )
}
