import { Link } from 'react-router-dom'
import './ReportTypes.scss'
import ReportTypesArray from './ReportTypesArray'

function ReportTypes() {
    return (
        <div className="report-types-wrapper">
            {ReportTypesArray.map((item) => (
                <div className="report-type-div" key={item.id}>
                    <div className="report-type-heading">{item.content}</div>
                    <div className="report-type-description">{item.contentDescription}</div>

                    <div className="buttons">
                        {item.button.map((b) => {
                            return (
                                <div key={b.id}>
                                    <Link to={`typeReport/${b.id}`} className="button" style={{ backgroundColor: b.backgroundColor }}>
                                        <div className="button_heading">{b.button_heading}</div>
                                        <div className="button_content">{b.button_content}</div>
                                    </Link>
                                </div>
                            )
                        })}
                    </div>
                </div>
            ))}
        </div>
    )
}
export default ReportTypes
