import { useState } from 'react'
import { Modal, Row, Col } from 'react-bootstrap'
import './FindEmailModal.scss'
import { InitialComp } from './InitialComp'
import { FindEmailForm } from './FindEmailForm'
import { EmailFoundComp } from './EmailFoundComp'
import { EmailNotFound } from './EmailNotFound'

export function FindEmailModal({ showFindEmailModal, setShowFindEmailModal, setShowForgetPasswordModal, setShowLoginModal }) {
    const [activeComp, setActiveComp] = useState('initial')
    const [foundEmailMsg, setFoundEmailMsg] = useState('')
    const [foundUserIdMsg, setFoundUserIdMsg] = useState('')

    const handleFindEmail = () => {
        setActiveComp('initial')
    }

    const handleForgetPassword = () => {
        setShowFindEmailModal(false)
        setShowForgetPasswordModal(true)
    }

    const SubComponents = {
        initial: <InitialComp setActiveComp={setActiveComp} setFoundEmailMsg={setFoundEmailMsg} setFoundUserIdMsg={setFoundUserIdMsg} />,
        findByNumber: (
            <FindEmailForm setActiveComp={setActiveComp} setFoundEmailMsg={setFoundEmailMsg} setFoundUserIdMsg={setFoundUserIdMsg} />
        ),
        emailFound: (
            <EmailFoundComp
                setActiveComp={setActiveComp}
                setShowFindEmailModal={setShowFindEmailModal}
                setShowForgetPasswordModal={setShowForgetPasswordModal}
                setShowLoginModal={setShowLoginModal}
                foundEmailMsg={foundEmailMsg}
                foundUserIdMsg={foundUserIdMsg}
            />
        ),
        emailNotFound: (
            <EmailNotFound
                setActiveComp={setActiveComp}
                handleFindEmail={handleFindEmail}
                handleForgetPassword={handleForgetPassword}
                foundEmailMsg={foundEmailMsg}
            />
        ),
    }

    return (
        <Modal
            className="find-email-modal-wrapper"
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={showFindEmailModal}
            onHide={() => setShowFindEmailModal(false)}
        >
            <Modal.Header>
                <button
                    type="button"
                    className="btn-close modal-close-btn text-black"
                    data-bs-dismiss="modal"
                    onClick={() => setShowFindEmailModal(false)}
                    aria-label="Close"
                />
            </Modal.Header>
            <Modal.Body className="px-[21px] md:px-[65px]">
                <Row>
                    {activeComp !== 'emailNotFound' ? (
                        <>
                            <Col className="col-12 find-email-heading d-flex justify-content-center">계정 찾기</Col>
                            <Col className="col-12 find-email-text d-flex justify-content-center pt-[0.75rem] md:pt-[1.25rem]">
                                회원 정보에 등록한 휴대폰 번호로 인증합니다.
                            </Col>
                        </>
                    ) : (
                        <>
                            <Col className="col-12 find-email-heading d-flex justify-content-center">죄송합니다.</Col>
                            <Col className="col-12 find-email-text d-flex justify-content-center mt-[2rem] md:mt-[3.5rem]">
                                {foundEmailMsg}
                            </Col>
                        </>
                    )}

                    {SubComponents[activeComp]}
                </Row>
            </Modal.Body>
        </Modal>
    )
}
