import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { UseUser } from '../api/context/userContext'

export const useHomePage = () => {
    const navigate = useNavigate()
    const { userData, setUserData } = UseUser()
    // ----------------Custom Modal States---------------
    const [showLoginModal, setShowLoginModal] = useState(false)
    const [showLoginFailedModal, setShowLoginFailedModal] = useState(false)
    const [showSearchPopup, setShowSearchPopup] = useState(false)
    const [showFindEmailModal, setShowFindEmailModal] = useState(false)
    const [showForgetPasswordModal, setShowForgetPasswordModal] = useState(false)
    const [resetPasswordWrongEmailModal, setResetPasswordWrongEmailModal] = useState(false)
    const [passwordError, setPasswordError] = useState(null)
    const [showRetakeTestModal, setShowRetakeTestModal] = useState(false)
    const [showSessionExpiredModal, setShowSessionExpiredModal] = useState(false)
    const [reviewModal, setReviewModal] = useState(false)

    // -------------Success And Error Modals---------------

    const [showSuccessModal, setShowSuccessModal] = useState(false)
    const [showErrorModal, setShowErrorModal] = useState(false)
    // ---------------- Responses-----------------
    const [successAndErrorResponse, setSuccessAndErrorResponse] = useState(null)

    // ----------------Login the User-----------------
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const location = useLocation()

    useEffect(() => {
        if (userData) {
            setIsLoggedIn(true)
        } else if (location?.state?.showLogin) {
            setShowLoginModal(true)
        }
    }, [successAndErrorResponse, isLoggedIn])

    // ----------------Logout the User-----------------
    const Logout = () => {
        setIsLoggedIn(false)
        setSuccessAndErrorResponse('사용자 로그아웃 성공 ')

        const kakaoAccessToken = localStorage.getItem('kakaoAccessToken')
        if (kakaoAccessToken) {
            axios
                .post(
                    process.env.REACT_APP_KAKAO_API_LOGOUT_URL || 'https://kapi.kakao.com/v1/user/logout',
                    {},
                    {
                        headers: {
                            Authorization: `Bearer ${kakaoAccessToken}`,
                            'Content-type': 'application/x-www-form-urlencoded;charset=utf-8',
                        },
                    }
                )
                .then((res) => {
                    localStorage.removeItem('kakaoAccessToken')
                    console.log('response from kakao', res)
                })
        }
        localStorage.clear()
        setShowSuccessModal(true)
        navigate('/?mode=hide')
        setUserData(null)
    }

    return {
        // ------Custom Modals-----------
        reviewModal,
        setReviewModal,
        showLoginModal,
        setShowLoginModal,
        showLoginFailedModal,
        setShowLoginFailedModal,
        showSearchPopup,
        setShowSearchPopup,
        showForgetPasswordModal,
        setShowForgetPasswordModal,
        showFindEmailModal,
        setShowFindEmailModal,
        resetPasswordWrongEmailModal,
        setResetPasswordWrongEmailModal,
        passwordError,
        setPasswordError,
        showRetakeTestModal,
        setShowRetakeTestModal,
        showSessionExpiredModal,
        setShowSessionExpiredModal,
        // -------------Success And Error Modals---------------
        showSuccessModal,
        setShowSuccessModal,
        showErrorModal,
        setShowErrorModal,
        // ---------------- Response---------------
        successAndErrorResponse,
        setSuccessAndErrorResponse,
        // ---------------- Login/Logout--------------
        isLoggedIn,
        setIsLoggedIn,
        Logout,
    }
}
