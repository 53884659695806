import './button.scss'

const BUTTON_TYPE_CLASSES = {
    button2: 'button2',
    button3: 'button3',
}

function Button({ children, buttonType, className, ...otherProps }) {
    return (
        <button {...otherProps} className={`button-container ${className} ${BUTTON_TYPE_CLASSES[buttonType]}`}>
            {children}
        </button>
    )
}

export default Button
