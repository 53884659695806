import React from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

export function SessionExpiredModal({ showSessionExpiredModal, setShowSessionExpiredModal, setShowLoginModal }) {
    const handleClick = () => {
        setShowSessionExpiredModal(false)
        setShowLoginModal(true)
    }
    return (
        <Modal
            size="lg"
            className="errorModal"
            aria-labelledby="contained-modal-title-vcenter"
            show={showSessionExpiredModal}
            centered
            onHide={() => setShowSessionExpiredModal(false)}
        >
            <Modal.Header closeButton className="border-none" />
            <Modal.Body className="errorModal gap-8">
                <div className="px-12 text-[24px] leading-[29px] md:text-[40] md:leading-[48px] font-bold text-center">
                    로그인 세션이 만료되었습니다.
                </div>
                <div className="text-[14px] leading-[21px] md:text-[20] md:leading-[30px] font-normal text-center">
                    다시 로그인 해주세요.
                </div>
                <div className="w-full pb-4">
                    <Button variant="primary" className="bg-[#005BE2] w-full h-[50px] md:h-[60px]" onClick={handleClick}>
                        로그인
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    )
}
