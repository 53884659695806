import React from 'react'
import dna from '../../../assets/images/dna.svg'

export function KindofType({ handleButtonClick }) {
    const KindData = [
        {
            id: 1,
            image: dna,
            heading: `54개 조합된 타입을 결정하는 4가지 학습유전자란?`,
            description: `\u2022 지구력 = 체력 = 에너지 유전자 (ACE PCR)
      \u2022 집착=중독=표현 욕구(DRD2 PCR)
      \u2022 창의행동=호기심=몰입 유전 (DRD4 PCR)
      \u2022 감정 = 우울폭력 = 정서 유전자 (Serotonin Transporter PCR)
      
      성격 형성에 영향을 주는 인성 관련 유전자를 조기에 분석하여 적성과 진로에 도움이 되도록 하는데 활용`,
            card: [
                {
                    id: 1,
                    heading: `01 지구력 유전자`,
                    description: `끈기, 인내와 같은 지구력, 체력, 에너지 성향을 알아볼 수 있는 유전자`,
                    subCardColor: '#93CA49',
                    subCard: [
                        {
                            id: 1,
                            number: `II`,
                            desc: `지구력 강함, 학습 및 운동에 유리, 침착, 온순
              이기적, 독선적 기질 강함`,
                        },
                        {
                            id: 2,
                            number: `ID`,
                            desc: `영민한 머리, 의욕적, 적극적 사고, 명랑, 활발
              의리파, 기분파이며 마무리가 매우 약함`,
                        },
                        {
                            id: 3,
                            number: `DD`,
                            desc: `따뜻한 마음, 높은 감성지수, 타인에 대한 배려가 크며 효자/부자 유전자를 보유
              소극적, 내성적임`,
                        },
                    ],
                    button: `자세히 탐구하기`,
                    buttonLink: `지구력 유전자`,
                },
                {
                    id: 2,
                    heading: `02 집착 유전자`,
                    description: `흥분, 쾌감에 대한 집착, 중독, 표현욕구 성향을 알아볼 수 있는 유전자`,
                    subCardColor: '#FF6D18',
                    subCard: [
                        {
                            id: 1,
                            number: `A`,
                            desc: `선호, 전공 분야에 탁월한 실적, 위대한 발견, 발명 가능성 큼
              집착이 매우 강하고, 대인관계 폐쇄적`,
                        },
                        {
                            id: 2,
                            number: `B`,
                            desc: `다방면에 지적 호기심 많아 학습에 유리
              외모, 치장에 대한 관심이 많으며, 감정 표현이 적극적임`,
                        },
                        {
                            id: 3,
                            number: `C`,
                            desc: `합리적, 규범적, 원만한 대인 관계, 순종적, 우수한 학업 성적
              꼼꼼함, 완벽주의 성향, 낯가림 심함`,
                        },
                    ],
                    button: `자세히 탐구하기`,
                    buttonLink: `집착 유전자`,
                },
                {
                    id: 3,
                    heading: `03 창의행동 유전자`,
                    description: `내향, 외향에 대한 호기심, 몰입 성향을 알아볼 수 있는 유전자`,
                    subCardColor: '#7D3C9A',
                    subCard: [
                        {
                            id: 1,
                            number: `44`,
                            desc: `매사 무난하나 겁이 많은 타입
              새로운 것에 대한 도전이 어려움, 결단력이 약한 편임`,
                        },
                        {
                            id: 2,
                            number: `43`,
                            desc: `창의적, 창조적 능력이 매우 뛰어남
              정적인 면이 강하여 학습 시 공상과 망상의 세계에 빠져 지냄`,
                        },
                        {
                            id: 3,
                            number: `45`,
                            desc: `대단히 활동적, 스타성이 매우 강함
              충동적, 대범, 외향적인 성격이여서 안전장치 및 안전교육이 절대 필요`,
                        },
                    ],
                    button: `자세히 탐구하기`,
                    buttonLink: `창의행동 유전자`,
                },
                {
                    id: 4,
                    heading: `04 감정 유전자`,
                    description: `지기싫고, 고집, 자기주장과 같은 감정, 정서 성향을 알아볼 수 있는 유전자`,
                    subCardColor: '#D72269',
                    subCard: [
                        {
                            id: 1,
                            number: `SS`,
                            desc: `자기주장, 자기 철학, 라이벌 의식이 강함
              고집 세고 스트레스를 주변 사람에 게 해소함`,
                        },
                        {
                            id: 2,
                            number: `SL`,
                            desc: `마음이 여리고 순한 편이며 동료들과 협력적
              학습 의욕이 많이 떨어지고 경쟁심이 약함`,
                        },
                        {
                            id: 3,
                            number: ``,
                            desc: ``,
                        },
                    ],
                    button: `자세히 탐구하기`,
                    buttonLink: `감정 유전자`,
                },
            ],
        },
    ]
    return (
        <section className="fontFamily relative mb-24 mt-[8.25rem] px-[20px] md:my-32 lg:px-[9.3%]">
            {KindData.map((k) => (
                <>
                    <div
                        key={k.id}
                        className="no-scrollbar flex flex-col items-center gap-[1rem] overflow-x-scroll md:min-w-[900px] md:flex-row-reverse md:items-start md:justify-between md:gap-[4rem]"
                    >
                        {/* background pattern */}
                        <div className="absolute right-[0rem] top-[6%] -z-[1] h-[1060px] w-[450px] bg-[url('./assets/images/kindofGeneBg.svg')] bg-cover bg-no-repeat md:top-[5%] md:h-[880px] md:w-[1440px]" />

                        <img className="w-[45%] md:w-[45%] md:min-w-[300px] xl:mr-4" src={k.image} alt="dna" />
                        <div className="flex flex-col gap-[20px] md:gap-[40px]">
                            <div className="whitespace-pre-line text-left text-[20px] font-semibold leading-[24px] md:text-[30px] md:leading-[36px]">
                                {k.heading}
                            </div>
                            <div className="mb-[2rem] whitespace-pre-line text-left text-[16px] font-normal leading-[24px] md:mb-[5.25rem] md:text-[20px] md:leading-[24px]">
                                {k.description}
                            </div>
                        </div>
                    </div>
                    {/* card */}
                    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-x-4 md:gap-y-2">
                        {k.card.map((card) => (
                            <div key={card.id} className="my-2 rounded-[20px] bg-[#58749E] px-[10px] py-[20px]">
                                <div className=" text-white text-[18px] font-bold leading-[22px] md:text-[20px] md:leading-[24px]">
                                    {card.heading}
                                </div>
                                <div className="text-white whitespace-pre-line px-8 pb-3 pt-2 text-[14px] font-medium leading-[17px] md:text-[16px] md:leading-[19px]">
                                    {card.description}
                                </div>
                                <div className="bg-white h-[300px] overflow-visible rounded-[20px] px-[25px] pb-4 pt-[16px] sm:h-[320px] xl:h-[295px]">
                                    {card.subCard.map((sub) => (
                                        <div className="" key={sub.id}>
                                            <h1
                                                className="mb-[0.125rem] whitespace-pre-line text-left text-[20px] font-bold leading-[26px] md:text-[22px]"
                                                style={{ color: card.subCardColor }}
                                            >
                                                {sub.number}
                                            </h1>
                                            <p className="whitespace-pre-line text-left text-[14px] font-medium leading-[16px] md:text-[16px] md:leading-[19px]">
                                                {sub.desc}
                                            </p>
                                        </div>
                                    ))}
                                </div>
                                <button
                                    className="border-white text-white -mb-2 mt-[16px] rounded-[100px] border-2 border-solid px-8 py-[13px] text-[16px] font-semibold leading-[19px] md:py-[16px]"
                                    onClick={() => {
                                        handleButtonClick(card.buttonLink)
                                        window.scrollTo(0, 0)
                                    }}
                                >
                                    {card.button}
                                </button>
                            </div>
                        ))}
                    </div>
                </>
            ))}
        </section>
    )
}
