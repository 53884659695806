function SearchRecommendations({ handleOnclick, word }) {
    const recommendationsList = ['창의력', '지구력', '활동적', '배려심', '경쟁', '목표', '영민', '침착', '대인관계']

    return (
        <div className="text-left">
            <h5 className="   text-[#272727]">추천 검색어</h5>
            <div className="mr-auto mt-2 flex max-w-[350px]  flex-wrap gap-3 md:max-w-full">
                {recommendationsList.map((searchItem) => (
                    <div
                        className={`min-w-[96px] cursor-pointer rounded-[50px] border-[0.8px] border-[#000] px-3 py-1 text-center text-[#000] hover:border-[#005BE2] hover:bg-[#005BE2] hover:text-[#fff] ${
                            word === searchItem && 'border-[#005BE2] bg-[#005BE2] text-[#fff]'
                        }`}
                        key={searchItem}
                        onClick={() => handleOnclick(searchItem)}
                    >
                        {searchItem}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default SearchRecommendations
