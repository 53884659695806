/* eslint-disable camelcase */
import React from 'react'
import DNAtype_SS from '../../../assets/images/DNAtye-SS.svg'
import DNAtype_SL from '../../../assets/images/DNAtye-SL.svg'
import emotionalGene from '../../../assets/images/emotion.svg'

export function EmotionalGene() {
    const EmotionalData = [
        {
            id: 1,
            heading: `감정 유전자
      (승부욕, 고집, 자기주장과 같은 감정, 정서 성향을 알아볼 수 있는 유전자)
      ST (Serotonin Transporter) PCR`,
            description: `혈압상승물질(angiontensin)을 변환시켜주는 역할을 하는 유전자로, 혈압 조절에 관여하여 심폐지구력과 근력에 영향을 주는 유전자이며,
      선천적인 체력에 영향을 주고 이는 외향적인 성격, 학업수행능력 등의 성격형성에도 영향을 줍니다.`,
            card: [
                {
                    id: 1,
                    heading: `SS 타입`,
                    description: `자기 주장 · 자기 철학 및 라이벌 의식이 강함
          고집이 세고 스트레스를 주변 사람에게 해소함`,
                    image: DNAtype_SS,
                },
                {
                    id: 2,
                    heading: `SL 타입`,
                    description: `마음이 여리고 순한 편이며, 동료들과 협력적
          학습 의욕이 많이 떨어지고 경쟁심이 약함`,
                    image: DNAtype_SL,
                },
            ],
            image: emotionalGene,
            details: [
                {
                    id: 1,
                    heading: `감정 = 우울폭력 = 정서 유전자 (Serotonin Transporter PCR)의 학술적 원리 기능`,
                    description: `사람의 뇌 속에 존재하는 세로토닌은 슬픔을 느끼거나 충격적인 고통, 마음의 상처를 당했을 때 분비되는 호르몬이다.
          일반적인 경우 분비된 세로토닌은 잠시 시간이 흐르고 난 뒤 세로토닌 운반체와 결합하여 다시 흡수되므로 마음이 진정되고 안정을 찾게 되어있다.
          그러나 세로토닌 운반체와 제대로 결합을 하지 못하게 된다면 상대적으로 정상인보다 더 오랫동안 세로토닌이 뇌 안에 남아있게 되므로 마음의 상태가
          슬프거나 우울하고 혹은 그런 기분을 견디기 어렵도록 하기 때문에 주위의 동료나 타인들과 마찰이나 시비가 일어나게 된다.`,
                },
                {
                    id: 2,
                    heading: `감정 유전자 검사에 대한 소개`,
                    description: `사회적 문제로까지 대두되고 있는 왕따나 학교 폭력 등에 대한 답으로 제시되고 있는 것이 세로토닌(감정적 성향과 관계가 깊은) 운반체 유전자 입니다.
          이 검사는 학습과 관련하여 얼마나 자기 주장이 강하고 라이벌 의식이 있는가를 알아보는 것입니다.
          SS, SL, LL 의 세가지 타입이 있으며, 이중에서 SS 타입이 위의 특성을 매우 강하게 보유한 성향입니다.
          그리고 LL 타입은 SL 타입과 크게 다른 점이 없어서 SL 타입으로 통합하게 됩니다.`,
                },
            ],
            points: [
                {
                    id: 1,
                    heading: `왜 세로토닌 운반체 유전자의 변이가 있으면 감정적 성향이 생기는가?`,
                    description: [
                        {
                            id: 1,
                            li: `사람의 뇌 속에는 슬픔과 안정에 관계하는 세로토닌이라는 호르몬이 있음`,
                        },
                        {
                            id: 2,
                            li: `세로토닌은 충격적인 고통, 마음의 상처로 인해 슬픔을 느낄 때 많이 분비됨`,
                        },
                        {
                            id: 3,
                            li: `그러나 곧 마음의 안정을 되찾는 것은, 세로토닌과 결합해서 뇌 속의 세로토닌 양을 다시 적정량으로 유지시켜주는 세로토닌 운반체가 활동하기 때문임`,
                        },
                        {
                            id: 4,
                            li: `특히 이 세로토닌 운반체에 변이가 생긴 사람들은 뇌 속의 세로토닌 양이 과다한 상태로 남게 되는 경우가 많음`,
                        },
                        {
                            id: 5,
                            li: `뇌 속의 세로토닌 양이 과다한 상태로 유지되면 슬픈 일이 있을 대 우울한 증세가 오래감`,
                        },
                        {
                            id: 6,
                            li: `또한 그와 더불어 돌발적인 폭력성을 발산하게 되기도 하며, 소극적이고 내성적인 경우 자살의 충동을 느끼기도 함`,
                        },
                        {
                            id: 7,
                            li: `한 연구 결과에 의하면, 우울증 환자의 부모 또는 자식이 우울증을 가진 경우가 일반인보다 월등히 높다고 함`,
                        },
                    ],
                },
                {
                    id: 2,
                    heading: `이 유전자의 변이가 있으면 어떻게 대처해야 할까?`,
                    description: [
                        {
                            id: 1,
                            li: `유전적인 영향이 강하기도 하지만 환경적인 영향도 무시할 수 없음`,
                        },
                        {
                            id: 2,
                            li: `세로토닌 운반체에 변이가 있는 사람들은 어릴 때부터 감정에 대한 적절한 관리와 교육이 필요함`,
                        },
                        {
                            id: 3,
                            li: `아이들의 경우 또래의 친구들과 잘 어울릴 수 있도록 인도해야 하며, 팀워크를 통한 놀이나 연수가 필요함`,
                        },
                        {
                            id: 4,
                            li: `태권도나 권투 등 격렬한 운동을 통해서 자신의 내면에 존재하는 불안감, 슬픔을 잊게 함과 동시에 마음을 순화하는 계기를 만들어 주는 것이 중요함`,
                        },
                        {
                            id: 5,
                            li: `SS 타입은 학창시절 지기 싫어하는 기질 때문에 학습에 매우 유리하나 사회에 진출해서 주변 사람들과 잘 융합하지 못하고 불편한 관계가 될 가능성이 있는 반면, SL 타입은 그 반대되는 기질을 가지고 있음`,
                        },
                    ],
                },
            ],
        },
    ]
    return (
        <section className="relative mt-12 mb-24 md:my-32 px-[20px] lg:px-[9.3%]">
            {EmotionalData.map((k) => (
                <>
                    <div key={k.id} className="flex flex-col gap-[1rem] md:flex-row-reverse md:justify-between md:gap-[4rem]">
                        {/* background pattern */}
                        <div className="md:hidden top-[42%] left-[-1rem] bg-cover bg-no-repeat bg-[url('./assets/images/endurenceGeneMobileBg.svg')] absolute h-[760px] w-[375px] -z-[1]" />
                        <div className="hidden md:block right-[0%] top-[57%] md:right-[0%] md:top-[70%] bg-cover bg-no-repeat bg-[url('./assets/images/emotionalGeneBg.svg')] absolute h-[734px] w-[361px] md:h-[827px] md:w-[1197px] -z-[1]" />
                        <div className="mx-auto md:ml-[0px] md:mx-0 w-1/2 md:w-1/5" />
                        <div className="flex flex-col gap-[16px] md:gap-[40px]">
                            <div className="whitespace-pre-line text-left text-[20px] leading-[24px] font-semibold md:text-[30px] md:leading-[36px]">
                                {k.heading}
                            </div>
                            <div className="mb-[2rem] md:mb-[4rem] whitespace-pre-line text-left text-[16px] leading-[24px] font-normal ">
                                {k.description}
                            </div>
                        </div>
                    </div>
                    {/* card */}
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
                        {k.card.map((card) => (
                            <div key={card.id} className="bg-white rounded-[8px] shadow-[3px_4px_80px_rgba(194,203,214,0.2)] xl:h-[450px]">
                                <div className="rounded-t-[8px] bg-[#D72269] h-[50px] md:h-[69px] text-white flex items-center justify-center text-[20px] leading-[24px] md:text-[24px] md:leading-[29px] font-semibold">
                                    {card.heading}
                                </div>
                                <div className="text-left py-3 whitespace-pre-line text-[16px] leading-[19px] md:text-[16px] md:leading-[24px] font-normal px-6">
                                    {card.description}
                                </div>
                                <img src={card.image} alt={card.image} className="mt-8 mb-4 mx-auto" />
                            </div>
                        ))}
                    </div>
                    <img src={k.image} alt={k.image} className="w-100 mt-12 mb-[0] md:-mb-[2rem] md:my-24 mx-auto rounded-[10px]" />
                    {/* details */}
                    <div className="">
                        {k.details.map((detail) => (
                            <div key={detail.id} className="flex flex-col gap-[16px] md:gap-[20px]">
                                <div className="mt-[2rem] md:mt-[6rem] text-left whitespace-pre-line text-[20px] leading-[24px] md:text-[24px] md:leading-[29px] font-semibold">
                                    {detail.heading}
                                </div>
                                <div className="text-left whitespace-pre-line text-[16px] leading-[24px] font-normal">
                                    {detail.description}
                                </div>
                            </div>
                        ))}
                    </div>
                    {/* points */}
                    <div className="">
                        {k.points.map((points) => (
                            <div key={points.id} className="flex flex-col gap-[16px] md:gap-[20px]">
                                <div className="mt-[2rem] md:mt-[6rem] text-left whitespace-pre-line text-[20px] leading-[24px] md:text-[24px] md:leading-[29px] font-semibold">
                                    {points.heading}
                                </div>
                                <ol className="list-disc text-left whitespace-pre-line text-[16px] leading-[24px] font-normal">
                                    {points.description.map((list) => (
                                        <li key={list.li}>{list.li}</li>
                                    ))}
                                </ol>
                            </div>
                        ))}
                    </div>
                </>
            ))}
        </section>
    )
}
