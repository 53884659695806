/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import { Helmet } from 'react-helmet'
import { LearningGeneDropdown } from '../../components/LearningGeneDropdown/LearningGeneDropdown'
import header_bg from '../../assets/images/history-header.svg'
import header_bg_mobile from '../../assets/images/history-header-mobile.svg'
import { KindofType } from './KindofType/KindofType'
import { EnduranceGene } from './EnduranceGene/EnduranceGene'
import { ObsessionGene } from './ObsessionGene/ObsessionGene'
import { CreativityGene } from './CreativityGene/CreativityGene'
import { EmotionalGene } from './EmotionalGene/EmotionalGene'

function DNAtype() {
    const isBigScreen = useMediaQuery({ query: '(min-width: 768px)' })
    const navigate = useNavigate()
    const location = useLocation()
    // render different sections
    const [section, setSection] = useState('타입의 종류')
    // different sections on different tabs
    const handleButtonClick = (sectionName) => {
        setSection(sectionName)
    }

    const [clicked, setClicked] = useState(false)

    const toggle = () => {
        setClicked(!clicked)
    }

    const close = () => {
        setClicked(false)
    }

    // Move focus from one header to body
    useEffect(() => {
        if (location.pathname === '/54DNAtype' && section !== '타입의 종류') {
            window.scroll(0, isBigScreen ? 500 : 200)
            navigate('/54DNAtype', { replace: true })
        }
    }, [section])

    // Back to default section if user press Browser Back button in same Page
    useEffect(() => {
        const handleBackButton = () => {
            if (location.pathname === '/54DNAtype' && section !== '타입의 종류') {
                setSection('타입의 종류')
                navigate('/54DNAtype', { replace: true })
            }
        }
        window.onpopstate = () => setTimeout(handleBackButton, 0)

        return () => {
            window.removeEventListener('popstate', handleBackButton)
        }
    })

    // DropDown array
    const SectionData = [
        {
            id: 1,
            title: `타입의 종류`,
        },
        {
            id: 2,
            title: `지구력 유전자`,
        },
        {
            id: 3,
            title: `집착 유전자`,
        },
        {
            id: 4,
            title: `창의행동 유전자`,
        },
        {
            id: 5,
            title: `감정 유전자`,
        },
    ]

    useEffect(() => {
        // navigate(location.pathname, {});
        if (location?.state?.searchWord) {
            const searchedSection = SectionData.find((item) => JSON.stringify(item).includes(location?.state?.searchWord))
            setSection(searchedSection?.title)
        } else if (location?.state?.section) {
            setSection(location?.state?.section)
        }
    }, [location?.state?.searchWord])

    return (
        <div className="">
            <Helmet>
                <title>54DNAtype</title>
            </Helmet>
            <div className="relative mb-6" onClick={() => close()}>
                <img src={header_bg_mobile} alt="bg" className="w-full md:hidden" />
                <img src={header_bg} alt="bg" className="hidden w-full md:block" />
                <p className="text-white absolute left-1/2 top-1/2 m-0 w-full -translate-x-1/2 -translate-y-1/2 transform text-[24px] font-bold leading-[36px] md:text-[42px] md:leading-[58px]">
                    54DNAtype
                </p>
            </div>
            <LearningGeneDropdown
                SectionData={SectionData}
                section={section}
                handleButtonClick={handleButtonClick}
                toggle={toggle}
                clicked={clicked}
                close={close}
            />
            <div onClick={() => close()}>
                {section === '타입의 종류' && <KindofType handleButtonClick={handleButtonClick} />}
                {section === '지구력 유전자' && <EnduranceGene />}
                {section === '집착 유전자' && <ObsessionGene />}
                {section === '창의행동 유전자' && <CreativityGene />}
                {section === '감정 유전자' && <EmotionalGene />}
            </div>
        </div>
    )
}

export default DNAtype
