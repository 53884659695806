const ReportTypes = [
    {
        id: 1,
        content: '아에그룹(II-A)',
        contentDescription:
            '지구력이 강하며 성격도 차분하고 온순한 스타일이기 때문에 학습과 대인관계에서 유리하고 신뢰감을 줍니다. 또한 자기가 좋아하는 한 두가지 분야에만 푹 빠져서 헤어나기 힘듭니다. 평균은 약하나 특정 분야가 강해서 위대한 발견이나 발명을 할 가능성이 높습니다.',
        button: [
            {
                id: 1,
                button_heading: '01 아에사스',
                button_content: '(II-A-44-SS)',
                backgroundColor: '#D00019',
            },
            {
                id: 2,
                button_heading: '02 아에사엘',
                button_content: '(II-A-44-SL)',
                backgroundColor: '#D00019',
            },
            {
                id: 3,
                button_heading: '03 아에삼스',
                button_content: '(II-A-43-SS)',
                backgroundColor: '#D00019',
            },
            {
                id: 4,
                button_heading: '04 아에삼엘',
                button_content: '(II-A-43-SL) ',
                backgroundColor: '#D00019',
            },
            {
                id: 5,
                button_heading: '05 아에오스',
                button_content: '(II-A-45-SS)',
                backgroundColor: '#D00019',
            },
            {
                id: 6,
                button_heading: '06 아에오엘',
                button_content: '(II-A-45-SL)',
                backgroundColor: '#D00019',
            },
        ],
    },
    {
        id: 2,
        content: '알에그룹(ID-A)',
        contentDescription:
            '지구력이 보통이며 영민하고 활발, 명랑한 스타일이 많아 의리를 소중히 여기고 친구 관계가 좋습니다. 또한 자기가 좋아하는 한 두가지 분야에만 푹 빠져서 헤어나기 힘듭니다. 평균은 약하나 특정 분야가 강해서 위대한 발견이나 발명을 할 가능성이 높습니다.',
        button: [
            {
                id: 7,
                button_heading: '07 알에사스',
                button_content: '(ID-A-44-SS)',
                backgroundColor: '#F49300',
            },
            {
                id: 8,
                button_heading: '08 알에사엘',
                button_content: '(ID-A-44-SL)',
                backgroundColor: '#F49300',
            },
            {
                id: 9,
                button_heading: '09 알에삼스',
                button_content: '(ID-A-43-SS)',
                backgroundColor: '#F49300',
            },
            {
                id: 10,
                button_heading: '10 알에삼엘',
                button_content: '(ID-A-43-SL)',
                backgroundColor: '#F49300',
            },
            {
                id: 11,
                button_heading: '11 알에오스',
                button_content: '(ID-A-45-SS)',
                backgroundColor: '#F49300',
            },
            {
                id: 12,
                button_heading: '12 알에오엘',
                button_content: '(ID-A-45-SL)',
                backgroundColor: '#F49300',
            },
        ],
    },
    {
        id: 3,
        content: '디에그룹(DD-A)',
        contentDescription:
            '지구력이 약하며 소극적, 내성적인 스타일이지만 감성적이고 예술적 재능이 많으며 심성이 착한 성격 입니다. 또한 자기가 좋아하는 한 두가지 분야에만 푹 빠져서 헤어나기 힘듭니다. 평균은 약하나 특정 분야가 강해서 위대한 발견이나 발명을 할 가능성이 높습니다.',
        button: [
            {
                id: 13,
                button_heading: '13 디에사스',
                button_content: '(DD-A-44-SS)',
                backgroundColor: '#FBE41A',
            },
            {
                id: 14,
                button_heading: '14 디에사엘',
                button_content: '(DD-A-44-SL)',
                backgroundColor: '#FBE41A',
            },
            {
                id: 15,
                button_heading: '15 디에삼스',
                button_content: '(DD-A-43-SS)',
                backgroundColor: '#FBE41A',
            },
            {
                id: 16,
                button_heading: '16 디에삼엘',
                button_content: '(DD-A-43-SL)',
                backgroundColor: '#FBE41A',
            },
            {
                id: 17,
                button_heading: '17 디에오스',
                button_content: '(DD-A-45-SS)',
                backgroundColor: '#FBE41A',
            },
            {
                id: 18,
                button_heading: '18 디에오엘',
                button_content: '(DD-A-45-SL)',
                backgroundColor: '#FBE41A',
            },
        ],
    },
    {
        id: 4,
        content: '아비그룹(II-B)',
        contentDescription:
            '지구력이 강하며 성격도 차분하고 온순한 스타일이기 때문에 학습과 대인관계에서 유리하고 신뢰감을 줍니다. 또한 새로운 것과 멋에 관심이 많고 의욕이 강해 질문도 많습니다. 다혈질적이지만 인간미가 있고 다방면 지식이 많아서 사람들과 함께 이야기를 즐깁니다.',
        button: [
            {
                id: 19,
                button_heading: '19 아비사스',
                button_content: '(II-B-44-SS)',
                backgroundColor: '#76D200',
            },
            {
                id: 20,
                button_heading: '20 아비사엘',
                button_content: '(II-B-44-SL)',
                backgroundColor: '#76D200',
            },
            {
                id: 21,
                button_heading: '21 아비삼스',
                button_content: '(II-B-43-SS)',
                backgroundColor: '#76D200',
            },
            {
                id: 22,
                button_heading: '22 아비삼엘',
                button_content: '(II-B-43-SL)',
                backgroundColor: '#76D200',
            },
            {
                id: 23,
                button_heading: '23 아비오스',
                button_content: '(II-B-45-SS)',
                backgroundColor: '#76D200',
            },
            {
                id: 24,
                button_heading: '24 아비오엘',
                button_content: '(II-B-45-SL)',
                backgroundColor: '#76D200',
            },
        ],
    },
    {
        id: 5,
        content: '알비그룹(ID-B)',
        contentDescription:
            '지구력이 보통이며 영민하고 활발, 명랑한 스타일이 많아 의리를 소중히 여기고 친구 관계가 좋습니다. 또한 새로운 것과 멋에 관심이 많고 의욕이 강해 질문도 많습니다. 다혈질적이지만 인간미가 있고 다방면 지식이 많아서 사람들과 함께 이야기를 즐깁니다.',
        button: [
            {
                id: 25,
                button_heading: '25 알비사스',
                button_content: '(ID-B-44-SS)',
                backgroundColor: '#008EDE',
            },
            {
                id: 26,
                button_heading: '26 알비사엘',
                button_content: '(ID-B-44-SL)',
                backgroundColor: '#008EDE',
            },
            {
                id: 27,
                button_heading: '27 알비삼스',
                button_content: '(ID-B-43-SS)',
                backgroundColor: '#008EDE',
            },
            {
                id: 28,
                button_heading: '28 알비삼엘',
                button_content: '(ID-B-43-SL)',
                backgroundColor: '#008EDE',
            },
            {
                id: 29,
                button_heading: '29 알비오스',
                button_content: '(ID-B-45-SS)',
                backgroundColor: '#008EDE',
            },
            {
                id: 30,
                button_heading: '30 알비오엘',
                button_content: '(ID-B-45-SL)',
                backgroundColor: '#008EDE',
            },
        ],
    },
    {
        id: 6,
        content: '알비그룹(DD-B)',
        contentDescription:
            '지구력이 약하며 소극적, 내성적인 스타일이지만 감성적이고 예술적 재능이 많으며 심성이 착한 성격 입니다. 또한 새로운 것과 멋에 관심이 많고 의욕이 강해 질문도 많습니다. 다혈질적이지만 인간미가 있고 다방면 지식이 많아서 사람들과 함께 이야기를 즐깁니다.',
        button: [
            {
                id: 31,
                button_heading: '31 디비사스',
                button_content: '(DD-B-44-SS)',
                backgroundColor: '#002FA8',
            },
            {
                id: 32,
                button_heading: '32 디비사엘',
                button_content: '(DD-B-44-SL)',
                backgroundColor: '#002FA8',
            },
            {
                id: 33,
                button_heading: '33 디비삼스',
                button_content: '(DD-B-43-SS)',
                backgroundColor: '#002FA8',
            },
            {
                id: 34,
                button_heading: '34 디비삼엘',
                button_content: '(DD-B-43-SL)',
                backgroundColor: '#002FA8',
            },
            {
                id: 35,
                button_heading: '35 디비오스',
                button_content: '(DD-B-45-SS)',
                backgroundColor: '#002FA8',
            },
            {
                id: 36,
                button_heading: '36 디비오엘',
                button_content: '(DD-B-45-SL)',
                backgroundColor: '#002FA8',
            },
        ],
    },
    {
        id: 7,
        content: '아시그룹(II-C)',
        contentDescription:
            '지구력이 강하며 성격도 차분하고 온순한 스타일이기 때문에 학습과 대인관계에서 유리하고 신뢰감을 줍니다. 또한 자기 절제 및 조절 능력이 뛰어나고 사람관계에서 순종적, 규범적 입니다. 합리적인 성품으로 스스로 계획을 잘 세워서 이루어내는 능력을 가졌습니다.',
        button: [
            {
                id: 37,
                button_heading: '37 아시사스',
                button_content: '(II-C-44-SS)',
                backgroundColor: '#9A00D0',
            },
            {
                id: 38,
                button_heading: '38 아시사엘',
                button_content: '(II-C-44-SL)',
                backgroundColor: '#9A00D0',
            },
            {
                id: 39,
                button_heading: '39 아시삼스',
                button_content: '(II-C-43-SS)',
                backgroundColor: '#9A00D0',
            },
            {
                id: 40,
                button_heading: '40 아시삼엘',
                button_content: '(II-C-43-SL)',
                backgroundColor: '#9A00D0',
            },
            {
                id: 41,
                button_heading: '41 아시오스',
                button_content: '(II-C-45-SS)',
                backgroundColor: '#9A00D0',
            },
            {
                id: 42,
                button_heading: '42 아시오엘',
                button_content: '(II-C-45-SL)',
                backgroundColor: '#9A00D0',
            },
        ],
    },
    {
        id: 8,
        content: '알시그룹(ID-C)',
        contentDescription:
            '지구력이 보통이며 영민하고 활발, 명랑한 스타일이 많아 의리를 소중히 여기고 친구 관계가 좋습니다. 또한 자기 절제 및 조절 능력이 뛰어나고 사람관계에서 순종적, 규범적 입니다. 합리적인 성품으로 스스로 계획을 잘 세워서 이루어내는 능력을 가졌습니다.',
        button: [
            {
                id: 43,
                button_heading: '43 알시사스',
                button_content: '(ID-C-44-SS)',
                backgroundColor: '#00D0C4',
            },
            {
                id: 44,
                button_heading: '44 알시사엘',
                button_content: '(ID-C-44-SL)',
                backgroundColor: '#00D0C4',
            },
            {
                id: 45,
                button_heading: '45 알시삼스',
                button_content: '(ID-C-43-SS)',
                backgroundColor: '#00D0C4',
            },
            {
                id: 46,
                button_heading: '46 알시삼엘',
                button_content: '(ID-C-43-SL)',
                backgroundColor: '#00D0C4',
            },
            {
                id: 47,
                button_heading: '47 알시오스',
                button_content: '(ID-C-45-SS)',
                backgroundColor: '#00D0C4',
            },
            {
                id: 48,
                button_heading: '48 알시오엘',
                button_content: '(ID-C-45-SL)',
                backgroundColor: '#00D0C4',
            },
        ],
    },
    {
        id: 9,
        content: '디시그룹(DD-C)',
        contentDescription:
            '지구력이 약하며 소극적, 내성적인 스타일이지만 감성적이고 예술적 재능이 많으며 심성이 착한 성격 입니다. 또한 자기 절제 및 조절 능력이 뛰어나고 사람관계에서 순종적, 규범적 입니다. 합리적인 성품으로 스스로 계획을 잘 세워서 이루어내는 능력을 가졌습니다.',
        button: [
            {
                id: 49,
                button_heading: '49 디시사스',
                button_content: '(DD-C-44-SS)',
                backgroundColor: '#D00089',
            },
            {
                id: 50,
                button_heading: '50 디시사엘',
                button_content: '(DD-C-44-SL)',
                backgroundColor: '#D00089',
            },
            {
                id: 51,
                button_heading: '51 디시삼스',
                button_content: '(DD-C-43-SS)',
                backgroundColor: '#D00089',
            },
            {
                id: 52,
                button_heading: '52 디시삼엘',
                button_content: '(DD-C-43-SL)',
                backgroundColor: '#D00089',
            },
            {
                id: 53,
                button_heading: '53 디시오스',
                button_content: '(DD-C-45-SS)',
                backgroundColor: '#D00089',
            },
            {
                id: 54,
                button_heading: '54 디시오엘',
                button_content: '(DD-C-45-SL)',
                backgroundColor: '#D00089',
            },
        ],
    },
]

export default ReportTypes
