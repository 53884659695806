/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from 'react'
import { Modal, Row, Col } from 'react-bootstrap'
import './SearchPopup.scss'
import '../../Search/SearchBox.scss'
import { useMediaQuery } from 'react-responsive'
import { useNavigate } from 'react-router-dom'
import xMarkIcon from '../../../assets/icons/xMarkIcon.svg'
import SearchRecommendations from '../../Common/SearchRecommendations'

export function SearchPopup({ setShowSearchPopup }) {
    const isBigScreen = useMediaQuery({ query: '(min-width: 768px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' })
    const param = window.location.pathname
    const navigate = useNavigate()
    // store input value from "search event"
    const [searchQuery, setSearchQuery] = useState('')
    const onChangeHandler = (event) => {
        // Get input value from "search event"
        setSearchQuery(event.target.value)
    }

    // Function calls whens submit event
    const handleSubmit = (event) => {
        event.preventDefault()
        if (searchQuery !== '') {
            if (param === '/search') {
                localStorage.setItem('search', JSON.stringify(searchQuery))
                return window.location.reload()
            }
            navigate('/search', { state: { searchQuery } })
            setShowSearchPopup(false)
        }
    }
    // Recent Searches
    const [recentSearches, setRecentSearches] = useState([])
    useEffect(() => {
        const recentSearches = JSON.parse(localStorage.getItem('recentSearches')) || []
        setRecentSearches(recentSearches)
    }, [])

    const removeSearch = (searchToRemove) => {
        const updatedSearches = recentSearches.filter((search) => search !== searchToRemove)
        localStorage.setItem('recentSearches', JSON.stringify(updatedSearches))
        setRecentSearches(updatedSearches)
    }

    const handleOnclick = (item) => {
        setSearchQuery(item)
        if (item) {
            if (param === '/search') {
                localStorage.setItem('search', JSON.stringify(item))
                return window.location.reload()
            }
            navigate('/search', { state: { searchQuery: item } })
            setShowSearchPopup(false)
        }
    }

    return (
        <>
            {isBigScreen && (
                <div className="search_popup_wrapper">
                    <div className="search_popup">
                        <form className="search_box" onSubmit={handleSubmit}>
                            <input
                                id="searchIcon"
                                type="search"
                                className="search-box"
                                placeholder="검색어를 입력해주세요."
                                onChange={onChangeHandler}
                                required
                                value={searchQuery}
                            />
                        </form>
                        <div className="icon cursor-pointer">
                            <img src={xMarkIcon} alt="xMark" onClick={() => setShowSearchPopup(false)} />
                        </div>
                    </div>
                    <div className="mr-auto mt-3">
                        <SearchRecommendations handleOnclick={handleOnclick} word={searchQuery} />
                    </div>
                    {recentSearches.length ? (
                        <div>
                            <h5 className="recentsearch-heading mt-4 text-left">최근 검색어</h5>
                            <div className="ml-4 mt-2 flex flex-col items-start gap-2">
                                {recentSearches?.map((search) => (
                                    <div className="flex w-full justify-between" key={search}>
                                        <div key={search} className="recentsearch cursor-pointer" onClick={() => handleOnclick(search)}>
                                            {search}
                                        </div>
                                        <img
                                            className="ml-20 h-[12px] cursor-pointer"
                                            src={xMarkIcon}
                                            alt="X"
                                            onClick={() => removeSearch(search)}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : null}
                </div>
            )}
            {/* Mobile View */}
            {isTabletOrMobile && (
                <Modal
                    className="search_popup_wrapper_mobile"
                    size="lg"
                    centered
                    aria-labelledby="contained-modal-title-vcenter"
                    show={() => setShowSearchPopup(true)}
                    onHide={() => setShowSearchPopup(false)}
                >
                    {/* {loading && <Spinner />} */}
                    <Modal.Header>
                        <button
                            type="button"
                            className="btn-close modal-close-btn"
                            data-bs-dismiss="modal"
                            onClick={() => setShowSearchPopup(false)}
                            aria-label="Close"
                        />
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col className="col-12 search-text d-flex justify-content-center">검색</Col>
                            {/* ---------------Form Starts Here--------------- */}
                            <form className="form" onSubmit={handleSubmit}>
                                <Col className="col-12 mt-[1rem]">
                                    <input
                                        id="searchIcon"
                                        type="search"
                                        className="search-box"
                                        placeholder="검색어를 입력해주세요."
                                        onChange={onChangeHandler}
                                        value={searchQuery}
                                        required
                                    />
                                </Col>
                                <Col className="col-12 d-flex justify-content-center mt-[1rem]">
                                    <button
                                        // disabled={disableButton}
                                        type="submit"
                                        className="text-white btn h-[50px] w-[100%] bg-[#005BE2] text-[16px] font-semibold leading-[19px]"
                                    >
                                        검색
                                    </button>
                                </Col>
                            </form>
                            <div className="mr-auto mt-3">
                                <SearchRecommendations handleOnclick={setSearchQuery} word={searchQuery} />
                            </div>
                            {/* ---------------Form Ends--------------- */}
                            <Col className="col-12 search-text mt-8 flex max-h-[200px] flex-col overflow-auto">
                                <h1 className="recentsearch-heading">최근 검색어</h1>
                                <div className=" flex flex-col items-start gap-2 ">
                                    {recentSearches?.map((search) => (
                                        <div className="flex w-full justify-between">
                                            <div key={search} className="recentsearch cursor-pointer" onClick={() => handleOnclick(search)}>
                                                {search}
                                            </div>
                                            <img className="h-[12px]" src={xMarkIcon} alt="X" onClick={() => removeSearch(search)} />
                                        </div>
                                    ))}
                                </div>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
            )}
        </>
    )
}
