/* eslint-disable camelcase */
import Axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { API_BASE_URL } from '../common/constants'

const axios = Axios.create({
    baseURL: API_BASE_URL,
    timeout: 8000,
    headers: { 'Content-Type': 'application/json' },
})

axios.interceptors.request.use((req) => {
    // implement Refresh token
    const token = localStorage.getItem('token')
    const refreshToken = localStorage.getItem('refreshToken')
    if (token) req.headers.Authorization = `Bearer ${token}`
    if (refreshToken) req.headers['x-refresh-token'] = refreshToken
    return req
})

// Make AxiosInterceptor function to use hooks inside
const AxiosInterceptor = ({ children }) => {
    const navigate = useNavigate()
    useEffect(() => {
        const resInterceptor = (response) => {
            const regenerated_token = response.headers.get('x-amzn-remapped-authorization')
            const regenerated_refresh_token = response.headers.get('x-refresh-token')
            if (regenerated_token) {
                const token = regenerated_token.split(' ')
                localStorage.setItem('token', token[1])
            } else if (regenerated_refresh_token) {
                localStorage.setItem('refreshToken', regenerated_refresh_token)
            }
            return response
        }

        const errInterceptor = (error) => {
            console.log('error', error)
            if (error.response.status === 500) {
                navigate('/500')
            } else if (error.response.status === 401 && error.response.data.message === 'Not authenticated') {
                localStorage.clear()
                navigate('/', { state: { error: true } })
            } else if (error.response.status === 404) {
                navigate('/404')
            }
            return Promise.reject(error)
        }

        const interceptor = axios.interceptors.response.use(resInterceptor, errInterceptor)

        // clean the side effect
        return () => axios.interceptors.response.eject(interceptor)
    }, [navigate])

    return children
}

export default axios
export { AxiosInterceptor }
