/* eslint-disable react/jsx-no-constructed-context-values */
import React, { useState, createContext, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from '../axios'

const UserContext = createContext({
    showSessionExpiredModal: false,
    setShowSessionExpiredModal: () => {},
    getPhoneAuthToken: () => {},
    emailAuthenticationByPhone: () => {},
    getPhoneData: () => {},
    updatePhoneNumberByAuthentication: () => {},
    userData: null,
    setUser: () => {},
    getUser: () => {},
    setLoading: () => {},
    loading: false,
})

// Hook to provide access to context object
export const UseUser = () => {
    return useContext(UserContext)
}
// Main context function
export function UserProvider(props) {
    const navigate = useNavigate()

    const getPhoneAuthToken = () => {
        return axios.get('/user/getPhoneVerifyToken').then(({ data }) => {
            return data
        })
    }

    const updatePhoneNumberByAuthentication = (encodeData) => {
        const query = encodeURIComponent(encodeData)
        return axios.get(`/user/updatePhoneNumberByAuthentication?EncodeData=${query}`).then(({ data }) => {
            return data
        })
    }
    const getPhoneData = (encodeData) => {
        const query = encodeURIComponent(encodeData)
        return axios.get(`/user/getPhoneData?EncodeData=${query}`).then(({ data }) => {
            return data
        })
    }
    const emailAuthenticationByPhone = (encodeData) => {
        const query = encodeURIComponent(encodeData)
        return axios.get(`/user/emailAuthenticationByPhone?EncodeData=${query}`).then(({ data }) => {
            return data
        })
    }
    const getUser = () => {
        const token = localStorage.getItem('token')
        if (token) {
            setLoading(true)
            return axios
                .get('/user/getUser')
                .then(({ data }) => {
                    setUserData(data)
                    setLoading(false)
                })
                .catch((error) => {
                    console.log('An Error occured while getting User:', error)
                    if (error.response.status === 401 && error.response.data.message === 'Not authenticated') {
                        localStorage.clear()
                        setUserData(null)
                        navigate('/', { state: { error: true } })
                    } else if (error.response.status === 500) {
                        navigate('/500')
                    }
                    setLoading(false)
                })
        }
    }
    const [userData, setUserData] = useState(null)
    const [loading, setLoading] = useState(false)
    const contextValue = {
        userData,
        setUserData,
        getUser,
        getPhoneAuthToken,
        getPhoneData,
        updatePhoneNumberByAuthentication,
        emailAuthenticationByPhone,
        loading,
    }
    useEffect(() => {
        // fetch user data from database
        getUser()
    }, [])

    return <UserContext.Provider value={contextValue}>{props.children}</UserContext.Provider>
}
