import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
// import Button from '../../Button/button';
import alertCircleOutline from '../../../assets/icons/alertCircleOutline.svg'

export function RetakeTestModal({ showRetakeTestModal, setShowRetakeTestModal, handleRetakeTest }) {
    const handleConfirm = () => {
        setShowRetakeTestModal(false)
        handleRetakeTest()
    }
    return (
        <Modal
            centered
            size="lg"
            className="errorModal"
            aria-labelledby="contained-modal-title-vcenter"
            show={showRetakeTestModal}
            onHide={() => setShowRetakeTestModal(false)}
        >
            <Modal.Header closeButton className="border-none" />
            <Modal.Body className="errorModal px-[21px] lg:px-[55px]">
                <div>
                    <img src={alertCircleOutline} alt="alertCircleOutline" className="h-[80px] md:h-[100px]" />
                </div>
                <div className="text-[15px] leading-[24px] font-normal md:text-[20px] md:leading-[30px] text-center max-md:mt-3">
                    다시 검사하면 이전 검사 결과는 삭제됩니다.
                    <br /> 계속 하시겠습니까?
                </div>
                <div className="flex items-center justify-center w-full gap-2 pb-12 md:mt-[10px]">
                    <Button
                        variant="outline-primary"
                        className="h-[50px] md:h-[60px] w-1/2  md:w-[40%] text-[16px] leading-[19px] font-semibold text-center"
                        onClick={() => setShowRetakeTestModal(false)}
                    >
                        취소
                    </Button>
                    <Button
                        variant="primary"
                        className="bg-[#005BE2] h-[50px] md:h-[60px] w-1/2 md:w-[40%] text-[16px] leading-[19px] font-semibold text-center"
                        onClick={handleConfirm}
                    >
                        다시 검사하기
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    )
}
