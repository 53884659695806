import { Modal } from 'react-bootstrap'
import './ResultModal.scss'
import loading from '../../../assets/images/loading.svg'
import spinnerBg from '../../../assets/images/ResultModal.svg'
import { UseUser } from '../../../api/context/userContext'

export function ResultModal(props) {
    const { userData } = UseUser()
    const urlParams = new URLSearchParams(window.location.search)
    const urlnickname = urlParams.get('nickname')
    const nickname = urlnickname || userData.nickname
    return (
        <div className="login-modal-wrapper result-modal-wrapper">
            <Modal
                className="login-modal-wrapper result-modal-wrapper"
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={props.showModal}
                onHide={() => props.handleShowModal(false)}
            >
                <div className="modalConent">
                    <Modal.Header>
                        <button
                            type="button"
                            className="btn-close modal-close-btn"
                            data-bs-dismiss="modal"
                            onClick={() => props.handleShowModal(false)}
                            aria-label="Close"
                        />
                    </Modal.Header>
                    <Modal.Body className="d-flex flex-column py-0 md:py-4 px-4">
                        <div className="d-flex flex-column justify-content-center align-items-center">
                            <h6 className="result-heading mt-2 mb-4 p-0 leading-[28px] md:leading-[47px] w-10/12">{`${nickname} 님의 검사 결과를 분석중입니다.`}</h6>
                            <p className="result-subheading">{`${nickname} 님의 검사를 토대로 결과를 분석중입니다. 결과 분석이 완료 되면 창이 자동으로 꺼집니다. 잠시만 기다려주세요.`}</p>
                        </div>
                        <div className="spinner-container d-flex flex-column justify-content-center align-items-center">
                            <img src={spinnerBg} className="position-absolute loading-bg w-[60%] mt-8" alt="loading-bg" />
                            <img src={loading} className="loading" width="80px" height="80px" alt="loading" />
                        </div>
                    </Modal.Body>
                </div>
            </Modal>
        </div>
    )
}
