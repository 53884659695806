import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { useNavigate } from 'react-router-dom'
import alertCircleOutline from '../../../assets/icons/alertCircleOutline.svg'

export function ShowKakaoError({ showKakaoError, setShowKakaoError }) {
    const navigate = useNavigate()
    return (
        <Modal
            size="lg"
            centered
            aria-labelledby="contained-modal-title-vcenter "
            show={showKakaoError}
            onHide={() => setShowKakaoError(false)}
            dialogClassName="w-[90%] md:w-[75%] max-w-[760px] mx-auto flex items-center"
        >
            <Modal.Header closeButton className="border-none" />
            <Modal.Body className="errorModal font-Pretendard h-[400px] md:h-[490px]">
                <div>
                    <img src={alertCircleOutline} alt="alertCircleOutline" className="h-[80px] md:h-[100px] mb-2 ml-1" />
                </div>
                <div className="block md:hidden text-[16px] leading-[24px] md:h-[90px] w-80 mx-auto md:leading-[30px] font-normal text-center mb-1 ml-3">
                    질문을 계속하려면,
                    <br />
                    휴대폰 본인인증을 하고 닉네임을 설정해야 합니다.
                </div>

                <div className="hidden md:block text-[16px] leading-[24px] md:h-[90px] w-[650px] md:leading-[30px] font-normal text-center mb-1 ml-3">
                    질문을 계속하려면,
                    <br />
                    휴대폰 본인인증을 하고 닉네임을 설정해야 합니다.
                </div>
                <div className="flex justify-center w-full flex space-x-4 mb-14 ml-3 ">
                    <button
                        onClick={() => setShowKakaoError(false)}
                        className="border-2 border-blue-500 bg-[#FFFFFF] h-[50px] md:h-[60px] w-[240px] text-[16px] leading-[19px] font-semibold rounded-lg text-center text-[#005BE2]"
                    >
                        취소
                    </button>
                    <button
                        onClick={() => navigate('/myPage#settingSection')}
                        variant="primary"
                        className="bg-[#005BE2]  h-[50px] md:h-[60px] w-[240px]  text-[16px] leading-[19px] font-semibold rounded-lg text-center text-[#ffffff]"
                    >
                        마이페이지
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    )
}
