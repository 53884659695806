import React, { useEffect, useState } from 'react'
import { UseUser } from '../../../api/context/userContext'

export function InitialComp({ setActiveComp, setFoundEmailMsg, setFoundUserIdMsg }) {
    const [phoneAuthToken, setPhoneAuthToken] = useState(null)
    const { getPhoneAuthToken, emailAuthenticationByPhone } = UseUser()
    const [authDisabled, setAuthDisabled] = useState(true)
    useEffect(() => {
        handlePhoneAuthToken()
    }, [])
    const handlePhoneAuthToken = () => {
        getPhoneAuthToken().then((response) => {
            if (response.success) {
                setPhoneAuthToken(response.data)
                setAuthDisabled(false)
            }
        })
    }
    const fnPopup = () => {
        // const url="http://localhost:3000/phoneVerifyResponse?EncodeData=AgAFQ0E4NDb/OANCNtrDrcHwDgwTrRri5MadM/XV4jfBWcoCfXI8AlvaT6ivz27ydDe3%2B3Bs98r6fBBYQOU70OHGLJjrriSslwu9eAusYdjpK6gPqtJMBKsJNQpJKAm3QndzN6kaLxptGs3Sy5PFMnil4jQC279Fu26TWDTZ36uxWwmuzWqI18LNrIGfnIgZbFO2wyaoutiNNU4aon9OwNQ2S%2BvOOvN280hz3wxlUWOs3ARTkEfx/3530BJtzGDXF9ELRt4MCNxEgCEuh%2BDgsbbKL49RrrY14fD2MXkfXmph0PofMkG%2BWuStDd/RuaBzkwLd7t6/BtobHIlk3a3uRzXG4Gj1rKYlx%2BSNDESUk5aXV5e1uOBEaNTpwbQDXsUj1voWGp%2BJ7k0drVOBDnsOKlNSVT9pDgtPMNQvi/aAfLzwtLrbOjuTUf1BfYddnTWW7pqpSdExTtrHFWeCAxRxAQTOM08JAZzJDEsGNUwTj5Sh7Wgm8fPldvWCXMOA1h3QMX0yzFx7feJTuT8Llp5f6%2BYNsAbrbV36%2B2%2BzHMU8t04FnmyUuWmvxNkwd2aKIgfDtV7Pasv7sm8="
        const popupWindow = window.open(
            '',
            'popupChk',
            'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no'
        )
        document.form_chk.action = 'https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb'
        document.form_chk.target = 'popupChk'
        document.form_chk.submit()
        window.getPhoneData = function (params) {
            if (params) {
                verifyPhoneAuthentication(params, popupWindow)
            }
        }
    }
    const verifyPhoneAuthentication = (params, popupWindow) => {
        emailAuthenticationByPhone(params)
            .then((response) => {
                if (response.success) {
                    setFoundEmailMsg(response.data)
                    setFoundUserIdMsg(response.userId)
                    setActiveComp('emailFound')
                } else {
                    setActiveComp('emailNotFound')
                    setFoundEmailMsg(response.message)
                }
            })
            .catch((error) => {
                const msg = error?.response?.data?.message
                setFoundEmailMsg(msg)
                setActiveComp('emailNotFound')
            })
            .finally(() => {
                popupWindow.close()
            })
    }
    return (
        <div className="initailCont">
            <div className="left">
                <svg
                    className="h-[45px] w-[30px] md:h-[52px] md:w-[30px]"
                    viewBox="0 0 31 52"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M25.167 0.333344H6.50033C4.95323 0.333344 3.4695 0.947925 2.37554 2.04189C1.28157 3.13585 0.666992 4.61958 0.666992 6.16668V45.8333C0.666992 47.3804 1.28157 48.8642 2.37554 49.9581C3.4695 51.0521 4.95323 51.6667 6.50033 51.6667H25.167C26.7141 51.6667 28.1978 51.0521 29.2918 49.9581C30.3857 48.8642 31.0003 47.3804 31.0003 45.8333V6.16668C31.0003 4.61958 30.3857 3.13585 29.2918 2.04189C28.1978 0.947925 26.7141 0.333344 25.167 0.333344ZM15.8337 49.3333C13.897 49.3333 12.3337 47.77 12.3337 45.8333C12.3337 43.8967 13.897 42.3333 15.8337 42.3333C17.7703 42.3333 19.3337 43.8967 19.3337 45.8333C19.3337 47.77 17.7703 49.3333 15.8337 49.3333ZM26.3337 40H5.33366V7.33334H26.3337V40Z"
                        fill="#272727"
                    />
                </svg>
                <div className="textCont">
                    <span>휴대폰 인증</span>
                    <span>귀하의 명의로된 휴대폰으로 인증</span>
                </div>
            </div>
            <span className={`authenticateButton ${authDisabled ? 'opacity-40' : ''}`} onClick={authDisabled ? null : fnPopup}>
                인증하기
            </span>
            <form name="form_chk" method="get" className="hidden">
                <input type="hidden" name="m" defaultValue="checkplusService" />
                <input type="hidden" name="EncodeData" defaultValue={phoneAuthToken} />
            </form>
        </div>
    )
}
