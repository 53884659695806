import React, { useContext } from 'react'
import GeneIDCard from './GeneIDCard'
import GeneACard from './GeneACard'
import Gene45Card from './Gene45Card'
import GeneSLCard from './GeneSLIcon'
import { MyPageContext } from '../../../api/context/myPageContext'

export default function GeneSection() {
    const {
        apiResponse: {
            data: { characters },
        },
    } = useContext(MyPageContext)
    return (
        <div className="max-w-[1210px] mx-auto px-[20px] py-[40px]">
            <div className="flex flex-wrap">
                <div className="w-1/2 basis-1/2 lg:basis-1/4 lg:w-1/3 ">
                    <GeneIDCard data={characters[0]} />
                </div>
                <div className="w-1/2 basis-1/2 lg:basis-1/4 lg:w-1/3 ">
                    <GeneACard data={characters[1]} />
                </div>
                <div className="w-1/2 basis-1/2 lg:basis-1/4 lg:w-1/3 ">
                    <Gene45Card data={characters[2]} />
                </div>
                <div className="w-1/2 basis-1/2 lg:basis-1/4 lg:w-1/3 ">
                    <GeneSLCard data={characters[3]} />
                </div>
            </div>
        </div>
    )
}
