import { useNavigate } from 'react-router-dom'
import BgContainer from '../../assets/images/BgHeaderTab.svg'
import { UseUser } from '../../api/context/userContext'

function NoResult({ message }) {
    const navigate = useNavigate()
    const { userData } = UseUser()
    return (
        <>
            <div
                className="flex min-h-[175px] items-center justify-center bg-cover bg-bottom bg-no-repeat sm:min-h-[390px] sxl:min-h-[468px]"
                style={{
                    background: `url(${BgContainer})`,
                }}
            >
                <div className="text-white -mt-[20px] sm:mt-0">
                    <h2 className="mb-[8px] text-[24px] font-bold leading-[1.38] sm:mb-[20px] sm:text-[32px] sxl:mb-[30px] sxl:text-[42px] ">
                        개요
                    </h2>
                    <p className="mb-0 hidden text-[18px] font-medium leading-normal sm:block sxl:text-[20px]">
                        DNA 질문검사 결과를 검토하고 조합된 타입 특성에 대해 자세히 알아보십시오.
                    </p>
                    <p className="mb-0 block text-[14px] font-medium leading-[17px] sm:hidden sm:leading-normal md:text-[18px] sxl:text-[20px] ">
                        DNA 질문검사 결과를 검토하고 <br />
                        조합된 타입 특성에 대해 자세히 알아보십시오.
                    </p>
                </div>
            </div>

            <div className="px-[20px] pb-[104px] pt-[75px] sm:pb-[110px] sm:pt-[90px]  sxl:pb-[135px] sxl:pt-[118px]">
                <p className="mb-[20px] text-center text-[18px] font-semibold leading-[22px] tracking-[-0.03em] text-[#999999] sm:mb-[30px] sm:text-[22px] sm:leading-normal sxl:mb-[40px] sxl:text-[26px] sxl:leading-[31px]">
                    {message.split('<br/>').map((line, index) => {
                        return (
                            <div key={index}>
                                {line}
                                <br />
                            </div>
                        )
                    })}
                </p>
                <button
                    className="text-white font-semi-bold ml-auto mt-auto  inline-block min-w-[120px] rounded-[8px] bg-[#005BE2] px-[30px]  py-[16px] text-base leading-[19px] tracking-[-0.03em] transition duration-200 hover:bg-opacity-90 sm:min-w-[134px] sxl:px-[34px] sxl:py-[18px]"
                    onClick={() => navigate(`${userData?.section === 0 ? '/questions' : '/result'}`)}
                >
                    {userData?.section === 0 && '검사시작'}
                    {userData?.section >= 1 && '중간 검사확인'}
                </button>
            </div>
        </>
    )
}

export default NoResult
