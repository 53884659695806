import React from 'react'
import PageCard1 from './PageCard1'
import PageCard2 from './PageCard2'
import PageCard3 from './PageCard3'

function PageCardList() {
    return (
        <div className="max-w-[1210px] mx-auto px-[20px] py-[40px]">
            <div className="flex -mx-[8px] flex-wrap">
                <div className="basis-full w-full sm:basis-1/2 sm:w-1/2 lg:basis-1/3 lg:w-1/3 p-[8px]">
                    <PageCard1 />
                </div>
                <div className="basis-full w-full sm:basis-1/2 sm:w-1/2 lg:basis-1/3 lg:w-1/3 p-[8px]">
                    <PageCard2 />
                </div>
                <div className="basis-full w-full sm:basis-1/2 sm:w-1/2 lg:basis-1/3 lg:w-1/3 p-[8px]">
                    <PageCard3 />
                </div>
            </div>
        </div>
    )
}

export default PageCardList
