import { Modal, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import './ForgetPasswordModal.scss'
import { useState } from 'react'
import axios from '../../../api/axios'
import { Spinner } from '../../Spinner/Spinner'

export function ForgetPasswordModal({
    setSuccessAndErrorResponse,
    showForgetPasswordModal,
    setShowForgetPasswordModal,
    setShowFindEmailModal,
    setShowSuccessModal,
    setResetPasswordWrongEmailModal,
    setShowLoginModal,
    setPasswordError,
}) {
    // ========================================================================================
    // ---------------------------Form Validation starts from Here-----------------------------
    // ========================================================================================
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm()
    // ========================================================================================
    // -----------------------------------APIs starts from Here----------------------------------
    // ==========================================================================================

    // -------------------request Link to reset password----------------------
    const [loading, setLoading] = useState(false)

    const handleFindEmail = () => {
        setShowForgetPasswordModal(false)
        setShowFindEmailModal(true)
    }

    const onSubmit = (data) => {
        setLoading(true)
        const postData = {
            ...data, // spread the properties from the original object
        }
        axios
            .post('/user/forgotPassword', postData)
            .then(({ data }) => {
                if (data.error) {
                    setResetPasswordWrongEmailModal(true)
                    setShowForgetPasswordModal(false)
                } else if (data.message) {
                    localStorage.setItem('resetPasswordToken', data.token)
                    setSuccessAndErrorResponse(data.message)
                    setShowForgetPasswordModal(false)
                    setShowSuccessModal(true)
                }
            })
            .catch((error) => {
                console.error('Error:', error)
                setResetPasswordWrongEmailModal(true)
                setShowForgetPasswordModal(false)
                setPasswordError(error.response.data.message)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <>
            {' '}
            <Modal
                className="forget-password-modal-wrapper"
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={showForgetPasswordModal}
                onHide={() => setShowForgetPasswordModal(false)}
            >
                {loading && <Spinner />}
                <Modal.Header>
                    <button
                        type="button"
                        className="btn-close modal-close-btn"
                        data-bs-dismiss="modal"
                        onClick={() => setShowForgetPasswordModal(false)}
                        aria-label="Close"
                    />
                </Modal.Header>
                <Modal.Body className="px-[21px] md:px-[55px]">
                    <Row className="mb-5">
                        <Col className="col-12 forget-heading d-flex justify-content-center">비밀번호 재설정</Col>
                        <Col className="col-12 forget-text d-flex justify-content-center pt-3">
                            재설정 링크를 전달 받을 이메일을 입력해주세요.
                        </Col>
                        {/* ---------------Form Starts Here--------------- */}

                        <form className="form" onSubmit={handleSubmit(onSubmit)}>
                            <Col className="col-12 mt-[2rem] md:mt-[3.75rem]">
                                {/* ---------------Email--------------- */}
                                <label className="font-Pretendard text-[18px] font-medium leading-[21px] md:mb-[1rem] md:text-[20px] md:leading-[23px] ">
                                    이메일 주소
                                </label>
                                <div className="input-icons mt-1">
                                    <svg
                                        className="ml-4 h-[30px] w-[30px] md:ml-6 md:h-[34px] md:w-[34px]"
                                        width="30"
                                        height="30"
                                        viewBox="0 0 36 36"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M30 12L18 19.5L6 12V9L18 16.5L30 9M30 6H6C4.335 6 3 7.335 3 9V27C3 27.7956 3.31607 28.5587 3.87868 29.1213C4.44129 29.6839 5.20435 30 6 30H30C30.7956 30 31.5587 29.6839 32.1213 29.1213C32.6839 28.5587 33 27.7956 33 27V9C33 8.20435 32.6839 7.44129 32.1213 6.87868C31.5587 6.31607 30.7956 6 30 6Z"
                                            fill="#272727"
                                        />
                                    </svg>
                                    <input
                                        className={`form-control input-field h-[50px] pl-[50px] pr-0 md:h-[70px] md:pl-[80px] ${
                                            errors.email && 'border-[1.5px] border-[#FF0000] focus:outline-none'
                                        }`}
                                        placeholder="이메일 주소"
                                        type="email"
                                        {...register('email', {
                                            required: true,
                                            pattern:
                                                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                        })}
                                    />
                                </div>
                                {errors.email && <p className="error">형식에 맞는 이메일 주소를 정확하게 입력해주세요.</p>}
                            </Col>
                            <Col className="col-12 d-flex justify-content-center mt-[1rem] md:mt-[3rem]">
                                <button
                                    type="submit"
                                    className="text-white btn mt-[4px] h-[50px] w-full bg-[#005BE2] text-[16px] font-semibold leading-[19px] md:mt-[8px] md:h-[62px] md:max-w-[421px] md:text-[20px] md:leading-[24px]"
                                >
                                    보내기
                                </button>
                            </Col>
                        </form>
                        {/* ---------------Form Ends--------------- */}
                        <Col className="col-12 d-flex justify-content-center mt-[0.5rem] md:mt-[0.75rem]">
                            <button
                                className="btn btn-outline-primary mx-auto h-[50px] w-full text-[16px] font-semibold leading-[19px] md:h-[60px] md:max-w-[421px] md:text-[20px] md:leading-[24px]"
                                onClick={() => {
                                    setShowLoginModal(true)
                                    setShowForgetPasswordModal(false)
                                }}
                            >
                                로그인
                            </button>
                        </Col>
                        <Col className="col-12 d-flex justify-content-center mt-[0.75rem] md:mt-[2rem]">
                            <Link className="link" onClick={handleFindEmail}>
                                계정 찾기
                            </Link>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    )
}
