import { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import done from '../../assets/icons/done.svg'
import what from '../../assets/icons/what.svg'
import DD_A from '../../assets/icons/DD-A.svg'
import DD_B from '../../assets/icons/DD-B.svg'
import DD_C from '../../assets/icons/DD-C.svg'
import ID_A from '../../assets/icons/ID-A.svg'
import ID_B from '../../assets/icons/ID-B.svg'
import ID_C from '../../assets/icons/ID-C.svg'
import II_A from '../../assets/icons/II-A.svg'
import II_B from '../../assets/icons/II-B.svg'
import II_C from '../../assets/icons/II-C.svg'

function ProgressBarStep({ label, isCompleted, isActive }) {
    return (
        <div className=" flex  flex-col items-center " key={label} id={isActive ? 'active' : ''}>
            <div className=" border-1 relative top-[47px] z-[-2] flex h-[50px]   w-[50px] items-center justify-center rounded-full border-[#F0F0F0]  bg-[#fff] p-[0.5]  lg:top-[76.5px] lg:h-[80px] lg:w-[80px]" />
            <div className=" flex h-[45px] w-[45px]  items-center justify-center rounded-full bg-[#fff] lg:h-[75px] lg:w-[75px]">
                <div
                    className={`bolder font-Pretendardfont-Pretendard z-[20] flex h-10 w-10 items-center justify-center rounded-full text-center  text-[12px] lg:h-[60px] lg:w-[60px] lg:text-[16px] ${
                        isActive
                            ? ' bg-[#BAE7FF] font-bold text-[#000]'
                            : isCompleted
                              ? 'border-2 border-[#BAE7FF] bg-[#E6F7FF]'
                              : 'bg-[#F0F0F0] text-[#8C8C8C]'
                    }`}
                >
                    {!isCompleted ? label : <img src={done} alt="done" className="w-6 lg:w-10" />}
                </div>
            </div>
        </div>
    )
}

function ProgressBar({ section, currentPage }) {
    console.log('section', section)
    const steps = ['A 유형', 'B 유형', 'C 유형', 'D 유형', '완료']
    const isSmallScreen = useMediaQuery({ query: '(max-width: 768px)' })

    const typeImage = {
        DD_A: {
            icon: DD_A,
            lg: currentPage === 1 ? 4.5 : 4.5,
            sm: section > 0 ? (currentPage === 1 ? 2 : 2.5) : currentPage === 1 ? 2 : 0,
        },
        DD_B: {
            icon: DD_B,
            lg: currentPage === 1 ? 3.5 : 4.2,
            sm: section > 1 ? (currentPage === 1 ? 2.5 : 4.5) : currentPage === 1 ? 2.5 : 4.5,
        },
        DD_C: {
            icon: DD_C,
            lg: currentPage === 1 ? 3.5 : 2,
            sm: currentPage === 1 ? 2 : 4.5,
        },
        ID_A: {
            icon: ID_A,
            lg: currentPage === 1 ? 3.8 : 3,
            sm: currentPage === 1 ? 2.5 : 4.5,
        },
        ID_B: {
            icon: ID_B,
            lg: currentPage === 1 ? 3.5 : 3,
            sm: currentPage === 1 ? 2.5 : 4.5,
        },
        ID_C: {
            icon: ID_C,
            lg: currentPage === 1 ? 4 : 3,
            sm: currentPage === 1 ? 3 : 5.5,
        },
        II_A: {
            icon: II_A,
            lg: currentPage === 1 ? 4 : 4.5,
            sm: currentPage === 1 ? 1.5 : 1,
        },
        II_B: {
            icon: II_B,
            lg: currentPage === 1 ? 3 : 3,
            sm: currentPage === 1 ? 1.5 : 0.5,
        },
        II_C: {
            icon: II_C,
            lg: currentPage === 1 ? 3.5 : 3,
            sm: currentPage === 1 ? 1 : 3.9,
        },
        default: {
            icon: what,
            lg: currentPage === 1 ? 2.5 : 2.5,
            sm: currentPage === 1 ? 1 : -1,
        },
    }
    // get type from localstorage
    const type = localStorage.getItem('type')
    // const type = 'DD_A';
    // const type = 'ID_A';
    // const type = 'II_C';

    const [progress, setProgress] = useState(200)
    const [iconPlaces, setIconPlace] = useState('6%')
    console.log({ iconPlaces, progress, currentPage, section })

    const getProgress = (section, currentPage) => {
        switch (section) {
            case 0:
                return 6.25 * (currentPage - 1)
            case 1:
                return 6.25 * (currentPage - 1) + 25
            case 2:
                return 6.25 * (currentPage - 1) + 50
            case 3:
                return 6.25 * (currentPage - 1) + 75
            default:
                return 0
        }
    }

    useEffect(() => {
        const progresPercentage = getProgress(section, currentPage)
        setProgress(progresPercentage)
        setIconPlace(getIconPlace(progresPercentage, section, currentPage))
    }, [section, currentPage])

    const getIconPlace = (progresPercentage, section, currentPage) => {
        if (isSmallScreen) {
            setTimeout(() => {
                document.getElementById('active').scrollIntoView({ alignToTop: true, behavior: 'smooth', inline: 'start', block: 'start' })
            }, 1000)
        }
        console.log({ progresPercentage, section, currentPage })
        if (section === 0) {
            if (currentPage === 1) return '6%'
            if (currentPage === 2) return isSmallScreen ? `${progresPercentage + 7}%` : `${progresPercentage + 5}%`
            return isSmallScreen ? `${progresPercentage + 7}%` : `${progresPercentage + 5}%`
        }
        if (section === 1) {
            if (currentPage === 1) return isSmallScreen ? `${progresPercentage + 4}%` : `${progresPercentage + 5}%`
            return isSmallScreen ? `${progresPercentage + 4}%` : `${progresPercentage + 5}%`
        }
        if (section === 2) {
            if (currentPage === 1) return isSmallScreen ? `${progresPercentage + 1.5}%` : `${progresPercentage + 3}%`
            return isSmallScreen ? `${progresPercentage + 0}%` : `${progresPercentage + 4}%`
        }
        if (section === 3) {
            if (currentPage === 1) return isSmallScreen ? `${progresPercentage + 0}%` : `${progresPercentage + 2}%`
            if (currentPage === 2) return isSmallScreen ? `${progresPercentage + -1}%` : `${progresPercentage + 2}%`
            if (currentPage === 3) return isSmallScreen ? `${progresPercentage + -2}%` : `${progresPercentage + 2}%`
            return isSmallScreen ? `${progresPercentage - 3}%` : `${progresPercentage + 2}%`
        }
    }

    return (
        <div className="mt-10 overflow-scroll">
            <div className="relative  w-full    min-w-[670px] overflow-scroll  px-[16px] lg:px-[9.3%] ">
                <div className=" mb-2  flex  items-end justify-end " style={{ width: iconPlaces }}>
                    <img
                        src={type && typeImage[type] ? typeImage[type].icon : typeImage.default.icon}
                        alt="character icon"
                        className={`mb-[-56px] w-7 lg:mb-[-82px] lg:w-24 `}
                    />
                </div>
                <div className="flex w-full items-center justify-between">
                    {steps.map((label, index) => (
                        <ProgressBarStep key={label} label={label} isCompleted={index < section} isActive={index === section} />
                    ))}
                </div>
                <div className="relative bottom-[30px] z-[-1] mx-auto flex h-4 w-[98%] items-center border-y border-[#F0F0F0]  bg-[#fff] lg:bottom-[53px] lg:h-8" />
                <div className={` relative bottom-[40px] z-[3]  mx-auto h-1 w-[88%] bg-[#F0F0F0] lg:bottom-[73px] lg:h-2 lg:w-[97%]`}>
                    <div className="h-1 rounded    bg-[#BAE7FF] lg:h-2" style={{ width: `${progress}%` }} />
                </div>
            </div>
        </div>
    )
}

export default ProgressBar
