import React from 'react'
import { useNavigate } from 'react-router-dom'
import ItemsStructure from '../../../assets/images/MyPage/ItemsStructure.svg'

function PageCard2() {
    const navigate = useNavigate()
    return (
        <div className="h-full flex flex-col bg-white rounded-lg shadow-[3px_4px_80px_rgba(194,_203,_214,_0.2)] overflow-hidden">
            <div className="bg-[#E5F0FF] text-center py-[20px] sxl:py-[30px]  min-h-[227px] sxl:min-h-[300px] px-[20px]">
                <p className="text-[#272727] mb-[8px] font-medium">학습유전자 타입의 종류</p>
                <h2 className="text-[#272727] mb-[20px] font-bold text-[18px] md:text-[20px] sxl:text-[24px] max-w-[300px] mx-auto">
                    지구력 / 집착 / 창의행동 / 감정
                </h2>
                <img src={ItemsStructure} alt="item-structure-icon" className="max-h-[115px] mx-auto h-[95px] sxl:h-auto" />
            </div>
            <p className="leading-[17px] sm:leading-normal text-black px-[16px] sm:px-[25px] sxl:text-[16px] text-[14px] mb-0 pb-0 md:pb-[20px] pt-[16px] md:pt-[20px] min-h-[105px] md:min-h-[168px] text-left font-Pretendard !tracking-normal">
                · 지구력=체력=에너지 유전자 (ACE PCR) <br /> · 집착=중독=표현욕구 , 창의행동=호기심=몰입 <br /> 유전자 (DRD2, 4 PCR) <br />{' '}
                · 감정=우울폭력=정서 유전자 (Serotonin Transporter PCR) <br /> 성격 형성에 영향을 주는 인성 관련 유전자를 조기에 분석하여
                진로와 적성에 도움이 되도록 하는데 활용
            </p>
            <div className="text-right pb-[20px] md:pb-[30px] pr-[25px] mt-auto pt-[18px]">
                <button
                    onClick={() => {
                        navigate('/54DNAtype')
                    }}
                    className="bg-[#005BE2] hover:bg-opacity-90 transition duration-200 rounded-full py-[13px] sxl:py-[16px] text-base text-white  px-[30px] sxl:px-[34px] mt-auto ml-auto inline-block font-semi-bold tracking-[-0.03em] leading-[19px]"
                >
                    더 알아보기
                </button>
            </div>
        </div>
    )
}

export default PageCard2
