import React, { useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { ResultModal } from '../Modals/ResultModal/ResultModal'
import { DuplicateQuestionModal } from '../Modals/DuplicateQuestionModal/DuplicateQuestionModal'
import './pagination.scss'
import { getLoggedInUser } from '../../common/utils'
import axios from '../../api/axios'
import { ShowKakaoError } from '../Modals/ShowKakaoError/ShowKakaoError'
import { UseUser } from '../../api/context/userContext'

function Pagination({ data, dataLimit, resultId, nickname, section, currentPage, setCurrentPage }) {
    const navigate = useNavigate()

    const [allAnswers, setAllAnswers] = useState({})
    const [loadingResult, setLoadingResult] = useState(false)
    const [showDuplicateQuestionModal, setShowDuplicateQuestionModal] = useState(false)

    useEffect(() => {
        if (window.innerWidth > 800) {
            window.scroll(0, 90)
        } else {
            window.scroll(0, 60)
        }
    }, [currentPage])
    function scrollToButton(buttonId) {
        const buttonElement = document.getElementById(buttonId)
        buttonElement.scrollIntoView({ behavior: 'smooth' }, true)
        if (window.innerWidth < 800) {
            const currentScrollY = window.scrollY || window.pageYOffset
            window.scroll(0, currentScrollY + 130)
        }
    }
    const { userEmail } = getLoggedInUser()
    const { userData } = UseUser()

    // Number of items to display per page.
    const itemsPerPage = dataLimit

    // Calculate the total number of pages.
    const totalPages = Math.ceil(data.length / itemsPerPage)

    // Calculate the index of the first and last item to display on the current page
    const indexOfLastItem = currentPage * itemsPerPage
    const indexOfFirstItem = indexOfLastItem - itemsPerPage

    // Slice the data array to display only the items for the current page
    const currentItems = data.slice(indexOfFirstItem, indexOfLastItem)

    // Create an array of page numbers to display in the pagination component
    const pageNumbers = []
    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i)
    }

    // Handle Previous click on a page number
    const handlePreviousClick = (event, pageNumber) => {
        event.preventDefault()
        setCurrentPage(pageNumber - 1)
    }

    // handle show duplicate questions modal
    useEffect(() => {
        const localStorageSection = localStorage.getItem('duplicate')
        const duplicateSection = localStorageSection !== null ? Number(localStorageSection) : localStorageSection

        if (data.length && duplicateSection !== section) {
            setShowDuplicateQuestionModal(true)
            localStorage.setItem('duplicate', section)
        }
    }, [data.length, section])

    // Handle Next click on a page number
    const handleNextClick = (event, pageNumber) => {
        event.preventDefault()
        setCurrentPage(pageNumber + 1)
    }

    const sectionType = (section) => {
        switch (section) {
            case 0:
                return 'A'
            case 1:
                return 'B'
            case 2:
                return 'C'
            case 3:
                return 'D'
            default:
                return 'A'
        }
    }

    // -------------------------Request To Submit Result---------------
    const submitResult = () => {
        setLoadingResult(true)
        const apiData = Object.keys(allAnswers).map((e) => {
            return { _id: e, answer: allAnswers[e] }
        })
        const postResult = { email: userEmail, questions: apiData }
        const urlWIthResultId = `questions/calculateResult?resultId=${resultId}`
        const urlWithoutResultId = 'questions/calculateResult'
        axios
            .post(resultId ? urlWIthResultId : urlWithoutResultId, JSON.stringify(postResult))
            .then(() => {
                const type = sectionType(section)
                resultId
                    ? navigate(`/result?resultId=${resultId}&nickname=${nickname}`)
                    : navigate(type == 'D' ? '/thank-you' : `/result?type=${type}`)
            })
            .catch((error) => {
                console.error('Error:', error)
            })
            .finally(() => {
                setLoadingResult(false)
            })
    }

    const isFirstPage = currentPage === 1
    const isLastPage = currentPage === pageNumbers.length
    const filledAll = Object.keys(allAnswers).length >= indexOfLastItem // getting length of object and compare

    // Enable Next button when previous clicked
    // to force user to attempt quentions in sequence
    const [enableButtons, setEnableButtons] = useState(data.map((item, index) => index === 0))
    const [showKakaoError, setShowKakaoError] = useState(false)
    const handleButtonClick = (id, answer, index, question) => {
        const { nickname, phoneNumber } = userData
        if (nickname === '' || phoneNumber === '') {
            setShowKakaoError(true)
        } else {
            if (allAnswers[id] === answer) {
                const { [id]: answer, ...rest } = allAnswers
                setAllAnswers(rest)
                return
            }
            setAllAnswers({ ...allAnswers, [id]: answer })
            if (index < data.length - 1) {
                const newEnableButtons = [...enableButtons]
                newEnableButtons[qNum(question)] = true
                setEnableButtons(newEnableButtons)
            }
            scrollToButton(`buttonId${index}`)
        }
    }
    const qNum = (question) => {
        const number = question.split('.', 2)[0]
        return number.replace(/[^0-9]/g, '')
    }
    return (
        <>
            {showDuplicateQuestionModal && (
                <DuplicateQuestionModal
                    showDuplicateQuestionModal={showDuplicateQuestionModal}
                    setShowDuplicateQuestionModal={setShowDuplicateQuestionModal}
                />
            )}
            {showKakaoError && <ShowKakaoError showKakaoError={showKakaoError} setShowKakaoError={setShowKakaoError} />}
            {loadingResult === true ? (
                <ResultModal nickname={nickname} showModal={loadingResult} handleShowModal={setLoadingResult} />
            ) : (
                ' '
            )}
            {currentItems.map((question, index) => (
                <>
                    <Row className="question hidden justify-end md:flex">
                        <Col md={9} sm={12}>
                            <span key={question._id}>{`${question.question}`}</span>
                        </Col>
                        <Col md={3} sm={12}>
                            <Row className="gx-2 justify-end">
                                <Col xs={6} lg={6}>
                                    <button
                                        name="yes"
                                        variant="outline-primary"
                                        onClick={() => {
                                            handleButtonClick(question._id, 'yes', index, question.question)
                                        }}
                                        id={`buttonId${index}`}
                                        disabled={!enableButtons[qNum(question.question) - 1]}
                                        className={
                                            allAnswers[question._id] === 'yes'
                                                ? 'btn btn-primary col-12 py-md-3 mt-lg-0 mt-2'
                                                : 'btn btn-outline-secondary col-12 py-md-3 mt-lg-0 mt-2'
                                        }
                                    >
                                        그렇다
                                    </button>
                                </Col>
                                <Col xs={6} lg={6}>
                                    <button
                                        name="no"
                                        variant="outline-primary"
                                        onClick={() => {
                                            handleButtonClick(question._id, 'no', index, question.question)
                                        }}
                                        id={`buttonId${index}`}
                                        disabled={!enableButtons[qNum(question.question) - 1]}
                                        className={
                                            allAnswers[question._id] === 'no'
                                                ? 'btn btn-primary col-12 py-md-3 mt-lg-0 mt-2'
                                                : 'btn btn-outline-secondary col-12 py-md-3 mt-lg-0 mt-2'
                                        }
                                    >
                                        아니다
                                    </button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <div className="textFont mt-4 flex w-full flex-col px-3 md:hidden">
                        <div className="flex flex-row">
                            <p className="self-start">{`${question.question.split('.', 2)[0]}.`}</p>
                            <p className="ml-1 self-start text-start">{question.question.split('.', 2)[1]}</p>
                        </div>
                        <div className="flex flex-row items-center justify-between">
                            <button
                                className={`disabled:border-{#c7c4c4} border-{#B4B4B4} h-10 w-[49%] rounded-md border-[1.5px] text-[#B4B4B4] disabled:text-[#c7c4c4] ${
                                    allAnswers[question._id] === 'yes' && 'text-white bg-[#005BE2]'
                                }`}
                                name="yes"
                                variant="outline-primary"
                                id={`buttonId${index}`}
                                onClick={() => {
                                    handleButtonClick(question._id, 'yes', index, question.question)
                                }}
                                disabled={!enableButtons[qNum(question.question) - 1]}
                            >
                                그렇다
                            </button>
                            <button
                                className={`disabled:border-{#c7c4c4} border-{#B4B4B4} h-10 w-[49%] rounded-md border-[1.5px] text-[#B4B4B4] disabled:text-[#c7c4c4] ${
                                    allAnswers[question._id] === 'no' && 'text-white bg-[#005BE2]'
                                }`}
                                name="no"
                                variant="outline-primary"
                                id={`buttonId${index}`}
                                onClick={() => {
                                    handleButtonClick(question._id, 'no', index, question.question)
                                }}
                                disabled={!enableButtons[qNum(question.question) - 1]}
                            >
                                아니다
                            </button>
                        </div>
                    </div>
                </>
            ))}
            <div className="flex w-full items-center justify-center px-3 pb-28 pt-16 md:pt-24">
                <div className="flex items-center justify-center">
                    {isFirstPage ? (
                        ''
                    ) : (
                        <button
                            type="button"
                            className="textFont prevNextColor h-12 w-40 rounded-md text-center font-semibold text-[#005BE2] md:w-28"
                            onClick={(event) => handlePreviousClick(event, currentPage)}
                        >
                            이전
                        </button>
                    )}
                    {isLastPage ? (
                        ''
                    ) : (
                        <button
                            type="button"
                            className="textFont prevNextColor ml-2 h-12 w-40 rounded-md text-center font-semibold text-[#005BE2] disabled:opacity-40 md:w-28"
                            disabled={!filledAll}
                            onClick={(event) => (filledAll ? handleNextClick(event, currentPage) : null)}
                        >
                            다음
                        </button>
                    )}
                    {/* // Result Button */}
                    {currentPage === pageNumbers.length ? (
                        <button
                            type="button"
                            disabled={!filledAll}
                            // className={loadingResult ? 'prevAndNextbuttons' : 'result_button'}
                            className="text-white ml-2 h-12 w-40 rounded-md bg-[#005BE2] disabled:opacity-40 md:w-32"
                            onClick={submitResult}
                        >
                            결과보기
                        </button>
                    ) : (
                        ''
                    )}
                </div>
            </div>
        </>
    )
}

export default Pagination
