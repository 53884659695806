import React from 'react'
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md'

export function LearningGeneDropdown({ SectionData, section, handleButtonClick, toggle, clicked }) {
    const filteredTitles = SectionData.filter((obj) => obj.title !== section)
    return (
        <section className="relative mb-[8rem] md:mb-[18rem]" onClick={toggle}>
            <div className="bg-[#FFFFFF] w-[185px] h-auto md:w-[300px] p-[0.75rem] md:p-[1rem] mx-[1.5rem] my-[2rem] md:mx-[9.3%] md:my-[6rem] rounded-[4px] shadow-[3px_4px_20px_rgba(194,203,214,0.15)] absolute top-0 z-10">
                <div className="flex justify-between items-center cursor-pointer">
                    <div className="text-[#005BE2] md:text-[24px] font-medium md:leading-[29px] textFont text-[16px] leading-[19px]">
                        {section}
                    </div>
                    <div className="text-[#005BE2]">
                        {clicked ? <MdKeyboardArrowUp fontSize={30} /> : <MdKeyboardArrowDown fontSize={30} />}
                    </div>
                </div>

                {clicked ? (
                    <div className="flex flex-col items-start justify-start gap-3 mt-[0.75rem] md:mt-[1.5rem]">
                        {filteredTitles.map((option) => (
                            <div
                                key={option.id}
                                className="text-[#6A6A6A] text-[16px] font-[400px] leading-[19px] textFont md:text-[20px] md:leading-[24px] cursor-pointer"
                                onClick={() => handleButtonClick(option.title)}
                            >
                                {option.title}
                            </div>
                        ))}
                    </div>
                ) : (
                    ''
                )}
            </div>
        </section>
    )
}
