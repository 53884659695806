/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useRef, useEffect } from 'react'
import './Navbar.scss'
import { FaCaretDown } from 'react-icons/fa'
import { Link, useNavigate } from 'react-router-dom'
import Button from '../../Button/button'
import searchIcon from '../../../assets/icons/searchIcon.svg'
import xMarkIcon from '../../../assets/icons/xMarkIcon.svg'
import { SearchPopup } from '../../Modals/SearchModal/SearchPopup'
import { LoginModal } from '../../Modals/LoginModal/LoginModal'
import { LoginFailedModal } from '../../Modals/LoginFailedModal/LoginFailedModal'
import { ForgetPasswordModal } from '../../Modals/ForgetPasswordModal/ForgetPasswordModal'
import { SuccessModal } from '../../Modals/SuccessModal/SuccessModal'
import { ResetPasswordWrongEmailModal } from '../../Modals/ResetPasswordWrongEmailModal/ResetPasswordWrongEmailModal'
import ReportTypes from '../../../views/ReportTypes/ReportTypes'
import logo from '../../../assets/images/logo.svg'
import bars from '../../../assets/images/bars.svg'
import { useHomePage } from '../../../hooks/useHomePage'
// Hover-component and hover-logic-component imports
import { useOnHoverOutside } from './useOnHoverOutside'
import { UseUser } from '../../../api/context/userContext'
import { FindEmailModal } from '../../Modals/FindEmailModal/FindEmailModal'
import axios from '../../../api/axios'
import { Spinner } from '../../Spinner/Spinner'
import { RetakeTestModal } from '../../Modals/RetakeTestModal/RetakeTestModal'
import { ErrorModal } from '../../Modals/ErrorModal/ErrorModal'
import { SessionExpiredModal } from '../../Modals/SessionExpiredModal/SessionExpiredModal'

// ===================================================================================================
//                        ||   Main Navbar Function Starts here   ||
// ===================================================================================================
export function Navbar() {
    const [showSmallScreenMenu, setShowSmallScreenMenu] = useState(false)
    const navigate = useNavigate()
    // get/aceess values from context object
    const { userData, getUser, showSessionExpiredModal, setShowSessionExpiredModal } = UseUser()

    // --------------------- Destructre the Modals States-----------------
    const {
        showLoginModal,
        setShowLoginModal,
        showSearchPopup,
        setShowSearchPopup,
        showLoginFailedModal,
        setShowLoginFailedModal,
        showForgetPasswordModal,
        setShowForgetPasswordModal,
        showSuccessModal,
        setShowSuccessModal,
        setShowErrorModal,
        showErrorModal,
        resetPasswordWrongEmailModal,
        setResetPasswordWrongEmailModal,
        passwordError,
        setPasswordError,
        isLoggedIn,
        Logout,
        successAndErrorResponse,
        setSuccessAndErrorResponse,
        showRetakeTestModal,
        setShowRetakeTestModal,
        showFindEmailModal,
        setShowFindEmailModal,
    } = useHomePage()

    useEffect(() => {
        function updateState() {
            const width = window.innerWidth
            if (width >= 1024) {
                setShowSmallScreenMenu(false)
            }
        }
        window.addEventListener('resize', updateState)
        return () => window.removeEventListener('resize', updateState)
    })

    // --------------------- For Dropdown Menu Logic-------------------------------------
    const dropdownRef = useRef(null) // Create a reference for dropdown container
    const [isMenuDropDownOpen, setMenuDropDownOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const resultRoute =
        userData?.section === 1
            ? '/result?type=A'
            : userData?.section === 2
              ? '/result?type=B'
              : userData?.section === 3
                ? '/result?type=C'
                : '/result?type=D'
    const navLink = userData?.finishTest ? '/thank-you' : [1, 2, 3].includes(userData?.section) ? resultRoute : '/questions'

    // Function to close dropdown
    const closeHoverMenu = () => {
        setMenuDropDownOpen(false)
    }
    const getbuttonText = () => {
        if (userData?.results && userData?.section === 4) {
            return '결과 보기'
        }
        if (userData?.section == 0) {
            return '검사시작'
        }
        if (userData?.section < 4) {
            return '중간 검사확인'
        }
        if (userData?.section == 4) {
            return '결과 보기'
        }
        return '검사시작'
    }
    useOnHoverOutside(dropdownRef, closeHoverMenu) // Call the hook

    // -------------------------Request to retake the test---------------

    const handleRetakeTest = () => {
        if (!isSubmitting) {
            setLoading(true)
            setIsSubmitting(true)
            axios
                .get('questions/retake')
                .then((res) => {
                    if (res) {
                        getUser()
                        setTimeout(() => {
                            navigate('/questions')
                        }, 1000)
                    }
                })
                .catch((error) => {
                    console.log('An error occur while retaking test', error)
                    setShowErrorModal(true)
                    setSuccessAndErrorResponse(error.response.data.message)
                })
                .finally(() => {
                    setLoading(false)
                    setIsSubmitting(true)
                    getUser()
                })
        }
    }
    const buttonText = getbuttonText()
    return (
        <>
            {/* ============================================================================== */}
            {/* -                            ||    Modals       ||                                 */}
            {/* ==============================================================================  */}

            {showSearchPopup === true ? (
                <SearchPopup showSearchPopup={showSearchPopup} setShowSearchPopup={setShowSearchPopup} />
            ) : showLoginModal === true ? (
                <LoginModal
                    setSuccessAndErrorResponse={setSuccessAndErrorResponse}
                    setShowSuccessModal={setShowSuccessModal}
                    showLoginModal={showLoginModal}
                    setShowLoginModal={setShowLoginModal}
                    setShowLoginFailedModal={setShowLoginFailedModal}
                    setShowForgetPasswordModal={setShowForgetPasswordModal}
                    isLoggedIn={isLoggedIn}
                    setShowFindEmailModal={setShowFindEmailModal}
                    setShowErrorModal={setShowErrorModal}
                />
            ) : showLoginFailedModal === true ? (
                <LoginFailedModal
                    showLoginFailedModal={showLoginFailedModal}
                    setShowLoginFailedModal={setShowLoginFailedModal}
                    setShowLoginModal={setShowLoginModal}
                    setShowForgetPasswordModal={setShowForgetPasswordModal}
                    setShowFindEmailModal={setShowFindEmailModal}
                />
            ) : showForgetPasswordModal === true ? (
                <ForgetPasswordModal
                    setSuccessAndErrorResponse={setSuccessAndErrorResponse}
                    setShowLoginModal={setShowLoginModal}
                    showForgetPasswordModal={showForgetPasswordModal}
                    setShowForgetPasswordModal={setShowForgetPasswordModal}
                    setShowSuccessModal={setShowSuccessModal}
                    setResetPasswordWrongEmailModal={setResetPasswordWrongEmailModal}
                    setShowFindEmailModal={setShowFindEmailModal}
                    setPasswordError={setPasswordError}
                />
            ) : showSuccessModal === true ? (
                <SuccessModal
                    successAndErrorResponse={successAndErrorResponse}
                    showSuccessModal={showSuccessModal}
                    setShowSuccessModal={setShowSuccessModal}
                />
            ) : showErrorModal === true ? (
                <ErrorModal
                    successAndErrorResponse={successAndErrorResponse}
                    showErrorModal={showErrorModal}
                    setShowErrorModal={setShowErrorModal}
                />
            ) : resetPasswordWrongEmailModal === true ? (
                <ResetPasswordWrongEmailModal
                    resetPasswordWrongEmailModal={resetPasswordWrongEmailModal}
                    setResetPasswordWrongEmailModal={setResetPasswordWrongEmailModal}
                    setShowForgetPasswordModal={setShowForgetPasswordModal}
                    setShowFindEmailModal={setShowFindEmailModal}
                    passwordError={passwordError}
                />
            ) : showFindEmailModal === true ? (
                <FindEmailModal
                    setShowLoginModal={setShowLoginModal}
                    setShowForgetPasswordModal={setShowForgetPasswordModal}
                    setShowFindEmailModal={setShowFindEmailModal}
                    showFindEmailModal={showFindEmailModal}
                />
            ) : showRetakeTestModal === true ? (
                <RetakeTestModal
                    showRetakeTestModal={showRetakeTestModal}
                    setShowRetakeTestModal={setShowRetakeTestModal}
                    handleRetakeTest={handleRetakeTest}
                />
            ) : showSessionExpiredModal === true ? (
                <SessionExpiredModal
                    showSessionExpiredModal={showSessionExpiredModal}
                    setShowSessionExpiredModal={setShowSessionExpiredModal}
                    setShowLoginModal={setShowLoginModal}
                />
            ) : (
                ''
            )}

            {loading ? <Spinner /> : ''}
            {/* ==============================================================================
      ||-                                Navigation                                       ||
      ============================================================================== */}
            <div className="user_navbar">
                <div className="left">
                    <div className="logo" alt="logo">
                        <img src={logo} alt="logo" onClick={() => navigate('/')} />
                    </div>
                </div>
                <div className="links ml-[13px] mt-[-5px]">
                    <Link to={userData?.finishTest ? '/thank-you' : '/questions'}>DNA 질문검사</Link>
                    {/* Dropdown starts */}
                    <div
                        className="dropdown"
                        ref={dropdownRef}
                        onMouseOver={() => setMenuDropDownOpen(true)} // use mouseover event to show dropdown
                    >
                        <a
                            href="#problem"
                            style={{ display: 'flex' }}
                            onClick={(e) => {
                                e.preventDefault()
                                navigate('/typeReport')
                            }}
                            // onMouseOver={() => setMenuDropDownOpen(true)} //use mouseover event to show dropdown
                        >
                            타입 리포트&nbsp;&nbsp;
                            <FaCaretDown />
                        </a>
                        {isMenuDropDownOpen && <ReportTypes />}
                    </div>
                    {/* Dropdown end */}
                    <a href="/54DNAtype">54DNAtype</a>
                    <a href="/LearningGene">학습유전자</a>
                </div>
                <div className="right mt-[-5px]">
                    <div className="icons  p-1" onClick={() => setShowSearchPopup(true)}>
                        <img src={searchIcon} alt="search" />
                    </div>
                    {/* <div className="icons  p-1"> */}
                    {/*  <img src={globeIcon} alt="globe" /> */}
                    {/* </div> */}
                    {/* ---------------Login/ Logout--------------- */}
                    {userData ? (
                        <>
                            <div className="log_in  p-1" onClick={Logout}>
                                로그아웃
                            </div>
                            <img
                                onClick={() => navigate('/myPage')}
                                src={userData.profileIcon}
                                alt="profile"
                                style={{ height: 50, width: 50 }}
                                className=" cursor-pointer"
                            />
                        </>
                    ) : (
                        <>
                            <div className="log_in  p-1" onClick={() => setShowLoginModal(true)}>
                                로그인
                            </div>
                        </>
                    )}

                    <Button buttonType="button1 p-2" onClick={() => navigate(navLink)}>
                        {buttonText}
                    </Button>
                </div>

                {/* ============================================================== */}
                {/* --------------------     Mobile Menu      ---------------- */}
                {/* ============================================================== */}

                {/* // Background blur if sidebar open */}
                {showSmallScreenMenu && (
                    <div className="fixed inset-0 bottom-0 left-0 right-0 top-0  h-screen w-[100vw] overflow-hidden bg-[#00000052] opacity-90 blur-sm" />
                )}
                <div className="hamburger_menu">
                    {' '}
                    <img src={bars} alt="bars" className="bar_icon" onClick={() => setShowSmallScreenMenu(true)} />
                </div>
                {showSmallScreenMenu && (
                    <div className="mobile_sidebar flex flex-col justify-between">
                        <div className="flex flex-col justify-between gap-[20px]">
                            <div className="x-icon">
                                <img src={xMarkIcon} alt="xMark" onClick={() => setShowSmallScreenMenu(false)} />
                            </div>
                            <>
                                {userData ? (
                                    <>
                                        <div className="logged_Status">
                                            <div className="profile_pic">
                                                <img
                                                    src={userData.profileIcon}
                                                    alt="profile"
                                                    onClick={() => {
                                                        navigate('/myPage')
                                                        setShowSmallScreenMenu(false)
                                                    }}
                                                />
                                            </div>
                                            <div className="logged_Status_profile">
                                                <div className="logged_heading">{userData.nickname}</div>
                                                <div className="logged_text">{userData?.message}</div>
                                            </div>
                                        </div>
                                        <button
                                            className="inspect_button"
                                            onClick={() => {
                                                navigate(navLink)
                                                setShowSmallScreenMenu(false)
                                            }}
                                        >
                                            {buttonText}
                                        </button>
                                    </>
                                ) : (
                                    <>
                                        <div className="loginStatus">
                                            <div
                                                className="login_heading"
                                                onClick={() => {
                                                    setShowSmallScreenMenu(false)
                                                    setShowLoginModal(true)
                                                }}
                                            >
                                                로그인 해주세요.
                                            </div>
                                            <div className="login_text">
                                                계정이 없으신가요?
                                                <br />
                                                회원가입 후 DNA 질문검사를 시작해보세요!
                                            </div>
                                            <button
                                                className="-my-[10px] w-full rounded-lg border border-solid bg-[#005be2] p-[15px] font-[16px] leading-[19px] text-[#ffffff]"
                                                onClick={() => {
                                                    setShowLoginModal(true)
                                                    setShowSmallScreenMenu(false)
                                                }}
                                            >
                                                로그인
                                            </button>
                                            <button
                                                className="signup_button"
                                                onClick={() => {
                                                    navigate('/create-account')
                                                    setShowSmallScreenMenu(false)
                                                }}
                                            >
                                                회원가입
                                            </button>
                                        </div>
                                    </>
                                )}
                                <div className="links">
                                    <a
                                        href="#question"
                                        onClick={() => {
                                            navigate('/questions')
                                            setShowSmallScreenMenu(false)
                                        }}
                                    >
                                        DNA 질문검사
                                    </a>
                                    <a
                                        href="#type"
                                        onClick={() => {
                                            navigate('/typeReport')
                                            setShowSmallScreenMenu(false)
                                        }}
                                    >
                                        타입 리포트
                                    </a>
                                    <a
                                        href="/54DNAtype"
                                        onClick={() => {
                                            setShowSmallScreenMenu(false)
                                        }}
                                    >
                                        54DNAtype
                                    </a>
                                    <a
                                        href="/LearningGene"
                                        onClick={() => {
                                            setShowSmallScreenMenu(false)
                                        }}
                                    >
                                        학습유전자
                                    </a>
                                    <hr />
                                    <Link to="/contact" onClick={() => setShowSmallScreenMenu(false)}>
                                        문의하기
                                    </Link>
                                    <a href="#type">언어</a>
                                    <a
                                        href="#search"
                                        onClick={() => {
                                            setShowSmallScreenMenu(false)
                                            setShowSearchPopup(true)
                                        }}
                                    >
                                        검색
                                    </a>
                                </div>
                            </>
                        </div>
                        {userData ? (
                            <button
                                className="login_button"
                                onClick={() => {
                                    Logout()
                                    setShowSmallScreenMenu(false)
                                }}
                            >
                                로그아웃
                            </button>
                        ) : (
                            ''
                        )}
                    </div>
                )}
            </div>
        </>
    )
}
