/* eslint-disable camelcase */
import React from 'react'
import DNAtype_A from '../../../assets/images/DNAtye-A.svg'
import DNAtype_B from '../../../assets/images/DNAtye-B.svg'
import DNAtype_C from '../../../assets/images/DNAtye-C.svg'
import obsessionGene from '../../../assets/images/obsession.svg'

export function ObsessionGene() {
    const ObsessionData = [
        {
            id: 1,
            heading: `집착 유전자
      (흥분, 쾌감에 대한 집착, 중독, 표현요구 성향을 알아볼 수 있는 유전자)
      DRD2 (Dopamine Receptor D2) PCR`,
            description: `중추 신경계의 신경전달물질인 도파민(dopamine)의 분비에 관여하는 유전자로 이 유전자에 변이가 있으며,
      뇌 속에 일정하지 않은 도파민이 존재하여 집착성 물질이나 자극에 대한 충동을 참을 수 없게 되어 쉽게 집착에 빠지게 됩니다.`,
            card: [
                {
                    id: 1,
                    heading: `A 타입`,
                    description: `선호, 전공 분야에 탁월한 실적, 위대한 발견 및 발명 가능성이 큼
          집착이 매우 강하고, 대인관계 폐쇄적`,
                    image: DNAtype_A,
                },
                {
                    id: 2,
                    heading: `B 타입`,
                    description: `다방면에 지적 호기심이 많아 학습에 유리
          외모, 치장에 대한 관심이 많으며, 감정표현이 적극적임`,
                    image: DNAtype_B,
                },
                {
                    id: 3,
                    heading: `C 타입`,
                    description: `합리적, 규범적, 원만한 대인관계
          순종적, 우수한 학업 성적
          꼼꼼함, 완벽주의 성향, 낯가림 심함`,
                    image: DNAtype_C,
                },
            ],
            image: obsessionGene,
            details: [
                {
                    id: 1,
                    heading: `집착=중독=표현욕구 (Dopamine Receptor D2 PCR)의 학술적 원리 기능
          창의행동=호기심=몰입 유전자 (Dopamine Receptor D4 PCR)의 학술적 원리 기능`,
                    description: `사람의 뇌 속에는 도파민이라는 신경전달 물질이 있는데 기쁨이나 흥분과 관련된 일, 즉 포옹, 키스, 칭찬, 승리 등의 즐겁거나 쾌감을 느낄 때 분비가 촉진된다.
          이러한 현상은 뇌 속에 존재하는 도파민 수용체에 의해 도파민이 다시 흡수됨으로써 진정되고 가라앉게 된다.
          그러나 만일 도파민 수용체의 4군데 부위에 유전자 변이가 생기면 도파민 수용체가 원래의 기능을 잘 수행하지 못하게 되는데 이렇게 되면 뇌 안에 도파민이 정상보다 상대적으로 오래 머물게 되고 쾌감과 즐거움은 더 오래 지속되는 것이다.
          이러한 느낌 때문에 자신이 선천적, 후천적으로 경험한 즐거운 경험을 향해 자제력을 상실한 채 빠져들게 되는 것이다.
          따라서 각종 중독성향, 대범성, 엉뚱한 일 자폐증, 과대망상증 등의 문제점을 발생하게 된다.
          Taq 1 A, B intron6 부위는 돌연변이에 의한 변이를 확인하는 것이며, DRD4는 반복되는 염기 서열이 몇 개인지를 보고변이 여부를 결정하는데, 대개 5개-7개 정도면 변이가 있다고 판단한다.`,
                },
                {
                    id: 2,
                    heading: `집착 유전자 검사에 대한 소개`,
                    description: `집착 유전자는 국내외 병원의 정신과에서 오랫동안 연구되어온 검사 방법입니다.
          A 타입 (A1 / A1:B1 / B1), B 타입( A1 / A2:B1 / B2), C타입  (A2 / A2:B2 / B2) 3가지 타입이 존재합니다.
          A 타입과 B 타입 집착성이 강하며, C 타입의 집착성이 없습니다.
          또한 유전자 변이로 집착성이 강하면 술 · 담배  · 도박 · 마약 · 이성 등에 빠지기 쉬운 특성을 가집니다.`,
                },
            ],
            points: [
                {
                    id: 1,
                    heading: `왜 집착 유전자에 변이가 있으면 집착성이 생기는 것일까?`,
                    description: [
                        {
                            id: 1,
                            li: `사람의 뇌 속에는 도파민이라는 신경 세포간 메세지를 전달하는 물질이 있음`,
                        },
                        {
                            id: 2,
                            li: `도파민은 기쁨 및 흥분과 관련되어 포옹, 키스, 칭찬, 승리 등 기쁠 때 분비가 촉진됨`,
                        },
                        {
                            id: 3,
                            li: `분비 후 도파민은 도파민 수용체와 결합하여 신경세포 안으로 재흡수 됨`,
                        },
                        {
                            id: 4,
                            li: `그러나 도파민 수용체(집착 유전자)에 변이가 일어난 사람들은 도파민의 흡수가 잘 안 되므로 흥분 상태가 오래 지속되고 쾌감을 오랫동안 느끼게 됨`,
                        },
                        {
                            id: 5,
                            li: `그래서 집착 유전자에 변이가 있는 타입은 흥분과 쾌감을 주었던 일을 계속 반복하려 하며, 문제는 일상 생활에서 흥분과 쾌감을 얻기 쉽지 않으므로 보다 빠르고 손쉬운 약물 등을 통해서 흥분과 쾌
              감을 느끼고 즐기려고 함`,
                        },
                        {
                            id: 6,
                            li: `따라서 초기에 집착 유전자 검사를 통해 아이를 파악하는 것이 필요하며, 새롭고 유익한 것에 흥분과 쾌감을 느끼고 계속 그 것을 하도록 하는 것이 중요함`,
                        },
                    ],
                },
                {
                    id: 2,
                    heading: `집착 유전자는 학습과 어떤 관련이 있을까?`,
                    description: [
                        {
                            id: 1,
                            li: `공부를 잘하고 성적이 향상 되려면 학습 외에 다른 것에 빠지지 않아야 함`,
                        },
                        {
                            id: 2,
                            li: `A 타입은 집착성이 강한 타입이므로 어릴 때부터 철저한 계획과 교육을 통해 아이의 관심을 끌만한 놀이, 도구, 학습을 지속적으로 실시해야 하며 꾸준한 대화를 통해 아이의 생활을 놓치는 일이 있
              어서는 안됨`,
                        },
                        {
                            id: 3,
                            li: `B 타입은 집착성의 가능성은 있지만 학습을 통해 성취감, 기쁨, 보람을 느끼게 할 수 있다면 집중력과 몰두하는 힘이 뛰어나므로 학업에서 두각이 나타남`,
                        },
                        {
                            id: 4,
                            li: `C 타입은 집착성의 가능성이 낮은 타입이므로 다른 문제가 없다면 무난하고 우수한 학업 수행이 가능하리라 예상함`,
                        },
                    ],
                },
            ],
        },
    ]
    return (
        <section className="relative mt-12 mb-24 md:my-32 px-[20px] lg:px-[9.3%]">
            {ObsessionData.map((k) => (
                <>
                    <div key={k.id} className="flex flex-col gap-[1rem] md:flex-row-reverse md:justify-between md:gap-[4rem]">
                        {/* background pattern */}
                        <div className="md:hidden top-[47%] left-[0rem] bg-cover bg-no-repeat bg-[url('./assets/images/endurenceGeneMobileBg.svg')] absolute h-[734px] w-[361px] -z-[1]" />
                        <div className="hidden md:block md:right-[0%] md:top-[66%] bg-cover bg-no-repeat bg-[url('./assets/images/onsessionGeneBg.svg')] absolute md:h-[942px] md:w-[1020px] -z-[1]" />

                        <div className="mx-auto md:ml-[0px] md:mx-0 w-1/2 md:w-1/5" />
                        <div className="flex flex-col gap-[16px] md:gap-[40px]">
                            <div className="whitespace-pre-line text-left text-[20px] leading-[24px] font-semibold md:text-[30px] md:leading-[36px]">
                                {k.heading}
                            </div>
                            <div className="mb-[2rem] md:mb-[4rem] whitespace-pre-line text-left text-[16px] leading-[24px] font-normal ">
                                {k.description}
                            </div>
                        </div>
                    </div>
                    {/* card */}
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
                        {k.card.map((card) => (
                            <div key={card.id} className="bg-white rounded-[8px] shadow-[3px_4px_80px_rgba(194,203,214,0.2)] xl:h-[450px]">
                                <div className="rounded-t-[8px] bg-[#FF6D18] h-[50px] md:h-[69px] text-white flex items-center justify-center text-[20px] leading-[24px] md:text-[24px] md:leading-[29px] font-semibold">
                                    {card.heading}
                                </div>
                                <div className="text-left py-3 whitespace-pre-line text-[16px] leading-[19px] md:text-[16px] md:leading-[24px] font-normal px-6">
                                    {card.description}
                                </div>
                                <img src={card.image} alt={card.image} className="mt-8 mb-4 mx-auto" />
                            </div>
                        ))}
                    </div>
                    <img src={k.image} alt={k.image} className="w-100 mt-12 mb-[0] md:-mb-[2rem] md:my-24 mx-auto rounded-[10px]" />
                    {/* details */}
                    <div className="">
                        {k.details.map((detail) => (
                            <div key={detail.id} className="flex flex-col gap-[16px] md:gap-[20px]">
                                <div className="mt-[2rem] md:mt-[6rem] text-left whitespace-pre-line text-[20px] leading-[24px] md:text-[24px] md:leading-[29px] font-semibold">
                                    {detail.heading}
                                </div>
                                <div className="text-left whitespace-pre-line text-[16px] leading-[24px] font-normal">
                                    {detail.description}
                                </div>
                            </div>
                        ))}
                    </div>
                    {/* points */}
                    <div className="">
                        {k.points.map((points) => (
                            <div key={points.id} className="flex flex-col gap-[16px] md:gap-[20px]">
                                <div className="mt-[2rem] md:mt-[6rem] text-left whitespace-pre-line text-[20px] leading-[24px] md:text-[24px] md:leading-[29px] font-semibold">
                                    {points.heading}
                                </div>
                                <ol className="list-disc text-left whitespace-pre-line text-[16px] leading-[24px] font-normal">
                                    {points.description.map((list) => (
                                        <li key={list.li}>{list.li}</li>
                                    ))}
                                </ol>
                            </div>
                        ))}
                    </div>
                </>
            ))}
        </section>
    )
}
