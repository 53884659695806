import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import axios from '../../api/axios'
import SearchList from './SearchList'
import { Spinner } from '../../components/Spinner/Spinner'
import SearchRecommendations from '../../components/Common/SearchRecommendations'
import './SearchPage.scss'

function SearchPage() {
    const { state } = useLocation()
    const [results, setResults] = useState(null)
    const [searchField, setSearchField] = useState('')
    const [loading, setLoading] = useState(false)
    const [recentSearches, setRecentSearches] = useState([])
    // localStorage.setItem('recentSearches', JSON.stringify(recentSearches));

    // Function call when Search Something
    const onSearchChange = (event) => {
        const searchString = event.target.value.toLowerCase()
        setSearchField(searchString)
    }

    // Recent Searches
    const handleRecentSearch = (search) => {
        // check if the query is already in the recent searches list
        if (!recentSearches.includes(search)) {
            // add query to recent searches
            const newSearches = [search, ...recentSearches]
            if (newSearches.length > 10) {
                // remove the oldest item from the array
                newSearches.shift()
            }
            setRecentSearches(newSearches)
        }
    }

    useEffect(() => {
        // Clear the state from location
        const search = JSON.parse(localStorage.getItem('search'))
        setSearchField(state?.searchQuery || search)
    }, [])

    const handleAddQueries = (word) => {
        const recentSearch = JSON.parse(localStorage.getItem('recentSearches'))?.filter((search) => search !== null) || []
        if (!recentSearch.includes(word)) {
            const newQueries = [word, ...recentSearch]
            localStorage.setItem('recentSearches', JSON.stringify(newQueries))
            setRecentSearches(newQueries)
        }
    }

    const fetchSearchedResults = () => {
        const postData = { searchItem: searchField }
        axios
            .post(`/main/search`, postData)
            .then((response) => {
                setResults(response.data.list)
                handleAddQueries(searchField)
            })
            .catch((error) => {
                console.log('An Error Occured while Searching', error)
                setResults(null)
            })
            .finally(() => {
                setLoading(false)
                handleRecentSearch(postData.searchItem)
            })
    }
    useEffect(() => {
        setLoading(true)
        const timer = setTimeout(() => {
            fetchSearchedResults()
        }, 1000)
        return () => {
            clearTimeout(timer)
        }
    }, [searchField])

    // const filteredResults = results.filter((result) => {
    //   return result.title.toLowerCase().includes(searchField);
    // });

    const handleOnclick = (item) => {
        setSearchField(item)
    }

    return (
        <>
            <Helmet>
                <title>검색</title>
            </Helmet>
            <div className="searchContainer">
                <div className="search">
                    <h1 className="search-title text-nowrap text-left">검색 결과</h1>
                    <div className="search-line" />
                    <div>
                        <div className="mb-8 hidden lg:block">
                            <SearchRecommendations handleOnclick={handleOnclick} word={searchField} />
                        </div>
                        <input
                            id="searchIcon"
                            type="search"
                            className="search-box focus:outline-none"
                            placeholder="검색어를 입력해주세요."
                            onChange={onSearchChange}
                            required
                            value={searchField}
                        />
                        <div className="mb-8  mt-4 lg:hidden">
                            <SearchRecommendations handleOnclick={handleOnclick} word={searchField} />
                        </div>
                        {/* <SearchBox onChangeHandler={onSearchChange} placeholder={state?.searchQuery}/> */}
                    </div>
                    {loading ? (
                        <div className="h-[100vh]">
                            <Spinner />
                        </div>
                    ) : (
                        <div className="resultsSearch text-left">
                            {/* <SearchList results={filteredResults} /> */}
                            {!results ? (
                                <p className="noResult  h-[30vh] md:h-[60vh]">검색 결과가 없습니다. 다시 검색해주세요.</p>
                            ) : (
                                <SearchList results={results} searchField={searchField} />
                            )}
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}

export default SearchPage
