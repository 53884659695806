import React from 'react'
import Modal from 'react-bootstrap/Modal'
import './ErrorModal.scss'
import alertCircleOutline from '../../../assets/icons/alertCircleOutline.svg'

export function ErrorModal({ showErrorModal, setShowErrorModal, successAndErrorResponse }) {
    return (
        <Modal
            size="lg"
            className="errorModal"
            centered
            aria-labelledby="contained-modal-title-vcenter"
            show={showErrorModal}
            onHide={() => setShowErrorModal(false)}
        >
            <Modal.Header closeButton className="border-none" />
            <Modal.Body className="errorModal">
                <div>
                    <img src={alertCircleOutline} alt="alertCircleOutline" className="h-[80px] md:h-[100px]" />
                </div>
                <div className="error-text">{successAndErrorResponse}</div>
                <div className="col-12 d-flex justify-content-center md:mb-3">
                    <button
                        variant="primary"
                        className="btn btn-primary h-[50px] w-[48%] max-md:mb-12 md:mt-3 md:mb-10 md:h-[60px] md:w-[35%]"
                        onClick={() => setShowErrorModal(false)}
                    >
                        확인
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    )
}
