import React from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import './LoginFailedModal.scss'
import { Link } from 'react-router-dom'
import danger from '../../../assets/images/danger.svg'

export function LoginFailedModal({
    showLoginFailedModal,
    setShowLoginFailedModal,
    setShowLoginModal,
    setShowForgetPasswordModal,
    setShowFindEmailModal,
}) {
    const handleFindEmail = () => {
        setShowLoginFailedModal(false)
        setShowFindEmailModal(true)
    }
    return (
        <Modal
            className="loginFailedModal"
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={showLoginFailedModal}
            onHide={() => setShowLoginFailedModal(false)}
        >
            <Modal.Header closeButton />
            <Modal.Body className="text-center px-[20px] pt-[12px] sm:pt-[26px] pb-[60px] lg:pb-[70px]">
                <div className="text-3xl md:text-4xl lg:text-[40px] leading-[1.2] text-[#272727] font-bold font-Pretendard  mb-[30px] md:mb-[40px] xl:mb-[60px] ">
                    로그인 오류
                </div>
                <div className="text-[16px] font-normal !leading-[150%] text-center md:text-[18px] xl:text-[20px] font-Pretendard">
                    로그인에 실패하였습니다.
                    <br /> 회원가입이나 개인정보 변경에서 설정하였던 이메일이나 비밀번호가 <br className="hidden sm:block" /> 기억나지 않는
                    경우는 하단에
                    <span className="font-bold">
                        계정 찾기 <span className="text-[#CCCCCC]">&nbsp;|&nbsp;</span> 비밀번호 재설정
                    </span>
                    버튼을 눌러 <br className="hidden sm:block" /> 정보 확인 후 로그인을 다시 시도 해주세요.
                </div>
                <div className="buttons mt-[44px] lg:mt-[60[px]] xl:mt-[80px] flex justify-center mb-[24px]">
                    <Button variant="outline-primary" onClick={() => setShowLoginFailedModal(false)} className="button">
                        취소
                    </Button>
                    <Button
                        variant="primary"
                        className="button"
                        onClick={() => {
                            setShowLoginFailedModal(false)
                            setShowLoginModal(true)
                        }}
                    >
                        다음
                    </Button>
                </div>
                <div className="mx-auto error_image">
                    <img src={danger} alt="error" className="danger" />
                </div>
                <div className="lg:mt-6 sm:mt-[24px] col-12 d-flex justify-content-center">
                    <Link className="mb-0 link font-Pretendard" onClick={handleFindEmail}>
                        계정 찾기
                    </Link>
                    &nbsp;&nbsp; | &nbsp;&nbsp;{' '}
                    <Link
                        className="mb-0 link font-Pretendard"
                        onClick={() => {
                            setShowLoginFailedModal(false)
                            setShowForgetPasswordModal(true)
                        }}
                    >
                        비밀번호 재설정
                    </Link>
                </div>
            </Modal.Body>
        </Modal>
    )
}
