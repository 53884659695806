/* eslint-disable react/no-unstable-nested-components */
import React, { useState } from 'react'
import ReactPaginate from 'react-paginate'
import './SearchList.scss'
import Highlighter from 'react-highlight-words'
import { useNavigate } from 'react-router-dom'

function SearchList(props) {
    const { results, searchField } = props
    function Results({ currentresults }) {
        const navigate = useNavigate()
        return (
            <di>
                {currentresults &&
                    currentresults.map((item) => (
                        <div className="itemContainerSearch" key={item._id}>
                            {/* Displaying the search results */}
                            <div
                                onClick={() => navigate(`/${item.link}`, { state: { searchWord: item?.name, number: item?.number } })}
                                className="cursor-pointer no-underline"
                            >
                                <div className="titleSearch">
                                    {item.headingType} {`>`}
                                    <span className="ml-[4px]">{item?.number}</span>
                                    {/* Highlight the search words */}
                                    <Highlighter
                                        className="titleSearch"
                                        highlightClassName="text-primary font-bold bg-[#F5F5F5]"
                                        searchWords={[searchField]}
                                        autoEscape
                                        textToHighlight={`${item?.name}`}
                                    />
                                    <span className="ml-[4px]">{item?.character}</span>
                                    {item?.type ? <span>{`(${item?.type})`}</span> : <span className="ml-[4px]">{item?.type}</span>}
                                </div>
                            </div>
                            {/* Highlight the search words */}
                            <Highlighter
                                className="descriptionSearch"
                                highlightClassName="text-[#999999] font-bold bg-[#F5F5F5]"
                                searchWords={[searchField]}
                                autoEscape
                                textToHighlight={`${item?.description}`}
                            />
                        </div>
                    ))}
            </di>
        )
    }

    function PaginatedResults({ resultsPerPage }) {
        // Here we use item offsets; we could also use page offsets
        // following the API or data you're working with.
        const [itemOffset, setItemOffset] = useState(0)
        // const [dataArray, setDataArray] = useState('');

        // Simulate fetching results from another resources.
        // (This could be results from props; or results loaded in a local state
        // from an API endpoint with useEffect and useState)
        const endOffset = itemOffset + resultsPerPage
        const currentresults = results?.slice(itemOffset, endOffset)
        const pageCount = Math.ceil(results?.length / resultsPerPage)

        // Invoke when user click to request another page.
        const handlePageChange = (event) => {
            const newOffset = (event.selected * resultsPerPage) % results?.length
            setItemOffset(newOffset)
        }

        return (
            <>
                <Results currentresults={currentresults} />
                <ReactPaginate
                    previousLabel="<"
                    nextLabel=">"
                    pageClassName="pageClassName"
                    pageLinkClassName="pageLinkClassName"
                    previousClassName="buttonsClassName"
                    previousLinkClassName="buttonsLabelClassName"
                    nextClassName="buttonsClassName"
                    nextLinkClassName="buttonsLabelClassName"
                    breakLabel="..."
                    breakClassName="breakClassName"
                    breakLinkClassName="breakClassName"
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={8}
                    onPageChange={handlePageChange}
                    containerClassName="pagination"
                    activeClassName="active"
                    activeLinkClassName="active"
                />
            </>
        )
    }

    return <PaginatedResults resultsPerPage={10} />
}

export default SearchList
