import { Store } from 'react-notifications-component'

export const addNotificationToApp = (title, message, type) => {
    Store.addNotification({
        title,
        message,
        type,
        insert: 'bottom',
        container: 'bottom-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
            duration: 5000,
            showIcon: true,
        },
    })
}

export const getLoggedInUser = () => {
    return {
        userEmail: localStorage.getItem('email'),
        userNickName: localStorage.getItem('nickname'),
        userToken: localStorage.getItem('token'),
    }
}

export const getProfileImage = () => {
    return localStorage.getItem('profileImage') || false
}
export const setProfileImage = (profileImage) => {
    return localStorage.setItem('profileImage', profileImage) || false
}
export const isUserLoggedIn = () => {
    const token = localStorage.getItem('token')
    return !!token
}
