import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useMediaQuery } from 'react-responsive'
import axios from '../../../api/axios'
import lock from '../../../assets/icons/lockIcon.svg'
import { useHomePage } from '../../../hooks/useHomePage'
import { Spinner } from '../../Spinner/Spinner'
import { ErrorModal } from '../ErrorModal/ErrorModal'
import { SuccessModal } from '../SuccessModal/SuccessModal'
import { UseUser } from '../../../api/context/userContext'
import { signOutFromGoogle, kakaoSignOut } from '../../../common/firebase'

export function SetPassword({ setShowMyPageSettingModal }) {
    const {
        showSuccessModal,
        setShowSuccessModal,
        showErrorModal,
        setShowErrorModal,
        successAndErrorResponse,
        setSuccessAndErrorResponse,
    } = useHomePage()
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm()
    const isSmallScreen = useMediaQuery({ query: '(max-width: 768px)' })

    // -----------------To Match Psswords in Form Validation-----------------------
    const password = React.useRef({})
    password.current = watch('newPassword', '')
    const { userData } = UseUser()
    const { getUser } = UseUser()

    const [loading, setLoading] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false) // to restrict Api calls to once

    // ---------------Submit POST request register data for signup-------------------
    const onSubmit = (data) => {
        setLoading(true)
        const postData = {
            ...data, // spread the properties from the original object
        }
        // to restrict Api calls to once
        if (!isSubmitting) {
            setIsSubmitting(true)
            axios
                .post('/user/updatePassword', postData)
                .then((response) => {
                    if (response) {
                        setSuccessAndErrorResponse(response.data.message)
                        setShowSuccessModal(true)
                        setTimeout(() => {
                            setShowMyPageSettingModal(false)
                            try {
                                if (userData.loginType === 'kakao') {
                                    kakaoSignOut()
                                }
                                if (userData.loginType === 'google') {
                                    signOutFromGoogle()
                                }

                                const { email } = userData
                                const socialLoginType = userData.loginType
                                const postData = {
                                    email,
                                    socialLoginType,
                                }

                                axios.post('/user/disconnect', postData, { timeout: 25000 }).then(async (response) => {
                                    getUser()
                                    alert(response?.data?.message)
                                })
                            } catch (error) {
                                console.log({ error })
                            }
                        }, 2000)
                    }
                })
                .catch((error) => {
                    setSuccessAndErrorResponse(error.response.data.message)
                    setShowErrorModal(true)
                })
                .finally(() => {
                    setLoading(false)
                    setIsSubmitting(false)
                })
        }
    }
    return (
        <>
            {loading && <Spinner />}
            {showSuccessModal === true ? (
                <SuccessModal
                    showSuccessModal={showSuccessModal}
                    setShowSuccessModal={setShowSuccessModal}
                    successAndErrorResponse={successAndErrorResponse}
                />
            ) : showErrorModal === true ? (
                <ErrorModal
                    showErrorModal={showErrorModal}
                    setShowErrorModal={setShowErrorModal}
                    successAndErrorResponse={successAndErrorResponse}
                />
            ) : (
                ''
            )}
            <section className="mb-4 font-Pretendard">
                <h2 className="text-[24px] leading-[29px] md:text-[40px] md:leading-[48px] font-bold text-left mb-[10px] md:mb-[20px]">
                    {userData.loginType} 연동 해제
                </h2>
                <h5 className="mb-[10px] md:mb-[40px]">
                    {userData.email} 계정과 {userData.loginType} 연동을 해제합니다. <br />
                    연동 해제 이후에는 이메일과 비밀번호로 로그인을 하실 수 있습니다.
                    <br />
                    해제하려면 이메일 로그인에 사용할 비밀번호 설정이 필요합니다.
                </h5>
                <form className="flex flex-col gap-[15px] md:gap-[18px] px-[5px] md:px-0" onSubmit={handleSubmit(onSubmit)}>
                    {/* --------------New Password------------------- */}
                    <div className="flex flex-col items-start justify-center gap-[10px] md:gap-[20px] md:px-[8px] text-left">
                        <label className=" text-[18px] md:text-[20px] leading-[21px] md:leading-[23px] font-medium font-Pretendard">
                            비밀번호
                        </label>
                        <div className="flex flex-col md:flex-row items-center justify-between gap-2 w-full">
                            <div className="w-full relative">
                                <img
                                    className="absolute top-[10px] md:top-[14px] h-[25px] md:h-[35px] ml-3 md:ml-5"
                                    src={lock}
                                    alt="lock"
                                />
                                <input
                                    className="w-full rounded-[8px] bg-white text-[#575757] text-[14px] leading-[16px] md:text-[16px] md:leading-[19px] font-normal border-solid border-[1.5px] border-[#cccccc] p-[15px] md:p-[20px] pl-[50px] md:pl-[70px]"
                                    placeholder={isSmallScreen ? '' : `문자, 숫자, 기호를 조합하여 8자 이상`}
                                    type="password"
                                    {...register('newPassword', {
                                        required: true,
                                        // Google Password Criteria
                                        pattern:
                                            /^(?=.*[0-9])(?=.*[!@#$%^&*(),.?":{}|<>])(?=.*[a-zA-Z])[0-9a-zA-Z!@#$%^&*(),.?":{}|<>]{8,16}$/,
                                    })}
                                />
                                {errors.newPassword?.type === 'required' && <p className="error">입력 필드가 비어 있습니다.</p>}
                                {errors.newPassword?.type === 'pattern' && (
                                    <p className="error">8~16자 영문 대 소문자, 숫자, 특수문자를 사용하세요.</p>
                                )}
                                <div
                                    className="md:hidden mt-1 text-[13px] leading-[15px]  font-normal text-[#57575]"
                                    style={{ display: Object.keys(errors).length === 0 ? 'block' : 'none' }}
                                >
                                    문자, 숫자, 기호를 조합하여 8자 이상
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* -----------------Verify Password------------- */}
                    <div className="flex flex-col items-start justify-center gap-[10px] md:gap-[20px] md:px-[8px] text-left">
                        <label className=" text-[18px] md:text-[20px] leading-[21px] md:leading-[23px] font-medium font-Pretendard -mb-1">
                            비밀번호 확인
                        </label>
                        <div className="flex flex-col md:flex-row items-center justify-between gap-2 w-full">
                            <div className="w-full relative">
                                <img
                                    className="absolute top-[10px] md:top-[14px] h-[25px] md:h-[35px] ml-3 md:ml-5"
                                    src={lock}
                                    alt="lock"
                                />
                                <input
                                    className="w-full rounded-[8px] bg-white text-[#575757] text-[14px] leading-[16px] md:text-[16px] md:leading-[19px] font-normal border-solid border-[1.5px] border-[#cccccc] p-[15px] md:p-[20px] pl-[50px] md:pl-[70px]"
                                    placeholder={isSmallScreen ? '' : `변경할 비밀번호를 재입력해주세요.`}
                                    type="password"
                                    {...register('confirmNewPassword', {
                                        required: true,
                                        validate: (value) => value === password.current || 'The passwords do not match',
                                    })}
                                />
                                {errors.confirmNewPassword?.type === 'required' && <p className="error">입력 필드가 비어 있습니다.</p>}
                                {errors.confirmNewPassword?.type === 'validate' && (
                                    <p className="error">위에 입력하신 비밀번호를 한번 더 입력해주세요.</p>
                                )}
                                <div
                                    className="md:hidden mt-1 text-[13px] leading-[15px]  font-normal text-[#57575]"
                                    style={{ display: Object.keys(errors).length === 0 ? 'block' : 'none' }}
                                >
                                    변경할 비밀번호를 재입력해주세요.
                                </div>
                            </div>
                        </div>
                    </div>
                    <button
                        type="submit"
                        className="mt-[8px] md:mt-[85px] md:mb-[10px] mx-auto w-full md:w-[68%] border-none bg-[#005BE2] py-[15px] md:py-[20px] rounded-[8px] text-white text-[16px] leading-[19px] font-semibold "
                    >
                        저장
                    </button>
                </form>
            </section>
        </>
    )
}
