/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable camelcase */
import React, { useState } from 'react'
import './LearningGene.scss'
import { Helmet } from 'react-helmet'
import { LearningGeneDropdown } from '../../components/LearningGeneDropdown/LearningGeneDropdown'
import { Introduction } from './Introduction/Introduction'
import { Birth } from './Birth/Birth'
import { Academic } from './Academic/Academic'
import { TestMethod } from './TestMethod/TestMethod'
import header_bg from '../../assets/images/history-header.svg'
import header_bg_mobile from '../../assets/images/history-header-mobile.svg'

function LearningGene() {
    // render different sections
    const [section, setSection] = useState('소개 및 이해')
    // different sections on different tabs
    const handleButtonClick = (sectionName) => {
        setSection(sectionName)
    }

    const [clicked, setClicked] = useState(false)

    const toggle = () => {
        setClicked(!clicked)
    }

    const close = () => {
        setClicked(false)
    }

    const SectionData = [
        {
            id: 1,
            title: `소개 및 이해`,
        },
        {
            id: 2,
            title: `탄생 및 연구`,
        },
        {
            id: 3,
            title: `학술적 원리기능`,
        },
        {
            id: 4,
            title: `검사방법 및 의미`,
        },
    ]

    return (
        <div className="">
            <Helmet>
                <title>학습유전자</title>
            </Helmet>
            <div className="relative mb-6" onClick={() => close()}>
                <img src={header_bg_mobile} alt="bg" className="w-full md:hidden" />
                <img src={header_bg} alt="bg" className="hidden w-full md:block" />
                <p className="text-white absolute left-1/2 top-1/2 m-0 w-full -translate-x-1/2 -translate-y-1/2 transform text-[24px] font-bold leading-[36px] md:text-[42px] md:leading-[58px]">
                    학습유전자
                </p>
            </div>
            <LearningGeneDropdown
                SectionData={SectionData}
                section={section}
                handleButtonClick={handleButtonClick}
                toggle={toggle}
                clicked={clicked}
                close={close}
            />
            <div onClick={() => close()}>
                {section === '소개 및 이해' && <Introduction />}
                {section === '탄생 및 연구' && <Birth />}
                {section === '학술적 원리기능' && <Academic />}
                {section === '검사방법 및 의미' && <TestMethod />}
            </div>
        </div>
    )
}

export default LearningGene
