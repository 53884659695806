import React from 'react'

export function TestMethod() {
    const data = [
        {
            id: 1,
            heading: `학습유전자 검사방법`,
            description: `학습유전자 타입을 알아내는 검사의 방법은 크게 두 가지가 있습니다.

      첫번째 방법은 타액, 혈액, 머리카락과 같은 검체를 채취하여 PCR 검사를 하는 방법입니다.
      이것의 정확도는 99.9% 완벽에 가깝습니다.
      또한 신생아도 검체 채취만 된다면 가능하다는 장점이 있습니다.
      하지만 PCR 검사를 진행하기 위해서는 연구소와 장비 및 시약 등등 준비와 나라별 관련법 인허가의 영향으로 간편하지 않다는 것이 최대의 단점입니다.
      
      두번째 방법은 다각화된 여러 유형의 질문을 통해 자신의 학습유전자 타입을 좁혀 나가며 검사를 하는 방법입니다.
      자신의 질문응답으로만 정확도는 85% 수준이지만, 가족과의 질문응답이 조합된 결과의 정확도라면 95% 수준까지 높일 수 있습니다.
      PCR 검사방법보다는 정확도가 약간 낮고 질문을 이해하는 최소한의 나이가 11살 이상이 되어야 한다는 단점을 가지고 있지만 질문응답으로 매우 간편하게 학습유전자 타입을 알 수 있다는 것이 최대의 장점입니다.`,
        },
        {
            id: 2,
            heading: `학습유전자 타입은 부모의 ½씩 유전으로 조합`,
            description: `자신의 학습유전자 타입은 갑자기 만들어지는 것이 아닙니다. 
      자신의 부모에게 ½씩 유전으로 조합됩니다.
      
      지구력 유전자인 경우 자신이 II 타입(I:I)이라면 부모는 II 타입(I:I)이거나 ID 타입(I:D)일 것 입니다.
      결국 부모에게 ½씩 유전 받아 II 타입(I:I)과 ID 타입(I:D)의 2가지 경우의 수 조합으로 지구력 유전자가 결정 됩니다.
      만약 자신이 DD 타입(D:D)으로 유전 조합되기 위해서는 부모 모두 ID 타입(I:D)이거나 또는 부모가 DD 타입(D:D)이 되어야 할 것입니다.
      
      집착 유전자인 경우 자신이 A 타입(A1:A1)이라면 부모는 A 타입(A1:A1)이거나 B 타입(A1:A2)일 것 입니다.
      결국 부모에게 ½씩 유전 받아 A 타입(A1:A1)과 B 타입(A1:A2)의 2가지 경우의 수 조합으로 집착 유전자가 결정 됩니다.
      만약 자신이 C 타입(A2:A2)으로 유전 조합하기 위해서는 부모 모두 B 타입(A1:A2)이거나 또는 부모가 C 타입(A2:A2)이 되어야 할 것입니다.
      
      창의행동 유전자인 경우 자신이 43 타입(창의)이라면 부모는 43 타입(창의)이거나 44 타입(균형)일 것 입니다.
      결국 부모에게 ½씩 유전 받아 43 타입(창의)과 44 타입(균형)의 2가지 경우의 수 조합으로 창의행동 유전자가 결정 됩니다.
      만약 자신이 45 타입(행동)으로 유전 조합하기 위해서는 부모 중 최소 한명은 45 타입(행동)이 되어야 할 것입니다.
      그리고 창의행동 유전자가 아주 극히 드물게 43 타입(창의)와 45 타입(행동) 동시에 유전 받는 경우도 있습니다.
      
      감정 유전자인 경우 자신이 SS 타입(S:S)이라면 부모가 모두 SS 타입(S:S)이 되었을때 강한 유전 조합으로 감정 유전자가 결정됩니다.
      만약 자신이 SL 타입(S:L)으로 유전 조합하기 이해서는 부모 중 최소 한명은 SL 타입(S:L)이 되어야 할 것입니다.
      
      이처럼 4가지 유전자(지구력, 집착, 창의행동, 감정)을 부모에게 ½씩 유전으로 자신의 타입이 조합되어 학습유전자 타입이 결정 됩니다.`,
        },
    ]
    return (
        <section className="relative flex flex-col gap-8 md:gap-24 mt-[9rem] mb-24 md:my-32 px-[20px] lg:px-[9.3%] font-Pretendard">
            {/* Background pattern */}
            <div className="md:hidden top-[18%] right-[0rem] md:left-[0%] md:top-[20%] bg-cover bg-no-repeat bg-[url('./assets/images/testMethodBg.svg')] absolute h-[778px] w-full md:w-[2325px] -z-[1]" />
            <div className="hidden md:block md:right-[0%] md:top-[8%] bg-cover bg-no-repeat bg-[url('./assets/images/testMethodMobileBg.svg')] absolute h-[1076px] w-full -z-[1]" />

            {data.map((g) => (
                <div key={g.id} className="flex flex-col gap-[1rem] md:gap-[4rem]">
                    <div className="flex flex-col items-start justify-center text-left">
                        <h1 className="whitespace-pre-line text-[20px] mb-[1rem] md:mb-[2.5rem] font-semibold leading-[24px] md:text-[30px] md:leading-[36px]">
                            {g.heading}
                        </h1>

                        <div className="whitespace-pre-line text-[16px] font-normal leading-[24px]">{g.description}</div>
                    </div>
                </div>
            ))}
        </section>
    )
}
