/* eslint-disable camelcase */
import React from 'react'
import DNAtype_43 from '../../../assets/images/DNAtye-43.svg'
import DNAtype_44 from '../../../assets/images/DNAtye-44.svg'
import DNAtype_45 from '../../../assets/images/DNAtye-45.svg'
import obsessionGene from '../../../assets/images/obsession.svg'

export function CreativityGene() {
    const ObsessionData = [
        {
            id: 1,
            heading: `창의행동 유전자
      (내향, 외향에 대한 호기심, 몰입 성향을 알아볼 수 있는 유전자)
      DRD4 (Dopamine Receptor D4) PCR`,
            description: `중추 신경계의 신경전달물질인 도파민(dopamine)의 분비와 운반에 관여하는 유전자로 
      인간의 특성 중 진기함을 추구하는 성향이나 탐구성, 모험성과 관련이 있고 또한 충동성, 과잉행동성의 성향과도 관련이 있는 것으로 알려져 있습니다.`,
            card: [
                {
                    id: 1,
                    heading: `44 타입`,
                    description: `매사에 무난하나 겁이 많은 타입
          새로운 것에 대한 도전이 어려움
          결단력이 약한 편임`,
                    image: DNAtype_44,
                },
                {
                    id: 2,
                    heading: `43 타입`,
                    description: `창의적 · 창조적 능력이 매우 뛰어남
          정적인 면이 강하여 학습 시 공상과 망상의 세계에 빠져지냄 `,
                    image: DNAtype_43,
                },
                {
                    id: 3,
                    heading: `45 타입`,
                    description: `대단히 활동적, 스타성이 매우 강함
          충동적, 대범함, 외향적인 성격이 강해서 안전장치 및 안전 교육이 절대 필요`,
                    image: DNAtype_45,
                },
            ],
            image: obsessionGene,
            details: [
                {
                    id: 1,
                    heading: `창의행동=호기심=몰입 유전자 (Dopamine Receptor D4 PCR)의 학술적 원리 기능
          집착=중독=표현욕구 (Dopamine Receptor D2 PCR)의 학술적 원리 기능`,
                    description: `사람의 뇌 속에는 도파민이라는 신경전달 물질이 있는데 기쁨이나 흥분과 관련된 일, 즉 포옹, 키스, 칭찬, 승리 등의 즐겁거나 쾌감을 느낄 때 분비가 촉진된다.
          이러한 현상은 뇌 속에 존재하는 도파민 수용체에 의해 도파민이 다시 흡수됨으로써 진정되고 가라앉게 된다.
          그러나 만일 도파민 수용체의 4군데 부위에 유전자 변이가 생기면 도파민 수용체가 원래의 기능을 잘 수행하지 못하게 되는데 이렇게 되면 뇌 안에 도파민이 정상보다 상대적으로 오래 머물게 되고
          쾌감과 즐거움은 더 오래 지속되는 것이다.
          이러한 느낌 때문에 자신이 선천적, 후천적으로 경험한 즐거운 경험을 향해 자제력을 상실한 채 빠져들게 되는 것이다.
          따라서 각종 중독성향, 대범성, 엉뚱한 일 자폐증, 과대망상증 등의 문제점을 발생하게 된다.
          Taq 1 A, B intron6 부위는 돌연변이에 의한 변이를 확인하는 것이며, DRD4는 반복되는 염기 서열이 몇 개인지를 보고변이 여부를 결정하는데, 대개 5개-7개 정도면 변이가 있다고 판단한다.`,
                },
                {
                    id: 2,
                    heading: `창의행동 유전자 검사에 대한 소개`,
                    description: `창의행동 유전자는 끊임없는 질문과 엉뚱한 행동, 과대망상, 대범한 행동, 충동구매 등의 공통점을 가지며 자폐증을 가진 이들에게 많이 발견되는 유전자 입니다.
          창의행동 유전자는 집착 유전자와 함께 판단하며, DRD4 유전자에서는 반복 염기 서열이 많을수록 강한 개성을 보입니다.
          대표적으로 길이가 짧은 43 타입과 길이가 긴  45 타입이 있는데 43 타입은 내성적이고 창의력이 높은 타입이며, 45 타입은 매우 외향적 및 충동적이고 대범한 기질을 가집니다.`,
                },
            ],
            points: [
                {
                    id: 1,
                    heading: `창의행동 유전자에 변이가 있으면 어떻게 나타나는 것일까?`,
                    description: [
                        {
                            id: 1,
                            li: `창의행동 유전자는 도파민 수용체를 만드는 유전자 내 DRD4 부위에서 여러 곳에 변이가 동시에 있는 것임`,
                        },
                        {
                            id: 2,
                            li: `특해 DRD4 부위에서 반복되는 동일한 유전자의 길이가 길 때와 짧을 때 위와 같은 특징들이 뚜렷하게 나타남`,
                        },
                        {
                            id: 3,
                            li: `이러한 유전자가 변이되어 있으면 스카이다이빙, 번지점프 등과 같이 스릴을 느낄 수 있는 행위와 마약, 술, 담배 등을 유난히 좋아하는 성격을 갖게 된다는 연구 결과가 있음`,
                        },
                        {
                            id: 4,
                            li: `또한 이러한 변이 유전자를 가진 청소년들은 흥분, 충동성, 무질서성이 강한 성격적 특징을 지닌 것으로 나타남`,
                        },
                        {
                            id: 5,
                            li: `그러나 이 변이 유전자 때문에 새로운 것에 대한 호기심이나 모험심이 강하므로 남들보다 성공할 확률이 높은 가능성도 있음`,
                        },
                        {
                            id: 6,
                            li: `그러므로 아이에 대한 유전자 분석과 그에 상응하는 계획된 프로그램으로 이 유전자 변이를 긍정적으로 이용할 수 있는 방향으로 이끌어주어야 함`,
                        },
                    ],
                },
                {
                    id: 2,
                    heading: `이 유전자의 변이가 있으면 어떻게 대처해야 할까?`,
                    description: [
                        {
                            id: 1,
                            li: `44타입에게 가장 중요한 것은 부모의 태도와 교육임`,
                        },
                        {
                            id: 2,
                            li: `43타입은 만화와 그림을 좋아하고 창의적, 창조적 소질이 다분한 그룹들이므로 조기에 미술 교육을 해도 무방하며 창의력을 더 발전시킬 수 있는 교육에 노출시킬 수 있도록 노력해야 함`,
                        },
                        {
                            id: 3,
                            li: `45타입은 지나치게 외향적이고 활발하므로 침착성이 요구되며, 안전사고에 유의하도록 철저한 세뇌 교육이 절실하고, 다른 유전자 타입과의 조합에 따라 진로를 결정해야 되겠지만 주로 정적이고 내근 위주의 일보다는 몸으로 직접 현장에서 뛰는 방향으로 접근해야 함`,
                        },
                    ],
                },
            ],
        },
    ]
    return (
        <section className="relative mt-12 mb-24 md:my-32 px-[20px] lg:px-[9.3%]">
            {ObsessionData.map((k) => (
                <>
                    <div key={k.id} className="flex flex-col gap-[1rem] md:flex-row-reverse md:justify-between md:gap-[4rem]">
                        {/* background pattern */}
                        <div className="right-[0%] top-[53%] md:left-[0%] md:top-[63%] bg-cover bg-no-repeat bg-[url('./assets/images/creativityGeneBg.svg')] absolute h-[814px] md:h-[814px] w-[1048px] -z-[1]" />
                        <div className="mx-auto md:ml-[0px] md:mx-0 w-1/2 md:w-1/5" />
                        <div className="flex flex-col gap-[16px] md:gap-[40px]">
                            <div className="whitespace-pre-line text-left text-[20px] leading-[24px] font-semibold md:text-[30px] md:leading-[36px]">
                                {k.heading}
                            </div>
                            <div className="mb-[2rem] md:mb-[4rem] whitespace-pre-line text-left text-[16px] leading-[24px] font-normal ">
                                {k.description}
                            </div>
                        </div>
                    </div>
                    {/* card */}
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
                        {k.card.map((card) => (
                            <div key={card.id} className="bg-white rounded-[8px] shadow-[3px_4px_80px_rgba(194,203,214,0.2)] xl:h-[450px]">
                                <div className="rounded-t-[8px] bg-[#7D3C9A] h-[50px] md:h-[69px] text-white flex items-center justify-center text-[20px] leading-[24px] md:text-[24px] md:leading-[29px] font-semibold">
                                    {card.heading}
                                </div>
                                <div className="text-left py-3 whitespace-pre-line text-[16px] leading-[19px] md:text-[16px] md:leading-[24px] font-normal px-6">
                                    {card.description}
                                </div>
                                <img src={card.image} alt={card.image} className="mt-8 mb-4 mx-auto" />
                            </div>
                        ))}
                    </div>
                    <img src={k.image} alt={k.image} className="w-100 mt-12 mb-[0] md:-mb-[2rem] md:my-24 mx-auto rounded-[10px]" />
                    {/* details */}
                    <div className="">
                        {k.details.map((detail) => (
                            <div key={detail.id} className="flex flex-col gap-[16px] md:gap-[20px]">
                                <div className="mt-[2rem] md:mt-[6rem] text-left whitespace-pre-line text-[20px] leading-[24px] md:text-[24px] md:leading-[29px] font-semibold">
                                    {detail.heading}
                                </div>
                                <div className="text-left whitespace-pre-line text-[16px] leading-[24px] font-normal">
                                    {detail.description}
                                </div>
                            </div>
                        ))}
                    </div>
                    {/* points */}
                    <div className="">
                        {k.points.map((points) => (
                            <div key={points.id} className="flex flex-col gap-[16px] md:gap-[20px]">
                                <div className="mt-[2rem] md:mt-[6rem] text-left whitespace-pre-line text-[20px] leading-[24px] md:text-[24px] md:leading-[29px] font-semibold">
                                    {points.heading}
                                </div>
                                <ol className="list-disc text-left whitespace-pre-line text-[16px] leading-[24px] font-normal">
                                    {points.description.map((list) => (
                                        <li key={list.li}>{list.li}</li>
                                    ))}
                                </ol>
                            </div>
                        ))}
                    </div>
                </>
            ))}
        </section>
    )
}
