import React from 'react'
import './Spinner.scss'
import loadingSpinner from '../../assets/images/loading.svg'

export function Spinner() {
    return (
        <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden flex flex-col items-center justify-center">
            <img src={loadingSpinner} className="loader ease-linear rounded-full h-[10vh]" alt="loading" />
        </div>
    )
}
