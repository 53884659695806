import { initializeApp } from 'firebase/app'
import { getAuth, signInWithPopup, GoogleAuthProvider, signOut } from 'firebase/auth'
import axios from '../api/axios'

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY || 'AIzaSyDhDR8VkoyeICRltXywG43Ed6cdlOOBCuk',
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || 'dna-2405a.firebaseapp.com',
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || 'dna-2405a',
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || 'dna-2405a',
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || '345957097857',
    appId: process.env.REACT_APP_FIREBASE_APP_ID || '1:345957097857:web:2e312fa7e1aaec53a6c880',
}

// Initialize Firebase
// eslint-disable-next-line no-unused-vars
const firebaseApp = initializeApp(firebaseConfig)
// Initialize Firebase Auth provider
const provider = new GoogleAuthProvider()

// whenever a user interacts with the provider, we force them to select an account
provider.setCustomParameters({
    prompt: 'select_account ',
})

export const auth = getAuth()

export const signOutFromGoogle = async () => {
    try {
        await signOut(auth)
        console.log('Successfully signed out')
    } catch (error) {
        console.error('Error signing out:', error)
    }
}

export const signInWithGooglePopup = () => signInWithPopup(auth, provider)

export const kakaoSignOut = () => {
    const kakaoAccessToken = localStorage.getItem('kakaoAccessToken')
    if (kakaoAccessToken) {
        axios
            .post(
                process.env.REACT_APP_KAKAO_API_LOGOUT_URL || 'https://kapi.kakao.com/v1/user/logout',
                {},
                {
                    headers: {
                        Authorization: `Bearer ${kakaoAccessToken}`,
                        'Content-type': 'application/x-www-form-urlencoded;charset=utf-8',
                    },
                }
            )
            .then((res) => {
                localStorage.removeItem('kakaoAccessToken')
                console.log('response from kakao', res)
            })
    }
}
