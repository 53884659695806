import React from 'react'
import { useNavigate } from 'react-router-dom'
import DNAStructure from '../../../assets/images/MyPage/DNA.svg'

function PageCard3() {
    const navigate = useNavigate()

    return (
        <div className="h-full flex flex-col bg-white rounded-lg shadow-[3px_4px_80px_rgba(194,_203,_214,_0.2)] overflow-hidden">
            <div className="bg-[#E5F0FF] text-center py-[30px] sxl:py-[40px]  min-h-[227px] sxl:min-h-[300px] px-[20px] flex flex-col justify-between">
                <h2 className="text-[#272727] mt-[20px] font-bold text-[18px] md:text-[20px] sxl:text-[24px] max-w-[300px] mx-auto">
                    학습유전자란?
                </h2>
                <img src={DNAStructure} alt="item-structure-icon" className="max-h-[115px] mx-auto h-[75px] sxl:h-auto" />
            </div>
            <p className="leading-normal text-black px-[16px] sm:px-[25px] sxl:text-[16px] text-[14px] mb-0 pb-0 md:pb-[20px] pt-[16px] md:pt-[20px] min-h-[105px] md:min-h-[168px] text-left font-Pretendard !tracking-normal">
                유전자는 인간의 설계도 입니다. 사람의 외모, 특정 질환 심지어 성격이나 성향까지도 기질적인 유전자가 결정합니다. <br />
                환경이 영향을 다소 줄 수 있으나, 유전자타입에 따라 결정되는 특징은 더 크고 심오 하다고 알려져 있습니다. <br />
                학습유전자는 3가지 type polymorphism으로 ...
            </p>

            <div className="text-right pb-[20px] md:pb-[30px] pr-[25px] mt-auto pt-[18px]">
                <button
                    onClick={() => {
                        navigate('/learningGene')
                    }}
                    className="bg-[#005BE2] hover:bg-opacity-90 transition duration-200 rounded-full py-[13px] sxl:py-[16px] text-base text-white  px-[30px] sxl:px-[34px] mt-auto ml-auto inline-block font-semi-bold tracking-[-0.03em] leading-[19px]"
                >
                    더 알아보기
                </button>
            </div>
        </div>
    )
}

export default PageCard3
