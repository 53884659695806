/* eslint-disable react/jsx-no-constructed-context-values */
import { createContext, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import axios from '../axios'
import Loading from '../../components/Common/Loading'

export const MyPageContext = createContext({
    apiResponse: {},
    setMyPageData: () => {},
})
export function MyPageProvider({ children }) {
    const [apiResponse, setApiResponse] = useState({})
    const [settingResponse, setSettingResponse] = useState(null)
    const [showMyPageSettingModal, setShowMyPageSettingModal] = useState(false)
    const [settingModalValue, setSettingModalValue] = useState(null)
    const [loading, setLoading] = useState(true)
    const params = useParams()
    const setMyPageData = () => {
        axios
            .get('user/myPage')
            .then(({ data }) => {
                setApiResponse(data)
            })
            .catch((error) => {
                console.log('error', error)
                // navigate('/', { state: { showLogin: true }})
            })
            .finally(() => {
                setLoading(false)
            })
    }
    const setShareLinkData = () => {
        axios
            .get(`/user/${params.nickname}`)
            .then(({ data }) => {
                setApiResponse(data)
            })
            .finally(() => {
                setLoading(false)
            })
    }
    useEffect(() => {
        params?.nickname ? setShareLinkData() : setMyPageData()
    }, [])
    const contextValue = {
        apiResponse,
        setApiResponse,
        settingResponse,
        setSettingResponse,
        showMyPageSettingModal,
        setShowMyPageSettingModal,
        settingModalValue,
        setSettingModalValue,
        setMyPageData,
    }
    return <MyPageContext.Provider value={contextValue}>{!loading ? children : <Loading />}</MyPageContext.Provider>
}
