import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import { useForm } from 'react-hook-form'
import CloseIcon from '../../assets/icons/xMarkIcon.svg'
import axios from '../../api/axios'
import { UseUser } from '../../api/context/userContext'
import { useHomePage } from '../../hooks/useHomePage'
import { SuccessModal } from '../Modals/SuccessModal/SuccessModal'
import { ErrorModal } from '../Modals/ErrorModal/ErrorModal'
import loading from '../../assets/images/loading.svg'

export default function RegisterReviewDialog({
    isReviewAdded,
    reviewValue,
    setReviewValue,
    userReview,
    isOpen,
    setIsOpen,
    getUserReview,
    getReviewList,
}) {
    const { getUser } = UseUser()
    const {
        showSuccessModal,
        setShowSuccessModal,
        showErrorModal,
        setShowErrorModal,
        successAndErrorResponse,
        setSuccessAndErrorResponse,
    } = useHomePage()

    const { handleSubmit, register } = useForm()
    const [showLoading, setShowLoading] = useState(false)
    const closeModal = () => {
        setIsOpen(false)
    }

    const onSubmit = (data) => {
        getUser()
        if (isReviewAdded) {
            updateReview(data)
        } else {
            createReview(data)
        }
    }
    const createReview = (data) => {
        setShowLoading(true)
        axios
            .post('/reviews/post', data)
            .then((response) => {
                console.log('check data', data)
                if (response.data.message) {
                    setSuccessAndErrorResponse(response.data.message)
                    setShowSuccessModal(true)
                    setReviewValue(data.contents)
                    getUserReview()
                    getReviewList && getReviewList()
                }
            })
            .catch((error) => {
                console.log('here is the error :', error)
                setSuccessAndErrorResponse(error.response.data.message)
                setShowErrorModal(true)
            })
            .finally(() => {
                setShowLoading(false)
                setTimeout(() => {
                    closeModal()
                }, 3000)
            })
    }
    const updateReview = (data) => {
        setShowLoading(true)
        const postData = {
            id: userReview?.review?._id,
            contents: data.contents,
        }
        axios
            .post('/reviews/updatereview', postData)
            .then((response) => {
                if (response.data.message) {
                    setSuccessAndErrorResponse(response.data.message)
                    setShowSuccessModal(true)
                    setReviewValue(data.contents)
                    getUserReview()
                    getReviewList && getReviewList()
                }
            })
            .catch((error) => {
                setSuccessAndErrorResponse(error.response.data.message)
                setShowErrorModal(true)
            })
            .finally(() => {
                setShowLoading(false)
                setTimeout(() => {
                    closeModal()
                }, 3000)
            })
    }

    return (
        <>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-[999]" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="bg-black fixed inset-0 bg-opacity-25" />
                    </Transition.Child>
                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-[20px] text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="bg-white w-full max-w-[760px] transform overflow-hidden rounded-[10px] py-[62px] text-left align-middle shadow-[0px_10px_15px_rgb(71,71,71,0.1)] transition-all sm:rounded-[20px] sm:py-[72px] sxl:py-[90px]">
                                    <button
                                        onClick={closeModal}
                                        className="!absolute right-[18px] top-[27.5px] p-[7px] outline-none sm:right-[30.5px] sm:top-[30.5px]"
                                    >
                                        <img src={CloseIcon} alt="close" className="" />
                                    </button>

                                    <Dialog.Title
                                        as="h2"
                                        className="mx-auto mb-[12px] max-w-[660px] px-[20px] text-center text-[24px] font-bold text-[#272727] sm:text-[32px] sxl:mb-[20px]  sxl:text-[40px] "
                                    >
                                        {isReviewAdded ? '사용후기 수정' : '사용후기 등록'}
                                    </Dialog.Title>
                                    <div className="mx-auto max-w-[660px] px-[16px] text-center">
                                        <p className="mb-0 text-[14px]  text-[#272727] max-md:px-5 sm:text-[16px] sxl:text-[20px] ">
                                            회원 타입 리포트를 보고 느낀 소감을 사용후기로 {isReviewAdded ? '수정' : '등록'}해주세요.
                                        </p>
                                    </div>
                                    {showLoading ? (
                                        <div className="d-flex align-items-center justify-content-center" style={{ height: '30vh' }}>
                                            <img src={loading} className="h-10 w-10 animate-spin md:h-16 md:w-16" alt="loading" />
                                        </div>
                                    ) : (
                                        <div className="mx-auto mt-[34px] max-w-[660px] px-[20px] sm:mt-[40px] sxl:mt-[54px]">
                                            <form className="form" onSubmit={handleSubmit(onSubmit)}>
                                                <div className="mt-[15px] sm:mt-[20px] sxl:mt-[28px]">
                                                    <div className="relative">
                                                        <textarea
                                                            className="my-4 block h-[200px] w-full whitespace-pre-wrap rounded-[8px] border-[2px] border-[#CCCCCC] bg-no-repeat p-[16px] text-left text-[0.8rem] font-normal leading-[1.2] placeholder:text-[#999999] focus:outline-none sm:p-[20px] sm:text-[16px] md:text-[1rem] sxl:p-[20px]"
                                                            name="contents"
                                                            {...register('contents', {
                                                                required: true,
                                                            })}
                                                            id="contents"
                                                            cols="30"
                                                            rows="8"
                                                            maxLength="500"
                                                            onChange={(e) => setReviewValue(e.target.value)}
                                                        >
                                                            {reviewValue}
                                                        </textarea>
                                                        <div className="absolute right-2 text-[#999999]" style={{ bottom: '-26px' }}>
                                                            <span id="charCount">{reviewValue?.length}</span>/500
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mt-[20px] flex flex-col-reverse items-center justify-center gap-2 text-center sm:mt-[40px] md:flex-row sxl:mt-[60px]">
                                                    <button
                                                        type="button"
                                                        onClick={closeModal}
                                                        className="font-semi-bold mt-[8px] inline-flex h-[50px] w-full max-w-full items-center justify-center rounded-[8px] border-[1.5px] border-[#005BE2] px-[30px] py-[15.5px] text-[16px] leading-[19px] tracking-[-0.03em] text-[#005BE2] no-underline  md:h-[60px] md:w-[240px]"
                                                    >
                                                        취소
                                                    </button>
                                                    <button
                                                        type="submit"
                                                        className=" text-white font-semi-bold mt-[8px] inline-flex h-[50px] w-full max-w-full items-center justify-center rounded-[8px] bg-[#005BE2] px-[30px] py-[15.5px] text-[16px] leading-[19px] tracking-[-0.03em] no-underline  md:h-[60px] md:w-[240px]"
                                                    >
                                                        {isReviewAdded ? '수정하기' : '등록하기'}
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    )}
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
            {/* Modals */}
            {showSuccessModal === true ? (
                <SuccessModal
                    showSuccessModal={showSuccessModal}
                    setShowSuccessModal={setShowSuccessModal}
                    successAndErrorResponse={successAndErrorResponse}
                />
            ) : showErrorModal === true ? (
                <ErrorModal
                    showErrorModal={showErrorModal}
                    setShowErrorModal={setShowErrorModal}
                    successAndErrorResponse={successAndErrorResponse}
                />
            ) : (
                ''
            )}
        </>
    )
}
