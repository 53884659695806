import { useForm } from 'react-hook-form'
import { useState } from 'react'
import { Col } from 'react-bootstrap'
import { Spinner } from '../../Spinner/Spinner'
import axios from '../../../api/axios'

export function FindEmailForm({ setActiveComp, setFoundEmailMsg }) {
    const [loading, setLoading] = useState(false)
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm()

    const onSubmit = (data) => {
        setLoading(true)
        const postData = {
            phoneNumber: data?.phoneNumber,
        }
        axios
            .post('/user/findEmail', postData)
            .then(({ data }) => {
                if (data) {
                    const resEmail = data?.email
                    setFoundEmailMsg(resEmail)
                    setActiveComp('emailFound')
                }
            })
            .catch((error) => {
                console.error('Error:', error)
                const msg = error?.response?.data?.message
                setFoundEmailMsg(msg)
                setActiveComp('emailNotFound')
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <form className="findByEmailForm" onSubmit={handleSubmit(onSubmit)}>
            {loading && <Spinner />}

            <Col className="col-12 mt-[1rem] md:mt-[3rem]">
                <label className="input-label">
                    휴대폰 번호<span>*</span>
                </label>
                <div className="input-cont">
                    <input
                        className="input-field"
                        placeholder="휴대폰 번호를 입력해주세요"
                        type="text"
                        {...register('phoneNumber', {
                            required: true,
                            pattern: /^[0-9]{11,11}$/,
                        })}
                    />
                </div>
                {errors.phoneNumber && <p className="error">정확한 전화번호를 입력해주세요.</p>}
            </Col>
            <Col className="col-12 md:mt-[4rem] mt-[1.25rem] mb-[2rem] md:mb-[3rem] d-flex justify-content-center">
                <button type="submit" className="findEmailBtn w-full md:w-[68%]">
                    찾기
                </button>
            </Col>
        </form>
    )
}
