// This is modal on question page when user comes from mypage friend Section.

import React from 'react'
import Modal from 'react-bootstrap/Modal'

export function CheckOthersModal({ showCheckOthersModal, setShowCheckOthersModal }) {
    return (
        <Modal
            size="lg"
            className="errorModal"
            centered
            aria-labelledby="contained-modal-title-vcenter"
            show={showCheckOthersModal}
            onHide={() => setShowCheckOthersModal(false)}
        >
            <Modal.Header closeButton className="border-none" />
            <Modal.Body className="errorModal font-Pretendard">
                <div className="text-[24px] leading-[29px] font-bold md:text-[40px] md:leading-[48px] text-center">타인 검사 안내</div>
                <div className="text-[16px] leading-[24px] md:text-[19px] md:leading-[30px] font-normal text-center">
                    본 질문 응답은 타인을 검사하는 방향으로 응답을 해주시기 바랍니다.
                    <br />이 결과는 타인의 결과와 조합되어 정확도를 조금 더 높일 수 있도록 도움을 줍니다.
                </div>
                <div className="flex justify-center w-full">
                    <button
                        variant="primary"
                        className="bg-[#005BE2] h-[50px] md:h-[60px] w-full md:w-1/3 text-[16px] leading-[19px] font-semibold rounded-lg text-center text-[#ffffff]"
                        onClick={() => setShowCheckOthersModal(false)}
                    >
                        검사 시작하기
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    )
}
