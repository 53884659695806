import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import BgContainer from '../../assets/images/BgHeaderTab.svg'
import axios from '../../api/axios'
import '../Signup/SignupPage.scss'
import { MyPageContext } from '../../api/context/myPageContext'
import { UseUser } from '../../api/context/userContext'
import { useHomePage } from '../../hooks/useHomePage'
import { SuccessModal } from '../../components/Modals/SuccessModal/SuccessModal'
import { ErrorModal } from '../../components/Modals/ErrorModal/ErrorModal'
import { Spinner } from '../../components/Spinner/Spinner'
import googleIcon from '../../assets/icons/google.svg'
import kakaoIcon from '../../assets/icons/kakaotalk.svg'
import DisconnectModal from '../../components/Modals/DisconnectModal/DisconnectModal'
import { REACT_APP_KAKAO_CONNECT_REDIRECT_URL, REACT_APP_KAKAO_URL, REACT_APP_JS_KEY } from '../../common/constants'
import { signInWithGooglePopup } from '../../common/firebase'
import loadingImg from '../../assets/images/loading.svg'

export function Setting() {
    const { setShowMyPageSettingModal, setSettingModalValue } = useContext(MyPageContext)
    const {
        showSuccessModal,
        setShowSuccessModal,
        showErrorModal,
        setShowErrorModal,
        successAndErrorResponse,
        setSuccessAndErrorResponse,
    } = useHomePage()
    const {
        register,
        trigger,
        formState: { errors },
    } = useForm()

    const { userData, getUser, getPhoneAuthToken, updatePhoneNumberByAuthentication } = UseUser()
    const [loading, setLoading] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false) // to restrict Api calls to once
    const [showDisconnectModal, setShowDisconnectModal] = useState(false) // to restrict Api calls to once
    const [showLoading, setShowLoading] = useState(false)

    const [phoneNum, setPhoneNum] = useState('')
    const [nickname, setNickname] = useState(userData?.nickname || '')
    const [phoneAuthToken, setPhoneAuthToken] = useState(null)
    const [oldNickname, setOldNickname] = useState(userData?.nickname || '')
    const [authEmail, setAuthEmail] = useState(userData?.nickname || '')
    const [authLoginType, setAuthLoginType] = useState(userData?.nickname || '')

    const changePhone = (e) => {
        setPhoneNum(e.target.value)
    }
    const changeName = (e) => {
        setNickname(e.target.value)
    }
    useEffect(() => {
        setPhoneNum(userData?.phoneNumber)
        setNickname(userData?.nickname)
        handlePhoneAuthToken()
    }, [])

    const handlePhoneAuthToken = () => {
        getPhoneAuthToken()
            .then((response) => {
                if (response.success) {
                    setPhoneAuthToken(response.data)
                } else {
                    setSuccessAndErrorResponse(response.message)
                    setShowErrorModal(true)
                }
            })
            .catch((err) => {
                setSuccessAndErrorResponse(err.response.message)
                setShowErrorModal(true)
            })
    }
    // ------------------ Update Nickname Request------------------
    const changeNickname = () => {
        setLoading(true)
        // Store the old nickname
        const previousNickname = oldNickname
        // Update the nickname with the new value
        setNickname(nickname)
        // to restrict Api calls to once
        if (!isSubmitting) {
            setIsSubmitting(true)
            const postData = {
                nickname,
            }
            axios
                .post('/user/updateNickname', postData)
                .then((response) => {
                    if (response.data.message) {
                        setSuccessAndErrorResponse(response.data.message)
                        setOldNickname(nickname)
                        setShowSuccessModal(true)
                    }
                })
                .catch((error) => {
                    setNickname(previousNickname)
                    setSuccessAndErrorResponse(error.response.data.message)
                    setShowErrorModal(true)
                })
                .finally(() => {
                    setLoading(false)
                    setIsSubmitting(false)
                    getUser()
                })
        }
    }
    const verifyPhoneAuthentication = (params, popupWindow) => {
        updatePhoneNumberByAuthentication(params)
            .then((response) => {
                if (response.success) {
                    setSuccessAndErrorResponse(response.message)
                    setShowSuccessModal(true)
                    setPhoneNum(response.data)
                    getUser()
                } else {
                    setSuccessAndErrorResponse(response.message)
                    setShowErrorModal(true)
                }
            })
            .catch((error) => {
                setSuccessAndErrorResponse(error.response.data.message)
                setShowErrorModal(true)
            })
            .finally(() => {
                popupWindow.close()
            })
    }
    const fnPopup = () => {
        // const url="http://localhost:3000/phoneVerifyResponse?EncodeData=AgAFQ0E4NDb6clr7zBq%2BB1nkihK7pP7MpwlsgQB2jNk2tIAt8siv/Oldf/ir8crmxivKMvGUYJxoyKQDvubb7iDub5GD3FSXBo/60B%2Bt7w8q29IlNR3mZ1AuF7YXOOOiccdb%2BWIPfZJa59z/%2BiuFGKDqxAy8UNnc%2BgD8zdbEeay0S5/1hHoYGL7xmOWrGW0exkE8a3AFLRt2/vQiC1PFvSIYOVRe4mzn5M0qQUvobu5giSaY9s77kyQbWAgA5ONJZcYNS3KuXxHviohmkm2RRtXouTjdESz1AeHVMBQujxx9Vxd5FQhXnPtpkIABtpG33ytf6IS4zEhdfXKSLOAQV/Qovv0a%2BlOwStr%2Bjkrism6WlykU346uaiRrDUifdwPe7SfhzxTBjBY%2Bz7yW/VgHeD2JUVanMGTC73AzQQgNgmevH64VQxSc4lPTPuTPx0FRGzdh9U8etTsI/QYzyjc4rtsmdftfdYotw7d3vJm7zfsywBx/MWKfaFsUliDTPKwNTL6Ac2mUeM6pClh6bfyVVFl7tFDssD2P//tnjEGOwU0wi3tcSwDpj1%2BuTGC6hsaLg0fi%2B1hLQv4="
        const popupWindow = window.open(
            '',
            'popupChk',
            'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no'
        )
        document.form_chk.action = 'https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb'
        document.form_chk.target = 'popupChk'
        document.form_chk.submit()
        window.getPhoneData = function (params) {
            if (params) {
                verifyPhoneAuthentication(params, popupWindow)
            } else {
                popupWindow.close()
                setSuccessAndErrorResponse('본인 인증이 실패하였습니다. 다시 시도해 주세요.')
                setShowErrorModal(true)
            }
        }
    }

    const handleDisconnect = (email, type) => {
        if (userData.email === email) {
            setSuccessAndErrorResponse(`${email} 기본 계정이므로 연결을 끊을 수 없습니다.`)
            setShowErrorModal(true)
            return
        }
        // if (userData.email === email && userData?.isPasswordSet === false) {
        //   // setSuccessAndErrorResponse(`${email} 기본 계정이므로 연결을 끊을 수 없습니다.`);
        //   //     setShowErrorModal(true);
        //   setShowMyPageSettingModal(true);
        //   setSettingModalValue('setPassword');
        //   return;
        // }
        setAuthEmail(email)
        setAuthLoginType(type)
        setShowDisconnectModal(true)
    }

    // ------------------ Update PhoneNumber Request------------------
    const changePhoneNumber = () => {
        fnPopup()
    }
    // cancel application withdraw request
    const handleCancel = () => {
        const postData = {
            isWithdraw: '0',
        }
        setLoading(true)
        // to restrict Api calls to once
        if (!isSubmitting) {
            setIsSubmitting(true)
            axios
                .post('/user/withdrawAccount', postData)
                .then((response) => {
                    if (response.data.message) {
                        setSuccessAndErrorResponse(response.data.message)
                        setShowSuccessModal(true)
                        setTimeout(() => {
                            setShowMyPageSettingModal(false)
                        }, 2000)
                    }
                })
                .catch((error) => {
                    setSuccessAndErrorResponse(error.response.data.message)
                    setShowErrorModal(true)
                })
                .finally(() => {
                    getUser()
                    setLoading(false)
                    setIsSubmitting(false)
                })
        }
    }

    const handleKakaoLogin = async () => {
        setShowLoading(true)
        const CLIENT_ID = REACT_APP_JS_KEY
        const REDIRECT_URI = REACT_APP_KAKAO_CONNECT_REDIRECT_URL
        const kakaoURL = `${REACT_APP_KAKAO_URL}/oauth/authorize?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=code`
        window.location.href = kakaoURL
    }

    const logGoogleUser = async () => {
        setShowLoading(true)
        const response = await signInWithGooglePopup()
        const { idToken } = response._tokenResponse
        const postData = {
            email: userData.email,
            firebase_id: idToken,
            socialLoginType: 'google',
        }
        if (idToken) {
            await axios
                .post('/user/connect', postData, { timeout: 25000 })
                .then((response) => {
                    if (response) {
                        alert(response?.data?.message)
                        getUser()
                    }
                    setShowLoading(false)
                })
                .catch((error) => {
                    console.log(error)
                    setShowLoading(false)
                    if (error.response) {
                        setSuccessAndErrorResponse(error.response?.data?.message)
                        setShowErrorModal(true)
                    } else {
                        alert('에러 발생됨. 나중에 다시 시도 해주십시오.')
                    }
                })
                .finally(() => {
                    setShowLoading(false)
                })
        }
    }

    return (
        <>
            {loading && <Spinner />}
            <DisconnectModal
                showDisconnectModal={showDisconnectModal}
                setShowDisconnectModal={setShowDisconnectModal}
                email={authEmail}
                socialLoginType={authLoginType}
            />
            {showSuccessModal === true ? (
                <SuccessModal
                    showSuccessModal={showSuccessModal}
                    setShowSuccessModal={setShowSuccessModal}
                    successAndErrorResponse={successAndErrorResponse}
                />
            ) : showErrorModal === true ? (
                <ErrorModal
                    showErrorModal={showErrorModal}
                    setShowErrorModal={setShowErrorModal}
                    successAndErrorResponse={successAndErrorResponse}
                />
            ) : (
                ''
            )}
            <div
                className="flex min-h-[175px] items-center justify-center bg-cover bg-bottom bg-no-repeat font-Pretendard sm:min-h-[390px] sxl:min-h-[468px]"
                style={{ background: `url(${BgContainer})` }}
            >
                <div className="text-white sm:mt-[15px]">
                    <h2 className="m-0 text-[24px] font-bold leading-normal sm:mb-[20px] sm:text-[32px] sm:leading-[1.38] sxl:mb-[50px] sxl:text-[42px] ">
                        설정
                    </h2>
                </div>
            </div>

            {/* ----------Section--------- */}
            <section className="mx-auto w-full max-w-[1310px] px-[20px] pb-[40px] pt-[60px]  sm:pb-[110px] sm:pt-[65px] md:w-[80%] lg:w-[55.5%] lg:pt-[125px] sxl:pb-[70px]">
                <form className="flex flex-col gap-[15px] md:gap-[27px]">
                    {/* ----------------UserId---------------- */}
                    <div className="flex flex-col items-start justify-center gap-3 text-left">
                        <label className=" text-[18px] font-medium leading-[21px] md:text-[20px] md:leading-[23px]">사용자 ID</label>
                        <div className="flex w-full flex-col items-center justify-between gap-1 md:flex-row">
                            <div className="w-full">
                                <input
                                    className="bg-white  w-full cursor-not-allowed rounded-[8px] border-[1.5px] border-solid border-[#cccccc] p-[14px] text-[16px] font-normal leading-[19px] text-[#575757] md:p-[20px] "
                                    placeholder={`${userData?.userId}`}
                                    type="text"
                                    readOnly
                                    disabled
                                />
                                {errors.userId && <div className="error text-[#f42b2f] md:hidden">{errors.userId.message}</div>}
                            </div>
                        </div>
                        {errors.userId && <div className="error hidden text-[#f42b2f] md:block">{errors.userId.message}</div>}
                    </div>
                    {/* ----------------Email---------------- */}
                    <div className="flex flex-col items-start justify-center gap-3 text-left">
                        <label className=" text-[18px] font-medium leading-[21px] md:text-[20px] md:leading-[23px]">이메일</label>
                        <div className="flex w-full flex-col items-center justify-between gap-1 md:flex-row">
                            <div className="w-full">
                                <input
                                    readOnly
                                    className="bg-white  w-full cursor-not-allowed rounded-[8px] border-[1.5px] border-solid border-[#cccccc] p-[14px] text-[16px] font-normal leading-[19px] text-[#575757] md:p-[20px] "
                                    placeholder={`${userData?.email}`}
                                    type="email"
                                    disabled
                                />
                            </div>
                        </div>
                    </div>
                    {/* ----------------Nickname---------------- */}
                    <div className="flex flex-col items-start justify-center gap-3 text-left">
                        <label className=" text-[18px] font-medium leading-[21px] md:text-[20px] md:leading-[23px]">닉네임</label>
                        <div className="flex w-full flex-col items-center justify-between gap-1 md:flex-row">
                            <div className="w-full">
                                <input
                                    className="bg-white w-full rounded-[8px] border-[1.5px] border-solid border-[#cccccc] p-[14px] text-[16px] font-normal leading-[19px] text-[#575757] md:p-[20px] "
                                    // placeholder={`${userData?.nickname}`}
                                    type="text"
                                    value={nickname}
                                    {...register('nickname', {
                                        required: '입력 필드가 비어 있습니다.',
                                        minLength: {
                                            value: 5,
                                            message:
                                                '닉네임을 입력해주세요. (알파벳 또는 숫자 입력, 최대 30자, 한글 또는 특수문자 입력 불가)',
                                        },
                                        pattern: {
                                            value: /^[a-zA-Z0-9]+$/,
                                            message:
                                                '닉네임을 입력해주세요. (알파벳 또는 숫자 입력, 최대 30자, 한글 또는 특수문자 입력 불가)',
                                        },
                                    })}
                                    onChange={changeName}
                                />
                                {errors.nickname && <div className="error text-[#f42b2f] md:hidden">{errors.nickname.message}</div>}
                            </div>
                            <button
                                type="button"
                                className="w-full text-nowrap rounded-[8px] border-[1.5px] border-solid border-[#005BE2] px-[50px] py-[15px] text-[16px] font-semibold leading-[19px] text-[#005BE2] md:w-auto md:py-[20px]"
                                onClick={async () => {
                                    const result = await trigger('nickname')
                                    if (result) {
                                        changeNickname()
                                    }
                                }}
                            >
                                변경
                            </button>
                        </div>
                        {errors.nickname && <div className="error hidden text-[#f42b2f] md:block">{errors.nickname.message}</div>}
                    </div>

                    {/* ----------------Change Password---------------- */}
                    {userData?.isPasswordSet === true && (
                        <div className="flex flex-col items-start justify-center gap-3 text-left">
                            <label className=" text-[18px] font-medium leading-[21px] md:text-[20px] md:leading-[23px]">
                                비밀번호 변경
                            </label>
                            <div className="flex w-full flex-col items-center justify-between gap-1 md:flex-row">
                                <div className="w-full">
                                    <input
                                        readOnly
                                        className="bg-white w-full cursor-not-allowed rounded-[8px] border-[1.5px] border-solid border-[#cccccc] p-[14px] text-[16px] font-normal leading-[19px] text-[#575757] md:p-[20px] "
                                        placeholder="**********"
                                        type="password"
                                        {...register('password', {
                                            required: true,
                                            // Google Password Criteria
                                            pattern:
                                                /^(?=.*[0-9])(?=.*[!@#$%^&*(),.?":{}|<>])(?=.*[a-zA-Z])[0-9a-zA-Z!@#$%^&*(),.?":{}|<>]{8,16}$/,
                                        })}
                                    />
                                </div>
                                <button
                                    type="button"
                                    className="w-full text-nowrap rounded-[8px] border-[1.5px] border-solid border-[#005BE2] px-[50px] py-[15px] text-[16px] font-semibold leading-[19px] text-[#005BE2] md:w-auto md:py-[20px]"
                                    onClick={() => {
                                        setShowMyPageSettingModal(true)
                                        setSettingModalValue('passwordChange')
                                    }}
                                >
                                    변경
                                </button>
                            </div>
                        </div>
                    )}

                    {/* ----------------Phone Number---------------- */}
                    <div className="flex flex-col items-start justify-center gap-3 text-left">
                        <label className=" text-[18px] font-medium leading-[21px] md:text-[20px] md:leading-[23px]">휴대폰 번호</label>
                        <div className="flex w-full flex-col items-center justify-between gap-1 md:flex-row">
                            <div className="w-full">
                                <input
                                    className="bg-white w-full cursor-not-allowed rounded-[8px] border-[1.5px] border-solid border-[#cccccc] p-[14px] text-[16px] font-normal leading-[19px] text-[#575757] md:p-[20px] "
                                    placeholder={`${userData?.phoneNumber}`}
                                    value={phoneNum}
                                    readOnly
                                    type="number"
                                    {...register('phoneNumber')}
                                    onChange={changePhone}
                                />
                                {errors.phoneNumber && <div className="error text-[#f42b2f] md:hidden">{errors.phoneNumber.message}</div>}
                            </div>
                            <button
                                type="button"
                                className="w-full text-nowrap rounded-[8px] border-[1.5px] border-solid border-[#005BE2] px-[50px] py-[15px] text-[16px] font-semibold leading-[19px] text-[#005BE2] md:w-auto md:py-[20px]"
                                onClick={async () => {
                                    const result = await trigger('phoneNumber')
                                    if (result) {
                                        changePhoneNumber()
                                    }
                                }}
                            >
                                변경
                            </button>
                        </div>
                        {errors.phoneNumber && <div className="error hidden text-[#f42b2f] md:block">{errors.phoneNumber.message}</div>}
                    </div>

                    <div className="pb-12 text-left">
                        {userData?.isWithdraw === 1 && (
                            <>
                                <div className="hidden items-center justify-end lg:flex">
                                    <div className="flex gap-4">
                                        <div className="py-[17px]  text-left text-[14px] font-medium leading-[17px] text-[#999999] md:w-auto md:py-[22px] md:text-[20px] md:leading-[23px]">
                                            {`남은시간 : ${userData.remainingDays}`}
                                        </div>
                                        <button
                                            type="button"
                                            className="text-white h-[50px] rounded-[8px]  border-[1.5px] border-solid border-[#FF4D4F] bg-[#FF4D4F] px-[30px] text-[16px] font-semibold leading-[19px] md:h-[60px] md:w-auto"
                                            onClick={handleCancel}
                                        >
                                            탈퇴 신청 취소
                                        </button>
                                    </div>
                                </div>
                            </>
                        )}
                        {/* // Mobile Design */}
                        {userData?.isWithdraw === 0 ? (
                            <div className="flex justify-between">
                                <div
                                    type="button"
                                    className="w-[100px] pb-[12px]  pt-[6px] text-left text-[14px] font-medium leading-[17px] text-[#999999] md:w-auto md:py-[12px]"
                                    onClick={() => {
                                        setShowMyPageSettingModal(true)
                                        setSettingModalValue('withdrawMembership')
                                    }}
                                >
                                    회원 탈퇴 신청
                                </div>
                            </div>
                        ) : userData?.isWithdraw === 1 ? (
                            <div className="flex justify-between lg:hidden">
                                <div className="py-[17px]  text-left text-[14px] font-medium leading-[17px] text-[#999999] md:w-auto md:py-[22px] md:text-[20px] md:leading-[23px]">
                                    {`남은시간 : ${userData.remainingDays}`}
                                </div>
                                <button
                                    type="button"
                                    className="text-white h-[50px] rounded-[8px]  border-[1.5px] border-solid border-[#FF4D4F] bg-[#FF4D4F] px-[30px] text-[16px] font-semibold leading-[19px] md:h-[60px] md:w-auto"
                                    onClick={handleCancel}
                                >
                                    탈퇴 신청 취소
                                </button>
                            </div>
                        ) : (
                            ''
                        )}
                    </div>

                    <div className="flex flex-col items-start justify-center gap-3 text-left" style={{ marginTop: '-40px' }}>
                        <label className=" text-[18px] font-medium leading-[21px] md:text-[20px] md:leading-[23px]">소셜 로그인 연결</label>
                        <div className="space h-20px flex w-full" />
                        <div className="flex w-full flex-col items-center justify-between gap-1 md:flex-row">
                            <div className="social-icons">
                                <h6>
                                    <a href="blank" target="blank">
                                        <img className="h-[40px] w-[40px]" src={kakaoIcon} alt={kakaoIcon} />
                                    </a>{' '}
                                    카카오 로그인
                                </h6>
                            </div>

                            <button
                                disabled={showLoading}
                                type="button"
                                className={`bg-white w-full text-nowrap rounded-[8px] border-[1px] px-[40px]  py-[10px] font-Pretendard text-[16px] font-semibold leading-[19px]  md:w-auto md:py-[15px] ${
                                    userData?.kakaoEmail ? 'border-[#D00019] text-[#D00019]' : 'border-[#005BE2] text-[#005BE2]'
                                }`}
                                onClick={() =>
                                    userData?.kakaoEmail ? handleDisconnect(userData?.kakaoEmail, 'kakao') : handleKakaoLogin()
                                }
                            >
                                {userData?.kakaoEmail ? '연결끊기' : '연결하기'}
                            </button>
                        </div>
                        <div className="horizontal-line" />

                        <div className="flex w-full flex-col items-center justify-between gap-1 md:flex-row">
                            <div className="social-icons">
                                <h6>
                                    <img className="h-[40px] w-[40px]" src={googleIcon} alt={googleIcon} />
                                    Google로 로그인
                                </h6>
                            </div>
                            <button
                                type="button"
                                className={`bg-white w-full text-nowrap rounded-[8px] border-[1px] px-[40px]  py-[10px] font-Pretendard text-[16px] font-semibold leading-[19px]  md:w-auto md:py-[15px] ${
                                    userData?.googleEmail ? 'border-[#D00019] text-[#D00019]' : 'border-[#005BE2] text-[#005BE2]'
                                }`}
                                disabled={showLoading}
                                onClick={() =>
                                    userData?.googleEmail ? handleDisconnect(userData?.googleEmail, 'google') : logGoogleUser()
                                }
                            >
                                {userData?.googleEmail ? '연결끊기' : '연결하기'}
                            </button>
                        </div>
                        <div className="horizontal-line" />
                    </div>
                    {showLoading && (
                        <div className="d-flex align-items-center justify-content-center  w-full">
                            <img src={loadingImg} className="mx-auto h-6 w-6 animate-spin md:h-6 md:w-6" alt="loading" />
                        </div>
                    )}
                </form>
                <form name="form_chk" method="post">
                    <input type="hidden" name="m" defaultValue="checkplusService" />
                    <input type="hidden" name="EncodeData" defaultValue={phoneAuthToken} />
                </form>
            </section>
        </>
    )
}
