import danger from '../../../assets/images/danger.svg'

export function EmailNotFound({ handleFindEmail, handleForgetPassword }) {
    return (
        <div className="emailNotFoundCont">
            <button className="checkBtn" onClick={handleFindEmail}>
                확인
            </button>
            <div className="error_image">
                <img src={danger} alt="error" className="danger" />
            </div>
            <div className="emailNotFoundbtnCont">
                <span className="btn" onClick={handleFindEmail} role="button" tabIndex={0}>
                    계정 찾기
                </span>
                <span className="separator" />
                <span className="btn" onClick={handleForgetPassword} role="button" tabIndex={0}>
                    비밀번호 재설정
                </span>
            </div>
        </div>
    )
}
