import React from 'react'
import './DNAtestCard.scss'

function DNAtestCard(props) {
    const { image, content } = props.card
    return (
        <div className="w-full">
            <div className="d-none d-md-block">
                <div className="dna_card">
                    <div className="cardImage">
                        <img src={image} alt="cardImage" />
                    </div>
                    <div className="cardContent">{content}</div>
                </div>
            </div>
            <div className="d-md-none mt-0">
                <div className="mobile-card d-inline-flex justify-content-start align-items-center px-3">
                    <div className="">
                        <img src={image} alt="cardImage" className="mobile-image" loading="eager" />
                    </div>
                    <div className="d-flex justify-content-start align-items-center ms-2 mt-3">{content}</div>
                </div>
            </div>
        </div>
    )
}
export default DNAtestCard
