import React from 'react'
import { useNavigate } from 'react-router-dom'

function GeneIDCard({ data: { icon, description } }) {
    const navigate = useNavigate()
    return (
        <div className="flex flex-col h-full lg:!rounded-tl-[8px] lg:!rounded-bl-[8px] rounded-[8px] lg:rounded-none border-[1.5px] border-[#EEEEEE] bg-white relative after:block after:h-[7px] after:w-full after:absolute after:left-0 after:top-0 after:bg-[#93CA49] after:bg-opacity-60 overflow-hidden pt-[7px]">
            <h2 className="text-[#272727] font-bold mt-[17px] sm:mt-[28px] mb-[12px] sm:mb-[18px] text-[18px] md:text-[20px] sxl:text-[24px] max-w-[300px] mx-auto text-center">
                지구력 유전자
            </h2>
            <img src={icon} alt="dna-structure" className="sm:max-w-auto mx-auto  sxl:h-auto h-[85px] md:h-[100px]" />
            <p className="mt-[16px] sm:mt-[20px] md:mt-[40px] px-[16px]  sm:px-[20px] xl:px-[25px] sxl:text-[16px] text-[12px] sm:text-[14px] min-h-[133px] text-left leading-[14.4px] sm:!leading- sxl:leading-[19px] tracking-normal text-[#272727] mb-0">
                {description}
            </p>
            <div className="text-right pb-[20px] lg:pb-[30px] pr-[25px] mt-auto pt-[8px] lg:pt-[40px] !leading-none">
                <button
                    className="underline text-[#005BE2] hover:text-opacity-90 transition duration-200  text-[14px]  mt-auto ml-auto inline-block font-semi-bold tracking-[-0.03em]  leading-none"
                    onClick={() => {
                        navigate('/54dnatype', { state: { section: '지구력 유전자' } })
                    }}
                >
                    더 알아보기 →
                </button>
            </div>
        </div>
    )
}

export default GeneIDCard
