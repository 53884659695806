import { useState } from 'react'

export const useQuestionPage = () => {
    const [showResultModal, setShowResultModal] = useState(false)
    const [showCheckOthersModal, setShowCheckOthersModal] = useState(false)

    return {
        showResultModal,
        setShowResultModal,
        showCheckOthersModal,
        setShowCheckOthersModal,
    }
}
