import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useMediaQuery } from 'react-responsive'
import axios from '../../../api/axios'
import { UseUser } from '../../../api/context/userContext'
import { useHomePage } from '../../../hooks/useHomePage'
import { Spinner } from '../../Spinner/Spinner'
import { ErrorModal } from '../ErrorModal/ErrorModal'
import { SuccessModal } from '../SuccessModal/SuccessModal'

export function EmailChange({ setShowMyPageSettingModal }) {
    const { getUser } = UseUser()
    const {
        showSuccessModal,
        setShowSuccessModal,
        showErrorModal,
        setShowErrorModal,
        successAndErrorResponse,
        setSuccessAndErrorResponse,
    } = useHomePage()
    const isSmallScreen = useMediaQuery({ query: '(max-width: 768px)' })

    const {
        register,
        handleSubmit,
        trigger,
        getValues,
        formState: { errors },
    } = useForm()

    const [loading, setLoading] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false) // to restrict Api calls to once
    const [disableEmail, setDisableEmail] = useState(false)
    const [disableVerifyCode, setDisableVerifyCode] = useState(false)
    // -------------Custom Guide Messages in Form Validation------------------------
    useEffect(() => {
        if (Object.keys(errors).length === 0) {
            // hide the div here
        } else {
            // show the div here
        }
    }, [errors])

    // -------------Get Values from FORM for POST request------------------------
    const getVerificationCode = () => {
        const email = getValues('email') // get email value from Form
        setLoading(true)
        const postData = {
            email,
        }
        // to restrict Api calls to once
        if (!isSubmitting) {
            setIsSubmitting(true)
            setDisableEmail(true)
            axios
                .post('/user/updateEmailSendCode', postData)
                .then((response) => {
                    if (response.data.message) {
                        setSuccessAndErrorResponse(response.data.message)
                        setShowSuccessModal(true)
                    }
                })
                .catch((error) => {
                    setSuccessAndErrorResponse(error.response.data.message)
                    setShowErrorModal(true)
                    setDisableEmail(false)
                })
                .finally(() => {
                    setLoading(false)
                    setIsSubmitting(false)
                })
        }
    }

    // ---------------Verify Code-------------------
    const verifyConfirmationCode = () => {
        // const email = getValues('email'); // get email value from Form
        const confirmationCode = getValues('confirmationCode') // get email value from Form
        const postData = {
            // email: email,
            confirmationCode: confirmationCode.trim(),
        }
        setLoading(true)
        // to restrict Api calls to once
        if (!isSubmitting) {
            setIsSubmitting(true)
            setDisableVerifyCode(true)
            axios
                .post('/user/updateEmailVerifyCode', postData)
                .then((response) => {
                    if (response.data.message) {
                        setSuccessAndErrorResponse(response.data.message)
                        setShowSuccessModal(true)
                    }
                })
                .catch((error) => {
                    setSuccessAndErrorResponse(error.response.data.message)
                    setShowErrorModal(true)
                    setDisableVerifyCode(false)
                })
                .finally(() => {
                    setLoading(false)
                    setIsSubmitting(false)
                })
        }
    }

    const onSubmit = () => {
        const email = getValues('email') // get email value from Form
        const postData = {
            email,
        }
        setLoading(true)
        // to restrict Api calls to once
        if (!isSubmitting) {
            setIsSubmitting(true)
            axios
                .post('/user/updateEmail', postData)
                .then((response) => {
                    if (response.data.message) {
                        setSuccessAndErrorResponse(response.data.message)
                        setShowSuccessModal(true)
                        setTimeout(() => {
                            setShowMyPageSettingModal(false)
                        }, 2000)
                    }
                })
                .catch((error) => {
                    setSuccessAndErrorResponse(error.response.data.message)
                    setShowErrorModal(true)
                })
                .finally(() => {
                    getUser()
                    setLoading(false)
                    setIsSubmitting(false)
                })
        }
    }

    return (
        <section className="mb-4 font-Pretendard">
            {showSuccessModal === true ? (
                <SuccessModal
                    showSuccessModal={showSuccessModal}
                    setShowSuccessModal={setShowSuccessModal}
                    successAndErrorResponse={successAndErrorResponse}
                />
            ) : showErrorModal === true ? (
                <ErrorModal
                    showErrorModal={showErrorModal}
                    setShowErrorModal={setShowErrorModal}
                    successAndErrorResponse={successAndErrorResponse}
                />
            ) : (
                ''
            )}
            {loading && <Spinner />}
            <h2 className="text-[24px] leading-[29px] md:text-[40px] md:leading-[48px] font-bold text-center mb-[20px] md:mb-16 ">
                이메일 변경
            </h2>
            <form className="flex flex-col gap-[20px] md:gap-[30px] px-[5px] md:px-0" onSubmit={handleSubmit(onSubmit)}>
                {/* ----------------Email---------------- */}
                <div className="flex flex-col items-start justify-center gap-[10px] md:gap-[20px] text-left md:px-[8px]">
                    <label className="text-[18px] md:text-[20px] md:mt-[10px] leading-[21px] md:leading-[23px] font-medium font-Pretendard">
                        이메일 주소
                    </label>
                    <div className="flex flex-col md:flex-row items-center justify-between gap-2 w-full">
                        <div className="w-full relative">
                            <svg
                                className="absolute top-[10px] md:top-[14px] h-[25px] md:h-[35px] ml-3 md:ml-6"
                                width="34"
                                height="34"
                                viewBox="0 0 36 36"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M30 12L18 19.5L6 12V9L18 16.5L30 9M30 6H6C4.335 6 3 7.335 3 9V27C3 27.7956 3.31607 28.5587 3.87868 29.1213C4.44129 29.6839 5.20435 30 6 30H30C30.7956 30 31.5587 29.6839 32.1213 29.1213C32.6839 28.5587 33 27.7956 33 27V9C33 8.20435 32.6839 7.44129 32.1213 6.87868C31.5587 6.31607 30.7956 6 30 6Z"
                                    fill="#272727"
                                />
                            </svg>
                            <input
                                className="disabled:cursor-not-allowed w-full rounded-[8px] bg-white text-[#575757] text-[14px] leading-[16px] md:text-[16px] md:leading-[19px] font-normal border-solid border-[1.5px] border-[#cccccc] p-[15px] md:p-[20px] pl-[50px] md:pl-[80px]"
                                placeholder={isSmallScreen ? `이메일 주소` : `인증번호를 수신받을 이메일 주소를 입력해주세요.`}
                                type="email"
                                disabled={disableEmail}
                                {...register('email', {
                                    required: true,
                                    // validate email using Regex
                                    pattern:
                                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                })}
                            />
                        </div>
                        <div
                            className="md:hidden text-[13px] leading-[15px] font-normal text-[#57575]"
                            style={{ display: Object.keys(errors).length === 0 ? 'block' : 'none' }}
                        >
                            형식에 맞는 이메일 주소를 입력한 후 오타가 없는지 재확인해 주세요.
                        </div>
                        {errors.email?.type === 'required' && (
                            <p className="md:hidden error block text-left w-full">입력 필드가 비어 있습니다.</p>
                        )}
                        {errors.email?.type === 'pattern' && (
                            <p className="md:hidden error block text-left w-full">이메일을 형식에 맞게 입력해주세요</p>
                        )}
                        <button
                            type="button"
                            disabled={disableEmail}
                            className="disabled:cursor-not-allowed w-full md:w-auto text-nowrap border-solid border-[1.5px] border-[#005BE2] px-[22px] py-[15px] md:py-[20px] mb-[20px] md:mb-0 rounded-[8px] text-[#005BE2] text-[16px] leading-[19px] font-semibold"
                            onClick={async () => {
                                const result = await trigger('email')
                                if (result) {
                                    getVerificationCode()
                                }
                            }}
                        >
                            인증번호 보내기
                        </button>
                    </div>
                    {errors.email?.type === 'required' && <p className="hidden md:block error">입력 필드가 비어 있습니다.</p>}
                    {errors.email?.type === 'pattern' && <p className="hidden md:block error">이메일을 형식에 맞게 입력해주세요</p>}
                    {/* ----------------confirmationCode---------------- */}

                    <div className="flex flex-col md:flex-row items-center justify-between gap-1 w-full">
                        <div className="w-full">
                            <input
                                className="disabled:cursor-not-allowed w-full rounded-[8px] bg-white text-[#575757] text-[16px] leading-[19px] font-normal border-solid border-[1.5px] border-[#cccccc] p-[15px] md:p-[20px] "
                                placeholder={isSmallScreen ? '인증번호' : `이메일 주소로 수신 받은 인증번호를 입력해주세요.`}
                                type="text"
                                disabled={disableVerifyCode}
                                {...register('confirmationCode', {
                                    required: '입력 필드가 비어 있습니다.',
                                    minLength: { value: 3, message: '수신된 인증번호를 정확히 입력해주세요.' },
                                    maxLength: { value: 6, message: '수신된 인증번호를 정확히 입력해주세요.' },
                                    pattern: { value: /^\d+$/, message: '수신된 인증번호를 정확히 입력해주세요.' },
                                })}
                            />
                        </div>
                        {errors.confirmationCode && <p className="error md:hidden w-full">{errors.confirmationCode.message}</p>}
                        <button
                            type="button"
                            disabled={disableVerifyCode}
                            className="disabled:cursor-not-allowed w-full md:w-auto text-nowrap border-solid border-[1.5px] border-[#005BE2] px-[30px] py-[15px] md:py-[20px] rounded-[8px] text-[#005BE2] text-[16px] leading-[19px] font-semibold"
                            onClick={async () => {
                                const result = await trigger('confirmationCode')
                                if (result) {
                                    verifyConfirmationCode()
                                }
                            }}
                        >
                            인증번호 확인
                        </button>
                    </div>
                    {errors.confirmationCode && <p className="error hidden md:block">{errors.confirmationCode.message}</p>}
                </div>

                <button
                    type="submit"
                    className="mb-[20px] md:mt-[35px] md:mb-[12px] mx-auto w-full md:w-[67%] border-none bg-[#005BE2] py-[15px] md:py-[20px] rounded-[8px] text-white text-[16px] leading-[19px] font-semibold "
                >
                    저장
                </button>
            </form>
        </section>
    )
}
