import loading from '../../assets/images/loading.svg'

function Loading() {
    return (
        <div className="d-flex align-items-center justify-content-center" style={{ height: '80vh' }}>
            <img src={loading} className="animate-spin w-20 h-20" alt="loading" />
        </div>
    )
}

export default Loading
