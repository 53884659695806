import React from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import './ResetPasswordWrongEmailModal.scss'
import { Link } from 'react-router-dom'
import danger from '../../../assets/images/danger.svg'

export function ResetPasswordWrongEmailModal({
    passwordError,
    resetPasswordWrongEmailModal,
    setResetPasswordWrongEmailModal,
    setShowForgetPasswordModal,
    setShowFindEmailModal,
}) {
    const handleForgetPassword = () => {
        setResetPasswordWrongEmailModal(false)
        setShowForgetPasswordModal(true)
    }

    const handleFindEmail = () => {
        setResetPasswordWrongEmailModal(false)
        setShowFindEmailModal(true)
    }
    return (
        <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={resetPasswordWrongEmailModal}
            onHide={() => setResetPasswordWrongEmailModal(false)}
            className="resetPasswordWrongEmailModalWrapper"
        >
            <Modal.Header closeButton />
            <Modal.Body className="resetPasswordWrongEmailModal">
                <div className="error_heading">죄송합니다.</div>
                <div className="error-text">{passwordError}</div>
                <div className="buttons">
                    <Button variant="primary" className="button" onClick={() => setResetPasswordWrongEmailModal(false)}>
                        확인
                    </Button>
                </div>
                <div className="error_image">
                    <img src={danger} alt="error" className="danger" />
                </div>
                <div className="col-12 d-flex justify-content-center mt-3">
                    <Link className="link" onClick={handleFindEmail}>
                        계정 찾기
                    </Link>
                    &nbsp;&nbsp; | &nbsp;&nbsp;{' '}
                    <Link className="link" onClick={handleForgetPassword}>
                        비밀번호 재설정
                    </Link>
                </div>
            </Modal.Body>
        </Modal>
    )
}
