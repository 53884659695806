/* eslint-disable jsx-a11y/control-has-associated-label */
import { Link } from 'react-router-dom'
import './Footer.scss'
import React, { useState } from 'react'

export function Footer() {
    const [instaColor, setInstaColor] = useState(false)
    const currentYear = new Date().getFullYear()
    return (
        <div className="footer-wrapper">
            <div className="footer">
                <div className="copyright" id="copyright">
                    &copy;2022-{currentYear} 54DNAtype
                </div>
                <div className="links cursor-pointer">
                    <Link to="/contact" className="linktext">
                        Contact
                    </Link>
                    {/* <Link to="/reviews" className="linktext"> */}
                    {/*  Testimonials */}
                    {/* </Link> */}

                    <Link to="/termAndCondition" className="linktext">
                        Terms & Conditions
                    </Link>
                    <Link to="/privacyPolicy" className="linktext">
                        Privacy Policy
                    </Link>
                </div>
                <div className="icons">
                    {/* insta */}
                    <a
                        href="https://www.instagram.com/54dnatype_official/"
                        target="blank"
                        className="instagram-icon"
                        onMouseOver={() => setInstaColor(true)}
                        onMouseLeave={() => setInstaColor(false)}
                    >
                        <svg
                            className="mx-3 cursor-pointer"
                            width="40"
                            height="40"
                            viewBox="0 0 40 40"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <circle
                                cx="20"
                                cy="20"
                                r="18.5185"
                                fill="white"
                                stroke={`${!instaColor ? '#EBEBEB' : '#bc2a8d'}`}
                                className="bg-[#bc2a8d]"
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M26.5268 23.5005C26.5268 25.1934 25.1542 26.566 23.4611 26.566H16.5392C14.8463 26.566 13.4738 25.1934 13.4738 23.5005V16.5787C13.4738 14.8855 14.8463 13.513 16.5392 13.513H23.4611C25.1542 13.513 26.5268 14.8855 26.5268 16.5787V23.5005ZM23.3816 12H16.6181C14.0676 12 12 14.0673 12 16.6181V23.3819C12 25.9321 14.0676 27.9997 16.6181 27.9997H23.3816C25.9324 27.9997 28 25.9321 28 23.3819V16.6181C28 14.0673 25.9324 12 23.3816 12ZM20.0004 22.7306C18.514 22.7306 17.3094 21.5258 17.3094 20.0397C17.3094 18.5533 18.514 17.3484 20.0004 17.3484C21.4868 17.3484 22.6914 18.5533 22.6914 20.0397C22.6914 21.5258 21.4868 22.7306 20.0004 22.7306ZM20.0001 15.8881C17.7073 15.8881 15.8485 17.7467 15.8485 20.0397C15.8485 22.3325 17.7073 24.191 20.0001 24.191C22.2928 24.191 24.1517 22.3325 24.1517 20.0397C24.1517 17.7467 22.2928 15.8881 20.0001 15.8881ZM23.3228 15.7305C23.3228 15.196 23.7559 14.7633 24.2901 14.7633C24.8242 14.7633 25.2573 15.196 25.2573 15.7305C25.2573 16.2644 24.8242 16.6974 24.2901 16.6974C23.7559 16.6974 23.3228 16.2644 23.3228 15.7305Z"
                                fill="#999999"
                            />
                        </svg>
                    </a>
                    {/* ----Facebook ------- */}
                    {/* <a href="https://www.facebook.com/profile.php?id=100091788874136" target='blank'> */}
                    {/*  <svg className="mx-3 cursor-pointer" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"> */}
                    {/*    <circle cx="20" cy="20" r="18.5185" fill="white" stroke="#EBEBEB" /> */}
                    {/*    <mask id="mask0_802_1963" maskUnits="userSpaceOnUse" x="15" y="10" width="10" height="20"> */}
                    {/*      <path fillRule="evenodd" clipRule="evenodd" d="M15 10H25V29.4118H15V10Z" fill="white" /> */}
                    {/*    </mask> */}
                    {/*    <g mask="url(#mask0_802_1963)"> */}
                    {/*      <path */}
                    {/*        fillRule="evenodd" */}
                    {/*        clipRule="evenodd" */}
                    {/*        d="M20.7274 28.2353V20.4542H23.3287L23.7184 17.4217H20.7274V15.4859C20.7274 14.6073 20.9703 14.0089 22.2242 14.0089L23.8235 14.0084V11.2964C23.5467 11.2596 22.5975 11.1765 21.4929 11.1765C19.1868 11.1765 17.6083 12.59 17.6083 15.1853V17.4217H15V20.4542H17.6083V28.2353H20.7274Z" */}
                    {/*        fill="#999999" */}
                    {/*      /> */}
                    {/*    </g> */}
                    {/*  </svg> */}
                    {/* </a> */}
                </div>
            </div>
        </div>
    )
}
