import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { MyPageContext } from '../../../api/context/myPageContext'

function PageCard1() {
    const navigate = useNavigate()
    const {
        apiResponse: {
            data: {
                cardData: { number, description, name, type, icon },
            },
        },
    } = useContext(MyPageContext) // use the useContext hook to access apiResponse value
    return (
        <div className="bg-white flex h-full flex-col overflow-hidden rounded-lg shadow-[3px_4px_80px_rgba(194,_203,_214,_0.2)]">
            <div className="min-h-[227px] bg-[#E5F0FF] px-[20px] py-[20px]  text-center sxl:min-h-[300px] sxl:py-[30px]">
                <p className="mb-[8px] font-medium text-[#272727]">당신의 학습유전자 타입</p>
                <h2 className="mx-auto mb-[20px] max-w-[300px] text-[18px] font-bold text-[#272727] md:text-[20px] sxl:text-[24px]">
                    {number} {name} <br /> {`${number}DNAtype`} ({type})
                </h2>
                <img src={icon} alt="banner-icon" className="mx-auto h-[75px] max-h-[115px] sxl:h-auto" />
            </div>
            <p className="text-black mb-0 min-h-[105px] px-[16px] pb-0 pt-[16px] text-left font-Pretendard text-[14px] leading-[17px] !tracking-normal sm:px-[25px] sm:leading-normal md:min-h-[168px] md:pb-[20px] md:pt-[20px] sxl:text-[16px]">
                {description}
            </p>

            <div className="mt-auto pb-[20px] pr-[25px] pt-[12px] text-right md:pb-[30px]">
                <button
                    onClick={() => navigate(`/typeReport/${number}`)}
                    className="text-white font-semi-bold ml-auto mt-auto inline-block rounded-full bg-[#005BE2] px-[30px] py-[13px]  text-base leading-[19px] tracking-[-0.03em] transition duration-200 hover:bg-opacity-90 sxl:px-[34px] sxl:py-[16px]"
                >
                    더 알아보기
                </button>
            </div>
        </div>
    )
}

export default PageCard1
