import React from 'react'
import manImage from '../../../assets/images/history-page-man.svg'
import colon from '../../../assets/images/colon.svg'
import './Introduction.scss'

export function Introduction() {
    return (
        <section className="relative mt-[9rem] mb-24 md:my-32 px-[20px] lg:px-[9.3%] fontFamily bg-white text-left text-[#272727] flex flex-col">
            {/* --------For small Screen--------- */}
            <div className="md:hidden top-[54%] left-0 bg-contain bg-no-repeat bg-[url('./assets/images/vector-history-first-mobile.png')]  absolute h-full w-full" />
            {/* --------For Large Screen--------- */}
            <div className="hidden xs:hidden md:inline left-0 top-[52%] bg-no-repeat bg-[url('./assets/images/vector-history.png')] absolute h-full w-full bg-[length:87%]" />
            {/* ------------------Section Starts Here--------------- */}
            <h1 className="my-0 font-semibold text-[20px] md:text-[30px]">학습유전자란?</h1>
            <p className="mt-[1rem] md:mt-[2rem] leading-[24px] tracking-[-0.03em] md:text-[16px]">
                유전자는 인간의 설계도 입니다. <br /> 사람의 외모, 특정 질환 심지어 성격이나 성향까지도 기질적인 유전자가 결정합니다. <br />
                환경이 영향을 다소 줄 수 있으나, 유전자 타입에 따라 결정되는 특징은 더 크고 심오하다고 알려져 있습니다. <br /> 학습유전자는
                3가지 type polymorphism으로 지역, 인종 불문 동일하며, 특성은 세계적인 저널에 게재된 논문을 참고한 것과 직접 검사 결과와
                비교하거나 상담을 통해서 얻은 결과를 나타낸 결과로 기질적인 성격이나 성향을 학습유전자라고 명칭하게 되었습니다. <br />{' '}
                학습유전자는 성격이나 성향 형성에 영향을 주는 인성 관련 유전자라고 하며, 총 4가지 유전자로 정의하게 됩니다. <br />
                첫번째, 지구력 = 체력 = 에너지 유전자 (Angiotensin Converting Enzyme PCR), <br /> 두번째, 집착 = 중독 = 표현욕구 유전자
                (Dopamine Receptor D2 PCR), <br /> 세번째, 창의행동 = 호기심 = 몰입 유전자 (Dopamine Receptor D4 PCR),
                <br /> 네번째, 감정 = 우울폭력 = 정서 유전자 (Serotonin Transporter PCR) 이렇게 4가지 유전자가 가지고 있는 타입들이 조합이
                되어 총 54개의 조합된 타입으로 기질적으로 결정된 성격과 성향을 확인하는 유전자의 통칭을 학습유전자라고 합니다.
                <br />
            </p>
            {/* ----------------For small screen----------- */}
            <div className="lg:hidden">
                <h1 className="leading-[24px] font-semibold text-[20px] ">
                    학습유전자 연구개발자 <br /> 카이스트 생명공학 임용빈 박사
                </h1>
                <div className="flex justify-center items-center w-full">
                    <img src={manImage} alt="man" className="mt-2 rounded-[10px] self-center" />
                </div>
                <div className="mt-3 flex flex-col">
                    <div>
                        <img src={colon} alt="colon" className="h-4 w-5" />
                    </div>
                    <p className="mt-[1rem] md:mt-[2rem] leading-[24px] tracking-[-0.03em]">
                        지구상 모든 사람들은 필연적 발생으로 경쟁에서 탈락하는 사람들에 대해서 미래에 무엇을 어떻게 해야 한다고 말해주고
                        싶습니다. <br />
                        미래는 기성직업이 사라지는 변화에 빠른 시류에 휩쓸리지 않기를 하는 바람으로 자신의 성향과 능력을 과학적으로
                        파악하도록 하는 것이 학습유전자 입니다. <br /> 이것으로 트렌드와 시대의 유행에 끌려 가기 보다는 덜 힘들고 덜
                        불안하게 자신감을 가지고 세상을 이끌어 나갈 수 있을 것입니다. <br /> 학습유전자 타입을 알면 자신의 미래를 더 적은
                        시간과 더 높은 확률로 찾아가는 시작이 될 것이라고 확신합니다.
                    </p>
                    <img src={colon} alt="" className="rotate-180 self-end h-4 w-5" />
                </div>
            </div>
            {/* ----------------For large screen----------- */}
            <div className="hidden xs:hidden lg:flex lg:flex-row justify-between w-full mt-20">
                <img src={manImage} alt="man" className=" rounded-[10px]" />
                <div className="mt-8 ml-16 flex flex-col">
                    <h1 className="leading-[24px] font-semibold text-[20px] md:text-[30px]">
                        학습유전자 연구개발자 카이스트 생명공학 임용빈 박사
                    </h1>
                    <div className="mt-8 flex flex-col">
                        <div>
                            <img src={colon} className="" alt="colon" />
                        </div>
                        <p className="mt-[1rem] md:mt-[2rem] leading-[24px] tracking-[-0.03em] text-[16px] px-4">
                            지구상 모든 사람들은 필연적 발생으로 경쟁에서 탈락하는 사람들에 대해서 미래에 무엇을 어떻게 해야 한다고 말해주고
                            싶습니다.
                            <br />
                            미래는 기성직업이 사라지는 변화에 빠른 시류에 휩쓸리지 않기를 하는 바람으로 자신의 성향과 능력을 과학적으로
                            파악하도록 하는 것이 학습유전자 입니다. <br /> 이것으로 트렌드와 시대의 유행에 끌려 가기 보다는 덜 힘들고 덜
                            불안하게 자신감을 가지고 세상을 이끌어 나갈 수 있을 것입니다. <br /> 학습유전자 타입을 알면 자신의 미래를 더
                            적은 시간과 더 높은 확률로 찾아가는 시작이 될 것이라고 확신합니다.
                        </p>
                    </div>
                    <img src={colon} alt="" className="rotate-180 self-end" />
                </div>
            </div>

            <div className="relative mt-[1rem] md:mt-[5rem]">
                <h1 className="my-0 leading-[24px] font-semibold text-[20px] md:text-[24px]">학습유전자로 가능한 것?</h1>
                <p className="mt-[1rem] md:mt-[2rem] leading-[24px] tracking-[-0.03em] text-[16px]">
                    첫번째로 학습 능력을 알 수 있습니다. <br /> 지구력 유전자의 3가지 타입으로 설명하자면 II 타입의 경우 학습을 장시간으로
                    할 수 있는 신체적인 지구력이 매우 뛰어납니다. <br /> 반면 ID 타입의 경우 중학교 2학년 기점으로 하여 학교 성적이 떨어지는
                    유형이 60% 가량 됩니다. <br />
                    이유는 학습을 장시간으로 할 수 있는 신체적인 지구력이 중간 정도여서 시기상 중학교 2학년 겨울방학 때 정도의 시기에
                    나타나고 있습니다. <br /> 그리고 DD 타입의 경우는 기질적으로 고혈압 예비 환자 정도의 지구력에 매우 좋지 않습니다. <br />{' '}
                    그래서 장시간 학습이 매우 어렵습니다. <br /> 하지만 전세계에 10%밖에 되지 않는 DD 타입은 부자, 효자 유전자를 가지고
                    태어난 귀한 타입이라는 점도 있습니다. <br /> <br /> 둘째는 성격 분석으로 진로 방향을 선택할 때 도움이 됩니다. <br />{' '}
                    창의행동 유전자 타입으로 설명하자면 43타입의 경우 정적으로 창의적이고 활동량이 적은 진로방향을 설정하여야만 건강을 잃지
                    않으며, 적성에 맞아서 원하는 성과를 이루어 낼 수 있습니다. <br /> 반면 그와 반대인 45 타입인 경우는 동적인 대외적이고
                    활발한 활동이 많은 진로방향을 설정해야만 자신의 역량으로 원하는 방향에 나아갈 수 있습니다. <br /> <br /> 결국 자신의
                    학습유전자 타입을 알고 앞으로 나아간다면 여러 방향에 무모한 기대를 하지 않고 가능성 있는 방향으로만 하다 보니 경제적
                    손실을 최소화 하면서 경험과 학습을 하게 되는 것이 가능합니다.
                </p>
            </div>
        </section>
    )
}
