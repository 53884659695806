import React from 'react'
import gene from '../../../assets/images/gene.svg'
import obsession from '../../../assets/images/obsession.svg'
import emotion from '../../../assets/images/emotion.svg'

export function Academic() {
    const data = [
        {
            id: 1,
            image: gene,
            heading: `지구력 = 체력 = 에너지 유전자 (Angiotensin Converting Enzyme PCR)의 학술적 원리 기능`,
            description: `간에서 만들어진 Angiotensinogen 이 renin 이라는 효소에 의해 Angiotensinㅣ으로 분해되고
      Angiotensin l 은 ACE 효소에 의해 Angiotensin ll 로 만들어 지는데 이때 Angiotensin ll 가 혈압을 올리는 역할을 담당하게 된다. 
      일반적으로 지구력 유전자의 DD 타입이 II 타입에 비해 ACE 효소량이 2배 정도 많아서 운동이나 일을 지속하는데에 지장을 많이 주게 된다. 
      이러한 이유는 ACE 효소가 상대적으로 많아서 혈압을 높이는 작용을 하기 때문이다. 
      혈압이 높다는 것은 그 만큼 심장에 무리가 따른다는 것이고 피로도가 증가하게 됨을 의미한다.`,
        },
        {
            id: 2,
            image: obsession,
            heading: `집착=중독=표현욕구 (Dopamine Receptor D2 PCR)의 학술적 원리 기능 
      창의행동=호기심=몰입 유전자 (Dopamine Receptor D4 PCR)의 학술적 원리 기능`,
            description: `사람의 뇌 속에는 도파민이라는 신경전달 물질이 있는데 기쁨이나 흥분과 관련된 일, 즉 포옹, 키스, 칭찬, 승리 등의 즐겁거나 쾌감을 느낄 때 분비가 촉진된다. 
      이러한 현상은 뇌 속에 존재하는 도파민 수용체에 의해 도파민이 다시 흡수됨으로써 진정되고 가라앉게 된다. 
      그러나 만일 도파민 수용체의 4군데 부위에 유전자가 변이가 생기면 도파민 수용체가 원래의 기능을 잘 수행하지 못하게 되면 뇌 안에 도파민이 정상보다 상대적으로 오래 머물게 되고 쾌감과 즐거움은 더 오래 지속되는 것이다. 
      이러한 느낌 때문에 자신이 선척적, 후천적으로 경험한 즐거운 경험을 향해 자제력을 상실한 채 빠져들게 되는 것이다. 
      따라서 각 종 중독 성향, 대범성, 엉뚱한 일, 자폐증, 과대망상증 등의 문제점이 발생하게 된다. 
      Taq1 A, B Intron6 부위는 돌연변이에 의한 변이를 확인하는 것이며, 
      DRD4는 반복되는 염기 서열이 몇 개인지를 보고 변이 여부를 결정하는데 대개 5개-7개 정도면 변이가 있다고 판단 한다.`,
        },
        {
            id: 3,
            image: emotion,
            heading: `감정 = 우울폭력 = 정서 유전자 (Serotonin Transporter PCR)의 학술적 원리 기능`,
            description: `사람의 뇌 속에 존재하는 세로토닌은 슬픔을 느끼거나 충격적인 고통, 마음의 상처를 당했을 때 분비되는 호르몬이다.
      일반적인 경우 분비된 세로토닌은 잠시 시간이 흐르고 난 뒤 세로토닌 운반체와 결합하여 다시 흡수되므로 마음이 진정되고 안정을 찾게 되어있다.
      그러나 세로토닌 운반체와 제대로 결합을 하지 못하게 된다면 상대적으로 정상인보다 더 오랫동안 세로토닌이 뇌 안에 남아있게 되므로 마음의 상태가 슬프거나 우울하고 혹은 그런 기분을 
      견디기 어렵도록 하기 때문에 주위의 동료나 타인들과 마찰이나 시비가 일어나게 된다.`,
        },
    ]
    return (
        <section className="relative flex flex-col gap-8 md:gap-24 mt-[9rem] mb-24 md:my-32 px-[20px] lg:px-[9.3%]">
            {/* Background pattern */}
            <div className="top-[78%] right-[0rem] md:left-[0%] md:top-[68%] bg-cover bg-no-repeat bg-[url('./assets/images/academicBg.svg')] absolute h-[470px] min-[500px]:max-md:h-full min-[500px]:max-md:w-full w-[500px] md:h-[680px] md:w-[739px] -z-[1]" />
            {data.map((g) => (
                <div key={g.id} className="flex flex-col gap-[1rem] md:gap-[4rem]">
                    <img src={g.image} alt="nature" className="w-full rounded-[10px]" />
                    <div className="flex flex-col items-start justify-center text-left">
                        <h1 className="whitespace-pre-line text-[20px] mb-[1rem] md:mb-[2.5rem] font-semibold leading-[24px] md:text-[30px] md:leading-[36px]">
                            {g.heading}
                        </h1>
                        <div className="whitespace-pre-line text-[16px] font-normal leading-[24px]">{g.description}</div>
                    </div>
                </div>
            ))}
        </section>
    )
}
