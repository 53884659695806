import React from 'react'
import nature from '../../../assets/images/nature.svg'
import birthGene from '../../../assets/images/birthGene.svg'

export function Birth() {
    return (
        <section className="relative mt-[9rem] mb-24 md:my-32 px-[20px] lg:px-[9.3%] fontFamily bg-white text-left text-[#272727] flex flex-col">
            <div className="top-[21%] md:top-[19%] right-0 bg-cover bg-no-repeat bg-[url('./assets/images/birthBg.svg')] absolute h-[791px] w-[355px] md:w-[822px]" />

            <div className="flex flex-col gap-[1rem] md:gap-[4rem] md:flex-row md:mt-[-1.5rem]">
                <img src={nature} alt="nature" className="h-[242px] md:h-[453px] w-full md:w-2/5 rounded-[10px]" />
                <div className="flex flex-col items-start justify-center text-left">
                    <h1 className="text-[20px] md:mb-[1rem] font-semibold leading-[24px] md:text-[30px] md:leading-[36px]">
                        학습유전자의 탄생 배경은 첫번째 유전자인 지구력 = 체력 = 에너지 유전자?
                    </h1>
                    <div className="my-[0.5rem] md:my-[1rem] text-[14px] font-medium leading-[21px] text-[#999999] md:text-[16px] md:leading-[24px]">
                        1998년 5 Nature 학술지에 실린 한 페이지 논문을 통해 시작 (Montgomery HE, Marshall R, Hemingway H, et al. Human gene
                        for physical performance. Nature 1998; 393 : 221–2.)
                    </div>
                    <div className="text-[16px] font-normal leading-[24px] text-[#272727]">
                        영국 런던 병원에서 500명의 해병을 연구 대상으로 바벨을 들고 팔을 벌려 얼마나 오래 버티는지 실험을 하였습니다. <br />{' '}
                        10주 후 특정 DNA 타입 (지구력 = 체력 = 에너지 유전자)을 가진 그룹이 극히 좋은 결과를 보여 결론은 특정 지구력
                        유전자를 가진 그룹은 매우 지구력이 강한 것으로 확정이 되었습니다.
                        <br />
                        여기서 카이스트 생명공학 임용빈 박사는 만약 강한 지구력을 가진 사람은 또한 학습 능력도 아주 우수함을 예측하고
                        1천명의 학생들을 대상으로 지구력 = 체력 = 에너지 유전자 분석과 성적을 비교한 결과 예상대로 지구력이 강한 유전자를
                        가질수록 좋은 성적을 가지는 것을 확인하였습니다. <br />그 후 3가지 유전자는 집착 = 중독 = 표현욕구 / 창의행동 =
                        호기심 = 몰입 / 감정 = 우울폭력 = 정서 유전자를 말합니다. <br />
                        1998년 이후 약 16만명이 검사하고 6천명의 학생이 관리를 받아 성적이 향상되었고 각종 심리 테스트 (MBTI 등)과 비교한
                        결과 80%의 일치도를 보이는 것으로 확인 되었습니다.
                    </div>
                </div>
            </div>

            <div className="mt-12 flex flex-col gap-[1rem] md:gap-[4rem] md:flex-row-reverse">
                <h1 className="-mt-[20px] md:hidden text-[20px] md:mb-[1rem] font-semibold leading-[24px] md:text-[30px] md:leading-[36px] text-left">
                    학습유전자 완성은 나머지 3가지 유전자가 조합이 되면서 완성 됩니다.{' '}
                </h1>
                <img src={birthGene} alt="nature" className="h-[242px] md:h-[453px] w-full md:w-2/5 rounded-[10px]" />
                <div className="flex flex-col items-start justify-center text-left">
                    <h1 className="hidden md:block text-[20px] md:mb-[1rem] font-semibold leading-[24px] md:text-[30px] md:leading-[36px]">
                        학습유전자 완성은 나머지 3가지 유전자가 조합이 되면서 완성 됩니다.{' '}
                    </h1>
                    <div className="my-[0.5rem] md:my-[1rem] text-[14px] font-medium leading-[21px] text-[#999999] md:text-[16px] md:leading-[24px]">
                        하워드 가드너(Howard Gardner, 하버드대 교육심리학자)의 다중지능이론(multiple intelligence theory)과 교육철학을
                        근거를 기초로 연구
                    </div>
                    <p className="text-[16px] font-normal leading-[24px] text-[#272727]">
                        하워드 가드너(Howard Gardner, 하버드대 교육심리학자)의 다중지능이론(multiple intelligence theory)과 교육철학을
                        근거를 기초하여 임용빈 박사는 지구력 = 체력 = 에너지 유전자 (ACE PCR), 집착 = 중독 = 표현욕구 유전자 (DRD4 PCR),
                        창의행동 = 호기심 = 몰입 유전자 (DRD2 PCR), 감정 = 우울폭력 = 정서 유전자 (Serotonin Transporter PCR) 총 4가지로
                        DNA를 구분 했으며, 그것이 학습유전자 입니다. 그래서 강도에 따른 조합을 통해 성향을 총 54개의 조합된 타입으로 하여
                        9가지의 대그룹에 6개 세부타입이 분류되어 54개에 DNA type 조합타입 결과가 나오는 것 입니다.
                    </p>
                </div>
            </div>
        </section>
    )
}
