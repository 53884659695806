import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import alertCircleOutline from '../../../assets/icons/alertCircleOutline.svg'
import axios from '../../../api/axios'
import { useHomePage } from '../../../hooks/useHomePage'
import { UseUser } from '../../../api/context/userContext'
import { SuccessModal } from '../SuccessModal/SuccessModal'
import { ErrorModal } from '../ErrorModal/ErrorModal'
import { Spinner } from '../../Spinner/Spinner'

export function WithdrawMembership({ setShowMyPageSettingModal }) {
    const [loading, setLoading] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false) // to restrict Api calls to once
    const { getUser } = UseUser()
    const {
        showSuccessModal,
        setShowSuccessModal,
        showErrorModal,
        setShowErrorModal,
        successAndErrorResponse,
        setSuccessAndErrorResponse,
    } = useHomePage()

    // WHen User Confirm Deactivate Account
    const handleConfirm = () => {
        const postData = {
            isWithdraw: '1',
        }
        setLoading(true)
        // to restrict Api calls to once
        if (!isSubmitting) {
            setIsSubmitting(true)
            axios
                .post('/user/withdrawAccount', postData)
                .then((response) => {
                    if (response.data.message) {
                        setSuccessAndErrorResponse(response.data.message)
                        setShowSuccessModal(true)
                        setTimeout(() => {
                            setShowMyPageSettingModal(false)
                        }, 2000)
                    }
                })
                .catch((error) => {
                    setShowErrorModal(true)
                    setSuccessAndErrorResponse(error.response.data.message)
                })
                .finally(() => {
                    setLoading(false)
                    setIsSubmitting(false)
                    getUser()
                })
        }
    }

    return (
        <div className="flex flex-col gap-[16px] md:gap-[40px]">
            {showSuccessModal === true ? (
                <SuccessModal
                    showSuccessModal={showSuccessModal}
                    setShowSuccessModal={setShowSuccessModal}
                    successAndErrorResponse={successAndErrorResponse}
                />
            ) : showErrorModal === true ? (
                <ErrorModal
                    showErrorModal={showErrorModal}
                    setShowErrorModal={setShowErrorModal}
                    successAndErrorResponse={successAndErrorResponse}
                />
            ) : (
                ''
            )}
            {loading && <Spinner />}
            <div>
                <img src={alertCircleOutline} alt="alertCircleOutline" className="mx-auto h-[80px] md:h-[100px]" />
            </div>
            <div className="text-center text-[16px] font-normal leading-[24px] md:text-[20px] md:leading-[30px]">
                <h1 className="text-center text-[24px] font-bold leading-[29px] md:mt-[15px] md:pb-4 md:text-[40px] md:leading-[48px]">
                    회원 탈퇴 신청
                </h1>
                <p className="px-4 text-center text-[16px] font-normal leading-[24px] md:px-0 md:text-[20px] md:leading-[30px]">
                    <span className="block">회원 탈퇴 신청 후 14일 안에 탈퇴 취소가 가능합니다.</span>
                    <span className="block font-bold">14일이 지난 후 자동적으로 회원 탈퇴 처리가 완료됩니다.</span>
                    <span className="block">그래도 탈퇴 신청을 하시겠습니까?</span>
                </p>
            </div>

            <div className="flex w-full items-center justify-center gap-2 pb-12">
                <Button
                    variant="outline-danger"
                    className="h-[50px] w-1/2 text-center text-[16px] font-semibold leading-[19px] text-[#FF4D4F] hover:text-[#ffffff] md:h-[60px] md:w-[38%]"
                    onClick={handleConfirm}
                >
                    탈퇴 신청
                </Button>
                <Button
                    variant="primary"
                    className="h-[50px] w-1/2 bg-[#005BE2] px-1  text-center text-[16px] font-semibold leading-[19px] md:h-[60px] md:w-[38%]"
                    onClick={() => setShowMyPageSettingModal(false)}
                >
                    취소
                </Button>
            </div>
        </div>
    )
}
