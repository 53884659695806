import React, { useState, useEffect } from 'react'
import './QuestionPage.scss'
import { Helmet } from 'react-helmet'
import { useLocation, useNavigate } from 'react-router-dom'
import CardsData from '../../components/Question/CardsData'
import DNAtestCard from '../../components/Question/DNAtestCard'
import Pagination from '../../components/Pagination/Pagination'
import axios from '../../api/axios'
import { Spinner } from '../../components/Spinner/Spinner'
import { UseUser } from '../../api/context/userContext'
import DemoQuestions from './DemoQuestions'
import { useQuestionPage } from '../../hooks/useQuestionPage'
import { CheckOthersModal } from '../../components/Modals/CheckOthersModal/CheckOthersModal'
import ProgressBar from './ProgressBar'

// ----------------Main Function Starts here--------------
function QuestionPage() {
    // get/aceess values from context object
    const { userData } = UseUser()
    const navigate = useNavigate()
    const location = useLocation()
    const { showCheckOthersModal, setShowCheckOthersModal } = useQuestionPage()
    const urlParams = new URLSearchParams(window.location.search)
    const resultId = urlParams.get('resultId')
    const showQuestion = urlParams.get('show')

    const nickname = urlParams.get('nickname')
    const token = localStorage.getItem('token')
    const [currentPage, setCurrentPage] = useState(1)
    const [fetchedQuestions, setFetchedQuestions] = useState([])
    const [fetchedDemoQuestions, setFetchedDemoQuestions] = useState([])
    const [loadingQuestion, setLoadingQuestion] = useState(false)
    const fetchQuestion = (resultId) => {
        setLoadingQuestion(true)
        const urlWIthResultId = `/questions?resultId=${resultId}`
        const urlWithoutResultId = '/questions/getQuestionsList'
        axios
            .get(resultId === null ? urlWithoutResultId : urlWIthResultId)
            .then(({ data }) => {
                setFetchedQuestions(data.questions)
            })
            .catch((error) => console.log('An Error Occured:', error))
            .finally(() => {
                setTimeout(() => {
                    setLoadingQuestion(false)
                }, 1000)
                const path = `${window.location.pathname}${window.location.search}`
                navigate(path, { replace: true, state: null })
            })
    }
    useEffect(() => {
        const showModal = () => {
            setShowCheckOthersModal(true)
        }
        if (resultId && location.state?.section === 0) {
            showModal()
        }
    }, [])

    const fetchDemoQuestion = () => {
        const demoQuestsData = JSON.parse(localStorage.getItem('demoQuests'))
        if (demoQuestsData?.length) {
            setFetchedDemoQuestions(demoQuestsData)
            // return;
        }
        !demoQuestsData?.length && setLoadingQuestion(true)
        axios
            .get('/questions/getQuestions')
            .then(({ data }) => {
                setFetchedDemoQuestions(data.questions)
                localStorage.setItem('demoQuests', JSON.stringify(data.questions))
            })
            .catch((error) => console.log('An Error Occured:', error))
            .finally(() => {
                setLoadingQuestion(false)
            })
    }

    useEffect(() => {
        if (token && userData) {
            if (userData.finishTest || userData.section == 4) {
                return navigate('/thank-you')
            }
            if (resultId) {
                fetchQuestion(resultId)
            } else if ((!showQuestion && userData.section == 2) || userData.section == 4) {
                navigate('/result')
            } else {
                fetchQuestion(null)
            }
        } else {
            fetchDemoQuestion()
        }
    }, [resultId, userData])

    return (
        <>
            <Helmet>
                <title>
                    {(() => {
                        switch (userData?.section) {
                            case 0:
                                return 'DNA질문검사 - A'
                            case 1:
                                return 'DNA질문검사 - B'
                            case 2:
                                return 'DNA질문검사 - C'
                            case 3:
                                return 'DNA질문검사 - D'
                            default:
                                return 'DNA질문검사'
                        }
                    })()}
                </title>
            </Helmet>

            <div className="questions_wrapper">
                {showCheckOthersModal && (
                    <CheckOthersModal showCheckOthersModal={showCheckOthersModal} setShowCheckOthersModal={setShowCheckOthersModal} />
                )}
                <div className="hero_section">
                    <div className="dna_title">DNA 질문검사</div>
                    <div className="dna_cards">
                        {CardsData.map((card, index) => {
                            return <DNAtestCard key={index} card={card} />
                        })}
                    </div>
                </div>
                {userData ? <ProgressBar section={userData?.section} currentPage={currentPage} /> : null}
                {token ? (
                    // Actual Questions
                    <div className="questions">
                        {loadingQuestion ? (
                            <Spinner />
                        ) : (
                            <Pagination
                                data={fetchedQuestions}
                                dataLimit={11}
                                resultId={resultId}
                                nickname={nickname}
                                section={userData?.section}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                            />
                        )}
                    </div>
                ) : (
                    // Demo Questions
                    <div className="questions">{loadingQuestion ? <Spinner /> : <DemoQuestions data={fetchedDemoQuestions} />}</div>
                )}
            </div>
        </>
    )
}

export default QuestionPage
