import { useContext } from 'react'
import { Modal, Row } from 'react-bootstrap'
import { MyPageContext } from '../../../api/context/myPageContext'
import { EmailChange } from './EmailChange'
import { PasswordChange } from './PasswordChange'
import { WithdrawMembership } from './WithdrawMembership'
import { SetPassword } from './SetPassword'

export function MyPageSettingModal() {
    const { showMyPageSettingModal, setShowMyPageSettingModal, settingModalValue } = useContext(MyPageContext)

    const SubComponents = {
        emailChange: <EmailChange setShowMyPageSettingModal={setShowMyPageSettingModal} />,
        passwordChange: <PasswordChange setShowMyPageSettingModal={setShowMyPageSettingModal} />,
        withdrawMembership: <WithdrawMembership setShowMyPageSettingModal={setShowMyPageSettingModal} />,
        setPassword: <SetPassword setShowMyPageSettingModal={setShowMyPageSettingModal} />,
    }

    return (
        <Modal
            className="find-email-modal-wrapper font-Pretendard"
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={showMyPageSettingModal}
            onHide={() => setShowMyPageSettingModal(false)}
        >
            <Modal.Header>
                <button
                    type="button"
                    className="btn-close modal-close-btn text-black"
                    data-bs-dismiss="modal"
                    onClick={() => setShowMyPageSettingModal(false)}
                    aria-label="Close"
                />
            </Modal.Header>
            <Modal.Body>
                <Row>{SubComponents[settingModalValue]}</Row>
            </Modal.Body>
        </Modal>
    )
}
